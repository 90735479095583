// Services
import { AjaxService } from 'NewAngular/services/core/ajax.service';
import { UrlsService } from 'NewAngular/services/core/urls.service';
import * as i0 from "@angular/core";
import * as i1 from "NewAngular/services/core/ajax.service";
import * as i2 from "NewAngular/services/core/urls.service";
/*
 *
 * SalesSupportService: service for accessing sales and support endpoint
 *
 */
export class SalesSupportService {
    constructor(ajaxService, urlsService) {
        this.ajaxService = ajaxService;
        this.urlsService = urlsService;
    }
    getSalesRep() {
        return this.ajaxService.getDataObserveBody(this.urlsService.SALES_SUPPORT.SALES_REP);
    }
    postSupportForm(postData, requestType) {
        const url = this.urlsService.getUrl(this.urlsService.SALES_SUPPORT.SUPPORT_FORM, { requestType });
        return this.ajaxService.postJsonDataObserveBody(url, postData);
    }
}
SalesSupportService.ɵfac = function SalesSupportService_Factory(t) { return new (t || SalesSupportService)(i0.ɵɵinject(i1.AjaxService), i0.ɵɵinject(i2.UrlsService)); };
SalesSupportService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: SalesSupportService, factory: SalesSupportService.ɵfac, providedIn: 'root' });
