// Vendor libraries
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { URIComponentCodec } from './uri_component_codec';
import { SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/platform-browser";
export class AjaxService {
    constructor(httpClient, sanitizer) {
        this.httpClient = httpClient;
        this.sanitizer = sanitizer;
    }
    getDataObserveResponse(url, getParams = {}, additionalHeaders = {}) {
        const headers = new HttpHeaders({ ...additionalHeaders, 'X-Requested-With': 'XMLHttpRequest' });
        return this.httpClient.get(url, { headers, observe: 'response', params: getParams });
    }
    getDataObserveBody(url, getParams = {}, additionalHeaders = {}) {
        const headers = new HttpHeaders({ ...additionalHeaders, 'X-Requested-With': 'XMLHttpRequest' });
        return this.httpClient.get(url, { headers, observe: 'body', params: getParams });
    }
    getDataObserveText(url, getParams = {}, additionalHeaders = {}) {
        const headers = new HttpHeaders({ ...additionalHeaders, 'X-Requested-With': 'XMLHttpRequest' });
        return this.httpClient.get(url, { headers, observe: 'body', params: getParams, responseType: 'text' });
    }
    getJsonP(url) {
        const sanitizedUrl = this.sanitizer.sanitize(SecurityContext.URL, url) ?? '';
        return this.httpClient.jsonp(sanitizedUrl, 'callback');
    }
    postJsonDataObserveResponse(url, postParams) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        });
        return this.httpClient.post(url, postParams, { headers, responseType: 'json', observe: 'response' });
    }
    postJsonDataObserveBody(url, postParams) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        });
        return this.httpClient.post(url, postParams, { headers, responseType: 'json', observe: 'body' });
    }
    postFormEncodedDataObserveResponse(url, postParams) {
        const serializedParams = new HttpParams({ fromObject: postParams });
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest',
        });
        return this.httpClient.post(url, serializedParams, { headers, observe: 'response' });
    }
    postFormEncodedDataObserveBody(url, postParams) {
        const serializedParams = new HttpParams({ fromObject: postParams, encoder: new URIComponentCodec() });
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest',
        });
        return this.httpClient.post(url, serializedParams, { headers, observe: 'body' });
    }
    postFormEncodedDataWithBlobObserveResponse(url, postParams) {
        const serializedParams = new HttpParams({ fromObject: postParams, encoder: new URIComponentCodec() });
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest',
        });
        return this.httpClient.post(url, serializedParams, { headers, observe: 'response', responseType: 'blob' });
    }
    postFormEncodedDataWithTextObserveResponse(url, postParams) {
        const serializedParams = new HttpParams({ fromObject: postParams });
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest',
        });
        return this.httpClient.post(url, serializedParams, { headers, responseType: 'text', observe: 'response' });
    }
    // We need a separate method for responseType blob because generics dont work with blobs
    postJsonDataWithBlobResponseObserveResponse(url, postParams) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        });
        return this.httpClient.post(url, postParams, { headers, responseType: 'blob', observe: 'response' });
    }
    // We need a separate method for responseType blob because generics dont work with blobs
    postJsonDataWithBlobResponseObserveBody(url, postParams) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        });
        return this.httpClient.post(url, postParams, { headers, responseType: 'blob', observe: 'body' });
    }
    postMultipartFormDataWithObserveBody(url, formData) {
        const headers = new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' });
        return this.httpClient.post(url, formData, { headers, observe: 'body' });
    }
    putDataObserveResponse(url, putParams) {
        const headers = new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' });
        return this.httpClient.put(url, putParams, { headers, observe: 'response' });
    }
    putDataObserveBody(url, putParams) {
        const headers = new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' });
        return this.httpClient.put(url, putParams, { headers, observe: 'body' });
    }
    putJsonDataObserveBody(url, putParams) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        });
        return this.httpClient.put(url, putParams, { headers, observe: 'body' });
    }
    patchDataObserveResponse(url, patchParams) {
        const headers = new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' });
        return this.httpClient.patch(url, patchParams, { headers, observe: 'response' });
    }
    patchDataObserveBody(url, patchParams) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this.httpClient.patch(url, patchParams, { headers, observe: 'body' });
    }
    deleteDataObserveResponse(url) {
        const headers = new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' });
        return this.httpClient.delete(url, { headers, observe: 'response' });
    }
    deleteDataObserveBody(url) {
        const headers = new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' });
        return this.httpClient.delete(url, { headers, observe: 'body' });
    }
}
AjaxService.ɵfac = function AjaxService_Factory(t) { return new (t || AjaxService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.DomSanitizer)); };
AjaxService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: AjaxService, factory: AjaxService.ɵfac, providedIn: 'root' });
