// Vendor libraries
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { ConfigModule } from 'NewAngular/config/config.module';
import { StoreModule } from '@ngrx/store';
// NgRx
import { NOTIFICATIONS_MODAL_STATE_SLICE_KEY, notificationsModal, } from 'NotificationsApp/ts/features/notifications-modal/state/notifications-modal.store';
// Module imports
import { IconModule } from 'NewAngular/features/directives/icon/icon.module';
import { TooltipModule } from 'NewAngular/features/directives/popovers/tooltip/tooltip.module';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class NotificationsModalModule {
}
NotificationsModalModule.ɵfac = function NotificationsModalModule_Factory(t) { return new (t || NotificationsModalModule)(); };
NotificationsModalModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: NotificationsModalModule });
NotificationsModalModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [BrowserModule,
        CommonModule,
        ConfigModule,
        IconModule,
        StoreModule.forFeature(NOTIFICATIONS_MODAL_STATE_SLICE_KEY, notificationsModal),
        TooltipModule] });
