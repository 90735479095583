// Vendor libraries
import { NgZone } from '@angular/core';
// Components
import { BaseAutocompleteComponent } from 'SearchApp/ts/features/base-autocomplete/components/base-autocomplete.component';
import * as i0 from "@angular/core";
import * as i1 from "../base-autocomplete/components/base-autocomplete.component";
/**
 *
 * Top Bar Nav Autocomplete Component
 *   Input defaultResults: list of AutocompleteExtraLink items to display when autocompleter is focused but empty
 *
 */
export class TopBarNavAutocompleteComponent {
    constructor(ngZone) {
        this.ngZone = ngZone;
        this.defaultResults = [];
        this.BLURRED_PLACEHOLDER = 'Enter text or press “/”';
        this.searchPlaceholder = this.BLURRED_PLACEHOLDER;
    }
    selectResult(result) {
        window.location.href = result.url;
    }
    reset() {
        if (this.baseAutocompleter) {
            this.baseAutocompleter.reset();
        }
    }
    ngAfterViewInit() {
        this.ngZone.runOutsideAngular(() => {
            // Focus search input when the slash key is pressed
            document.addEventListener('keydown', (event) => {
                // Ignore other keys
                if (event.key !== '/') {
                    return;
                }
                // Make sure a text input is not currently focused
                const activeElement = document.activeElement;
                const focusedElementIsTextInput = activeElement &&
                    ((activeElement.tagName === 'INPUT' && activeElement.type === 'text') ||
                        activeElement.tagName === 'TEXTAREA' ||
                        activeElement.classList?.contains('ql-editor'));
                if (focusedElementIsTextInput) {
                    return;
                }
                // Make sure no modals are open
                const modalOpen = !!document.querySelector('.modal-backdrop.show');
                if (modalOpen) {
                    return;
                }
                event.preventDefault();
                if (this.baseAutocompleter) {
                    this.baseAutocompleter.focus();
                }
            });
        });
    }
}
TopBarNavAutocompleteComponent.ɵfac = function TopBarNavAutocompleteComponent_Factory(t) { return new (t || TopBarNavAutocompleteComponent)(i0.ɵɵdirectiveInject(i0.NgZone)); };
TopBarNavAutocompleteComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: TopBarNavAutocompleteComponent, selectors: [["ycn-top-bar-nav-autocomplete"]], viewQuery: function TopBarNavAutocompleteComponent_Query(rf, ctx) { if (rf & 1) {
        i0.ɵɵviewQuery(BaseAutocompleteComponent, 5);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.baseAutocompleter = _t.first);
    } }, inputs: { defaultResults: "defaultResults" }, decls: 1, vars: 6, consts: [[1, "input-group", "field-input-group", 3, "blurHandler", "focusHandler", "submitHandler", "buttonType", "defaultResults", "globalSearch", "placeholder", "searchType", "showMoreResultsUrl"]], template: function TopBarNavAutocompleteComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "ycn-base-autocomplete", 0);
        i0.ɵɵlistener("blurHandler", function TopBarNavAutocompleteComponent_Template_ycn_base_autocomplete_blurHandler_0_listener() { return ctx.searchPlaceholder = ctx.BLURRED_PLACEHOLDER; })("focusHandler", function TopBarNavAutocompleteComponent_Template_ycn_base_autocomplete_focusHandler_0_listener() { return ctx.searchPlaceholder = ""; })("submitHandler", function TopBarNavAutocompleteComponent_Template_ycn_base_autocomplete_submitHandler_0_listener($event) { return ctx.selectResult($event); });
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵproperty("buttonType", "icon-search")("defaultResults", ctx.defaultResults)("globalSearch", true)("placeholder", ctx.searchPlaceholder)("searchType", "main")("showMoreResultsUrl", true);
    } }, dependencies: [i1.BaseAutocompleteComponent], encapsulation: 2 });
