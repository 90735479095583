// Vendor libraries
import { SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
// Utils / Constants
import { formFlattenedObject, formFlattenedParamString, parseUrlParamStringToArray, translateArrayToUrlParamString, } from 'NewAngular/utils/urls';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
export class UtilsService {
    constructor(domSanitizer) {
        this.domSanitizer = domSanitizer;
        this.url = {
            translateArrayToUrlParamString,
            parseUrlParamStringToArray,
            formFlattenedObject,
            formFlattenedParamString,
        };
    }
    copyToClipboard(stringToCopy) {
        let success;
        const textArea = document.createElement('textarea');
        // Place in top-left corner
        textArea.style.position = 'fixed';
        textArea.style.top = '0';
        textArea.style.left = '0';
        // Give small width/height, 1em/1px doesn't work on some browsers
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        // Don't need padding/borders
        textArea.style.padding = '0';
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';
        // Set the value of the state_data
        textArea.value = stringToCopy;
        // Add to document body
        document.body.appendChild(textArea);
        // Select and copy text
        textArea.select();
        try {
            if (navigator.clipboard) {
                navigator.clipboard.writeText(stringToCopy).then(() => { });
            }
            else {
                document.execCommand('copy');
            }
            success = true;
        }
        catch (err) {
            success = false;
        }
        // Clean up
        document.body.removeChild(textArea);
        return success;
    }
    copyStateDataToClipboard(stateData, stateKeys, defaultData) {
        // Get the state data with defaults.
        const copiedData = {};
        for (let i = 0; i < stateKeys.length; i += 1) {
            const key = stateKeys[i];
            copiedData[key] = key in stateData ? stateData[key] : defaultData[key] || '';
        }
        return this.copyToClipboard(JSON.stringify(copiedData));
    }
    addUrlParams(urlTemplate, urlParams) {
        /**
         * Given a URL template and its url params, return the formatted URL.
         *
         * Example: addUrlParams('/custom_securities/{{ customSecurityId }}', { customSecurityId: 15 });
         * Returns: '/custom_securities/15'
         */
        if (!urlParams) {
            return urlTemplate;
        }
        let formattedString = urlTemplate;
        Object.keys(urlParams).forEach((key) => {
            const interpolationString = `{{ ${key} }}`;
            if (urlTemplate.includes(interpolationString)) {
                formattedString = formattedString.replace(interpolationString, urlParams[key]);
            }
        });
        return formattedString;
    }
    addQueryParams(urlTemplate, queryParams) {
        /**
         * Given a URL template and its query params, return the formatted URL.
         *
         * Example: addQueryParams('/custom_securities/15', { calculate: true });
         * Returns: '/custom_securities/15?calculate=true&'
         */
        if (!queryParams) {
            return urlTemplate;
        }
        let formattedString = `${urlTemplate}?`;
        Object.keys(queryParams).forEach((key) => {
            formattedString += `${key}=${queryParams[key]}&`;
        });
        return formattedString;
    }
    sanitizeUrl(url) {
        // If sanitize returns null, return an empty string
        return this.domSanitizer.sanitize(SecurityContext.URL, url) || '';
    }
}
UtilsService.ɵfac = function UtilsService_Factory(t) { return new (t || UtilsService)(i0.ɵɵinject(i1.DomSanitizer)); };
UtilsService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: UtilsService, factory: UtilsService.ɵfac, providedIn: 'root' });
