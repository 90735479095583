// Vendor libraries
import { ElementRef, SecurityContext, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
// Utils / Constants
import { isDesktop } from 'NewAngular/utils/media';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
/**
 * Popover Base Directive
 *
 * Adds a tooltip to a host element using the tippy.js library.
 *
 * Input Properties:
 *  tooltipTitle: text or HTML to put in the tooltip
 *  tooltipPlacement: where to position the tooltip relative to the host element
 *  tooltipHtml: whether to parse the input tooltipTitle as text or HTML
 *  tooltipClasses: Additional CSS classes to apply to the tooltip popper
 */
export class PopoverBaseDirective {
    constructor(el, sanitizer) {
        this.el = el;
        this.sanitizer = sanitizer;
        this.tooltip = null;
        this.creationPromise = null;
    }
    ngOnChanges(changes) {
        // If the tooltip title changed, delete the old tooltip before a new one is created
        if (changes?.tooltipTitle.currentValue !== changes?.tooltipTitle.previousValue) {
            if (this.tooltip)
                this.tooltip.destroy();
            this.tooltip = null;
        }
    }
    async showTooltip() {
        // Create the tooltip and show it.
        this.creationPromise = this.createTooltip();
        // Wait for the tooltip to be created before showing it.
        await this.creationPromise;
        if (this.tooltip !== null) {
            this.tooltip.show();
        }
    }
    async hideTooltip() {
        // Hide the tooltip if it exists after it has been created.
        if (this.creationPromise !== null) {
            await this.creationPromise;
            if (this.tooltip !== null) {
                this.tooltip.hide();
            }
        }
    }
    isTooltip() {
        return true;
    }
    async toggleTooltip() {
        if (this.tooltip && this.tooltip.state.isShown) {
            this.hideTooltip();
        }
        else {
            await this.showTooltip();
        }
    }
    async createTooltip() {
        // Before we create a tooltip make sure we destroy the existing one.
        if (this.tooltip) {
            this.tooltip.destroy();
            this.tooltip = null;
        }
        if ((this.tooltipTitle && isDesktop()) || !this.isTooltip()) {
            const tooltipPlacement = this.tooltipPlacement || 'bottom'; // default to bottom
            const tooltipTitle = this.tooltipHtml
                ? this.sanitizer.sanitize(SecurityContext.HTML, this.tooltipTitle) || ''
                : this.tooltipTitle;
            // NOTE: (03/17/23) We lazy load the `tippy.js` library on purpose since it is a relatively large
            //       JS package that is used rarely and therefor should ONLY be loaded when it is
            //       actually used and not on page load for performance gains.
            const tippy = (await import('tippy.js')).default;
            // Typecast to Element so tippy.js knows we're only instantiating a single Instance
            this.tooltip = tippy(this.el.nativeElement, {
                trigger: 'manual',
                content: tooltipTitle,
                placement: tooltipPlacement,
                allowHTML: this.tooltipHtml || false,
                interactive: !!this.tooltipHtml,
                appendTo: document.body,
                // prevents popover from fading when it disappears
                duration: [0, 0],
            });
            // Apply any additional CSS classes to the tooltip
            if (this.tooltipClasses?.length) {
                const tippyBoxEl = this.tooltip.popper.querySelector('div.tippy-box');
                if (tippyBoxEl) {
                    tippyBoxEl.classList.add(...this.tooltipClasses);
                }
            }
        }
    }
}
PopoverBaseDirective.ɵfac = function PopoverBaseDirective_Factory(t) { return new (t || PopoverBaseDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.DomSanitizer)); };
PopoverBaseDirective.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: PopoverBaseDirective, selectors: [["", "ycnPopoverBase", ""]], inputs: { tooltipTitle: "tooltipTitle", tooltipPlacement: "tooltipPlacement", tooltipHtml: "tooltipHtml", tooltipClasses: "tooltipClasses" }, features: [i0.ɵɵNgOnChangesFeature] });
