// Vendor Libraries
import { EventEmitter } from '@angular/core';
// Services
import { SalesSupportService } from '../../services/sales-support.service';
// Utils / Constants
import { encodeHtml } from 'NewAngular/utils/html-transcode';
import * as i0 from "@angular/core";
import * as i1 from "../../services/sales-support.service";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
function SupportFormComponent_div_2_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtext(1, " It appears you do not have dedicated support with your account. If you would like to upgrade please contact ");
    i0.ɵɵelementStart(2, "a", 8);
    i0.ɵɵtext(3, "support@ycharts.com");
    i0.ɵɵelementEnd()();
} }
function SupportFormComponent_div_2_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtext(1, " We apologize but an unknown error occurred. Please email ");
    i0.ɵɵelementStart(2, "a", 8);
    i0.ɵɵtext(3, "support@charts.com");
    i0.ɵɵelementEnd()();
} }
function SupportFormComponent_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵtemplate(1, SupportFormComponent_div_2_div_1_Template, 4, 0, "div", 6)(2, SupportFormComponent_div_2_div_2_Template, 4, 0, "div", 7);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngSwitch", ctx_r0.errorStatus);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngSwitchCase", 403);
} }
export class SupportFormComponent {
    constructor(salesSupportService) {
        this.salesSupportService = salesSupportService;
        this.submitHandler = new EventEmitter();
        this.textAreaValue = '';
        this.errorStatus = null;
    }
    submitForm(textAreaValue) {
        const sanitizedMessage = encodeHtml(textAreaValue);
        // Prevent submitting empty strings
        if (sanitizedMessage.trim().length === 0)
            return;
        const postData = {
            message: sanitizedMessage,
            emailSubject: this.emailSubject,
        };
        this.salesSupportService.postSupportForm(postData, this.requestType).subscribe({
            next: () => this.submitHandler.emit(),
            error: (errResponse) => {
                this.errorStatus = errResponse.status;
            },
        });
    }
}
SupportFormComponent.ɵfac = function SupportFormComponent_Factory(t) { return new (t || SupportFormComponent)(i0.ɵɵdirectiveInject(i1.SalesSupportService)); };
SupportFormComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: SupportFormComponent, selectors: [["ycn-support-form"]], inputs: { placeholder: "placeholder", emailSubject: "emailSubject", requestType: "requestType" }, outputs: { submitHandler: "submitHandler" }, decls: 6, vars: 4, consts: [[1, "form-row"], ["rows", "4", 1, "form-control", 3, "ngModelChange", "placeholder", "ngModel"], ["class", "text-left text-red", 3, "ngSwitch", 4, "ngIf"], [1, "text-right"], [1, "btn", 3, "click", "ngClass"], [1, "text-left", "text-red", 3, "ngSwitch"], [4, "ngSwitchCase"], [4, "ngSwitchDefault"], ["href", "mailto:support@ycharts.com", 1, "text-blue"]], template: function SupportFormComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0)(1, "textarea", 1);
        i0.ɵɵtwoWayListener("ngModelChange", function SupportFormComponent_Template_textarea_ngModelChange_1_listener($event) { i0.ɵɵtwoWayBindingSet(ctx.textAreaValue, $event) || (ctx.textAreaValue = $event); return $event; });
        i0.ɵɵelementEnd()();
        i0.ɵɵtemplate(2, SupportFormComponent_div_2_Template, 3, 2, "div", 2);
        i0.ɵɵelementStart(3, "div", 3)(4, "div", 4);
        i0.ɵɵlistener("click", function SupportFormComponent_Template_div_click_4_listener() { return ctx.submitForm(ctx.textAreaValue); });
        i0.ɵɵtext(5, "Submit");
        i0.ɵɵelementEnd()();
    } if (rf & 2) {
        i0.ɵɵadvance();
        i0.ɵɵpropertyInterpolate("placeholder", ctx.placeholder);
        i0.ɵɵtwoWayProperty("ngModel", ctx.textAreaValue);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.errorStatus !== null);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngClass", ctx.textAreaValue.trim().length > 0 ? "btn-primary" : "btn-secondary disabled");
    } }, dependencies: [i2.NgClass, i2.NgIf, i2.NgSwitch, i2.NgSwitchCase, i2.NgSwitchDefault, i3.DefaultValueAccessor, i3.NgControlStatus, i3.NgModel], encapsulation: 2 });
