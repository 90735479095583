// Vendor libraries
import { NgZone, Renderer2, RendererFactory2 } from '@angular/core';
// Utils / Constants
import { isMobile } from 'NewAngular/utils/media';
import * as i0 from "@angular/core";
export class ModalManagerService {
    constructor(ngZone, rendererFactory) {
        this.ngZone = ngZone;
        this.rendererFactory = rendererFactory;
        // A container is the div that is added to the DOM, containing two children:
        // 1. The backdrop, which is used to gray out the background display
        // 2. The modal content itself
        this.containers = [];
        // Store an array of the modals that are currently active, along with their close handlers
        this.modals = [];
        this.CLOSE_MODAL_EVENTS = ['mousedown', 'touchstart'];
        this.closeModalHelper = (evt) => {
            /**
             * Helper function that is bound to event listeners. Closes the top modal if
             * 1: mouse / touch clicks are outside of the top modal
             * 2: top modal does not require an explicit action to be closed.
             */
            // We want to check if the user has clicked outside of the top modal.
            // If so, close the top modal.
            const topModal = this.getTopModal();
            if (!topModal)
                return;
            const modalContent = topModal.modalElement.getElementsByClassName('modal-content')[0];
            if (!modalContent.contains(evt.target) && // typecast EventTarget to HTMLElement
                !ModalManagerService.isValidOuterClickEvent(evt) &&
                !topModal.requiresAction) {
                topModal.closeHandler();
            }
        };
        this.renderer = rendererFactory.createRenderer(null, null);
    }
    getTopModal() {
        /**
         * Returns the top modal if it exists, otherwise null
         */
        if (this.modals.length > 0) {
            return this.modals[this.modals.length - 1];
        }
        return null;
    }
    addModal(modal) {
        /**
         * Add a container (that contains a modal and a backdrop) to the DOM.
         * Update our `modals` and `containers` arrays.
         */
        const container = this.renderer.createElement('div');
        const newBackdrop = this.createNewBackdrop();
        // Add the modal and backdrop to the container, and add the container to the DOM
        this.renderer.appendChild(container, newBackdrop);
        this.renderer.appendChild(container, modal.modalElement);
        this.renderer.appendChild(document.body, container); // do this last so we update the DOM in one go
        this.containers.push(container);
        this.modals.push(modal);
        // Only add event listeners once the first modal exists. When all modals are removed,
        // we will remove the event listeners
        if (this.modals.length === 1) {
            // Run event listeners outside of Angular context so they don't trigger unnecessary change detection cycles
            this.ngZone.runOutsideAngular(() => {
                this.CLOSE_MODAL_EVENTS.forEach((eventName) => {
                    document.addEventListener(eventName, this.closeModalHelper, { passive: true });
                });
            });
        }
    }
    removeModal(modalElement) {
        /**
         * Remove the container containing modal from the DOM.
         * Update our `modals` and `containers` arrays.
         */
        // Find the index of the modal to remove. In practice, this should always be the top modal
        const modalIndex = this.modals.findIndex((i) => i.modalElement === modalElement);
        this.modals.splice(modalIndex, 1);
        // Containers map one-to-one with modals, so they should always have the same index
        const containerIndex = modalIndex;
        const container = this.containers[containerIndex];
        this.containers.splice(containerIndex, 1);
        // Remove the container from the DOM
        const containerParentNode = this.renderer.parentNode(container);
        this.renderer.removeChild(containerParentNode, container);
        // Since we have 0 modals now, remove all event listeners for performance reasons.
        if (this.modals.length === 0) {
            // Create a single event listener for this service
            this.CLOSE_MODAL_EVENTS.forEach((eventName) => {
                document.removeEventListener(eventName, this.closeModalHelper);
            });
        }
    }
    createNewBackdrop() {
        /**
         * Returns a new backdrop div, which grays out the rest of the screen behind the modal
         */
        const backdrop = this.renderer.createElement('div');
        backdrop.className = 'modal-backdrop show';
        return backdrop;
    }
    disableScrolling() {
        /**
         * Disables scrolling on the body. Called when the first modal is opened.
         */
        setTimeout(() => {
            this.renderer.addClass(document.body, 'modal-open');
            if (isMobile()) {
                this.renderer.setStyle(document.body, 'overflow', 'hidden');
                this.renderer.setStyle(document.body, 'position', 'fixed');
            }
        }, 0);
    }
    enableScrolling() {
        /**
         * Re-enables scrolling on the body. Called when the final modal is closed.
         */
        setTimeout(() => {
            this.renderer.removeClass(document.body, 'modal-open');
            if (isMobile()) {
                this.renderer.setStyle(document.body, 'overflow', '');
                this.renderer.setStyle(document.body, 'position', '');
            }
        }, 0);
    }
    static hasAppendToBodyClass(target) {
        /**
         * Returns true if the target has a 'ycnAppendToBody' css class
         */
        return target.classList && target.classList.contains('ycnAppendToBody');
    }
    static isValidOuterClickEvent(event) {
        /**
         * Normally we close modals when a user clicks outside of the modal body. However there are 2 valid
         * scenarios where a user might click outside the modal, but we don't want to close it:
         *   1. The target element (or one of its ancestors) is part of a Pendo Guide
         *   2. The target element (or one of its ancestors) has the AppendToBodyDirective
         *
         *  This function returns true if one of the above conditions is true.
         */
        let target = event.target;
        // Check scenario 1
        const pendoGuideContainer = document.getElementById('pendo-guide-container');
        if (pendoGuideContainer?.contains(target))
            return true;
        // Check scenario 2
        if (ModalManagerService.hasAppendToBodyClass(target))
            return true;
        let found = false;
        while (!found && target.parentNode) {
            target = target.parentNode;
            found = ModalManagerService.hasAppendToBodyClass(target);
        }
        return found;
    }
    getModalManager(modalElement, closeHandler, requiresAction) {
        /**
         * Returns the actual modal manager that should be used from the modal base component.
         * It offers two functions: `open` and `close`
         *
         * @param modalElement - The HTML of the modal itself
         * @param closeHandler - The function to be called when the modal is closed
         * @param requiresAction - Whether the user is forced to explicitly (X, submit, cancel) the modal to close it
         */
        const that = this;
        return {
            open() {
                that.addModal({ modalElement, closeHandler, requiresAction });
                if (that.modals.length === 1)
                    that.disableScrolling();
            },
            close() {
                that.removeModal(modalElement);
                if (that.modals.length === 0)
                    that.enableScrolling();
            },
        };
    }
}
ModalManagerService.ɵfac = function ModalManagerService_Factory(t) { return new (t || ModalManagerService)(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i0.RendererFactory2)); };
ModalManagerService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ModalManagerService, factory: ModalManagerService.ɵfac, providedIn: 'root' });
