import { Store } from '@ngrx/store';
import { deleteAllNotifications, setFetchNotificationsSuccess, } from 'NotificationsApp/ts/features/notifications-modal/state/notifications-modal/notifications-modal.actions';
import { getNotifications } from 'NotificationsApp/ts/features/notifications-modal/state/notifications-modal.selectors';
import { NOTIFICATIONS_MODAL_STATE_SLICE_KEY } from 'NotificationsApp/ts/features/notifications-modal/state/notifications-modal.store';
// Services
import { NotificationsService } from 'NotificationsApp/ts/services/notifications.service';
import * as i0 from "@angular/core";
import * as i1 from "NotificationsApp/ts/services/notifications.service";
import * as i2 from "@ngrx/store";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../../../../../ycharts/static/ts/features/directives/icon/icon.directive";
import * as i5 from "NotificationsApp/ts/features/notifications-modal/components/notifications-list-item/notifications-list-item.component";
const _c0 = a0 => ({ "disabled": a0 });
function NotificationsModalComponent_div_0_table_11_tr_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "tr", 14);
} if (rf & 2) {
    const notification_r3 = ctx.$implicit;
    i0.ɵɵproperty("notification", notification_r3);
} }
function NotificationsModalComponent_div_0_table_11_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "table", 12)(1, "tbody");
    i0.ɵɵtemplate(2, NotificationsModalComponent_div_0_table_11_tr_2_Template, 1, 1, "tr", 13);
    i0.ɵɵelementEnd()();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngForOf", ctx_r1.notifications)("ngForTrackBy", ctx_r1.trackByTypeAndId);
} }
function NotificationsModalComponent_div_0_div_12_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 15);
    i0.ɵɵtext(1, "No new notifications.");
    i0.ɵɵelementEnd();
} }
function NotificationsModalComponent_div_0_Template(rf, ctx) { if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1)(1, "div", 2)(2, "div", 3);
    i0.ɵɵtext(3, "Notifications");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "div", 4)(5, "a", 5);
    i0.ɵɵlistener("click", function NotificationsModalComponent_div_0_Template_a_click_5_listener() { i0.ɵɵrestoreView(_r1); const ctx_r1 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r1.deleteAllNotifications()); });
    i0.ɵɵtext(6, "Dismiss All");
    i0.ɵɵelementEnd();
    i0.ɵɵelement(7, "div", 6);
    i0.ɵɵelementStart(8, "div", 7);
    i0.ɵɵlistener("click", function NotificationsModalComponent_div_0_Template_div_click_8_listener() { i0.ɵɵrestoreView(_r1); const ctx_r1 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r1.closeModal()); });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(9, "svg", 8);
    i0.ɵɵelementEnd()()();
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(10, "div", 9);
    i0.ɵɵtemplate(11, NotificationsModalComponent_div_0_table_11_Template, 3, 2, "table", 10)(12, NotificationsModalComponent_div_0_div_12_Template, 2, 0, "div", 11);
    i0.ɵɵelementEnd()();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(3, _c0, ctx_r1.notifications.length == 0));
    i0.ɵɵadvance(6);
    i0.ɵɵproperty("ngIf", ctx_r1.notifications.length > 0);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.notifications.length == 0);
} }
export class NotificationsModalComponent {
    constructor(notificationsService, store) {
        this.notificationsService = notificationsService;
        this.store = store;
        this.isOpen = false;
        this.loading = false;
        this.notifications = [];
        this.mapStateToComponent = (state) => {
            this.notifications = getNotifications(state);
        };
        this.storeSubscription = this.store
            .select(NOTIFICATIONS_MODAL_STATE_SLICE_KEY)
            .subscribe(this.mapStateToComponent);
    }
    ngOnInit() {
        this.fetchNotifications();
    }
    ngOnDestroy() {
        this.storeSubscription.unsubscribe();
    }
    openModal() {
        this.isOpen = true;
    }
    closeModal() {
        this.isOpen = false;
    }
    deleteAllNotifications() {
        this.notificationsService.deleteNotifications().subscribe(() => {
            this.store.dispatch(deleteAllNotifications());
        });
    }
    fetchNotifications() {
        this.loading = true;
        this.notificationsService.getNotifications().subscribe({
            next: (notifications) => {
                this.store.dispatch(setFetchNotificationsSuccess({ notifications }));
            },
            error: () => { },
            complete: () => {
                this.loading = false;
            },
        });
    }
    trackByTypeAndId(index, notification) {
        return notification.type + notification.id;
    }
}
NotificationsModalComponent.ɵfac = function NotificationsModalComponent_Factory(t) { return new (t || NotificationsModalComponent)(i0.ɵɵdirectiveInject(i1.NotificationsService), i0.ɵɵdirectiveInject(i2.Store)); };
NotificationsModalComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: NotificationsModalComponent, selectors: [["ycn-notifications-modal"]], decls: 1, vars: 1, consts: [["class", "notifications collapse show", "id", "notifications", 4, "ngIf"], ["id", "notifications", 1, "notifications", "collapse", "show"], [1, "notifications-header"], [1, "notifications-header-title"], [1, "notifications-header-options"], ["href", "#", 1, "align-self-center", 3, "click", "ngClass"], [1, "divider-vertical", "mr-1", "ml-1"], ["data-toggle", "collapse", "data-target", "notifications", 1, "btn", "btn-xs", "btn-circle", "btn-transparent2", 3, "click"], ["ycnIcon", "", "name", "icon-close"], [1, "notifications-body", "panel-content"], ["class", "table table-hover mb-1 ng-scope", 4, "ngIf"], ["class", "notifications-empty", 4, "ngIf"], [1, "table", "table-hover", "mb-1", "ng-scope"], ["ycn-notifications-list-item", "", "class", "notification", 3, "notification", 4, "ngFor", "ngForOf", "ngForTrackBy"], ["ycn-notifications-list-item", "", 1, "notification", 3, "notification"], [1, "notifications-empty"]], template: function NotificationsModalComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, NotificationsModalComponent_div_0_Template, 13, 5, "div", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.isOpen);
    } }, dependencies: [i3.NgClass, i3.NgForOf, i3.NgIf, i4.IconDirective, i5.NotificationsListItemComponent], encapsulation: 2 });
