// Vendor libraries
import { SimpleChanges } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
const _c0 = a0 => ({ "options-list": a0 });
const _c1 = a0 => ({ "options-list-item": a0 });
const _c2 = a0 => ({ "form-control": a0 });
function SkeletonLoadersListComponent_div_0_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵelement(1, "div", 5);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const row_r1 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(2, _c2, ctx_r1.large))("ngStyle", row_r1);
} }
function SkeletonLoadersListComponent_div_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1)(1, "div", 2);
    i0.ɵɵtemplate(2, SkeletonLoadersListComponent_div_0_div_2_Template, 2, 4, "div", 3);
    i0.ɵɵelementEnd()();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(3, _c0, ctx_r1.border));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(5, _c1, ctx_r1.border));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r1.rows);
} }
/**
 *
 * Skeleton Loaders Component
 *
 * This component is responsible for displaying an animated placeholder for the information that is still loading,
 * giving an idea regarding what kind of content is loading in each block like image.
 */
export class SkeletonLoadersListComponent {
    constructor() {
        this.border = true;
        this.large = false;
    }
    ngOnInit() {
        // Default to 1 if numOfSkeletonLoaders is 0
        this.numOfSkeletonLoaders = Math.max(1, this.numOfSkeletonLoaders);
        this.rows = this.getSkeletonRows(this.numOfSkeletonLoaders);
    }
    ngOnChanges(changes) {
        if (changes.numOfSkeletonLoaders &&
            Number(changes.numOfSkeletonLoaders.currentValue) &&
            changes.numOfSkeletonLoaders.currentValue > 0) {
            // Get the maximum of the min number of loaders or the current value.
            const numRows = Math.max(1, changes.numOfSkeletonLoaders.currentValue);
            this.rows = this.getSkeletonRows(numRows);
        }
    }
    getSkeletonRows(numRows) {
        // generate a random percentages in range [30, 70] to define an arbitrary skeleton loader width
        const rows = [];
        for (let i = 0; i < numRows; i += 1) {
            rows.push({ width: `${Math.floor(Math.random() * 40) + 30}%` });
        }
        return rows;
    }
}
SkeletonLoadersListComponent.ɵfac = function SkeletonLoadersListComponent_Factory(t) { return new (t || SkeletonLoadersListComponent)(); };
SkeletonLoadersListComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: SkeletonLoadersListComponent, selectors: [["ycn-skeleton-loaders-list"]], inputs: { border: "border", large: "large", loading: "loading", numOfSkeletonLoaders: "numOfSkeletonLoaders" }, features: [i0.ɵɵNgOnChangesFeature], decls: 1, vars: 1, consts: [["class", "user-select-none", 3, "ngClass", 4, "ngIf"], [1, "user-select-none", 3, "ngClass"], [3, "ngClass"], ["class", "options-list-skeleton", 4, "ngFor", "ngForOf"], [1, "options-list-skeleton"], [1, "skeleton", 3, "ngClass", "ngStyle"]], template: function SkeletonLoadersListComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, SkeletonLoadersListComponent_div_0_Template, 3, 7, "div", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.loading);
    } }, dependencies: [i1.NgClass, i1.NgForOf, i1.NgIf, i1.NgStyle], encapsulation: 2 });
