import * as __NgCli_bootstrap_1 from "@angular/platform-browser";
// Vendor libraries
import { enableProdMode } from '@angular/core';
import { platformBrowser } from '@angular/platform-browser';
import 'NewAngular/polyfills';
export function bootstrapAppModule(appModule) {
    if (process.env.YC_CODE_ENV === 'development') {
        __NgCli_bootstrap_1.platformBrowser().bootstrapModule(appModule);
    }
    else {
        enableProdMode();
        platformBrowser().bootstrapModule(appModule);
    }
}
