import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../apps/search/static/search/ts/features/base-autocomplete/components/base-autocomplete.component";
export class ErrorSearchComponent {
    searchHandler(selectedSearchObject) {
        let successfulSubmit = false;
        if (typeof selectedSearchObject !== 'undefined') {
            successfulSubmit = true;
            const { url } = selectedSearchObject;
            window.location.href = url;
        }
        return successfulSubmit;
    }
}
ErrorSearchComponent.ɵfac = function ErrorSearchComponent_Factory(t) { return new (t || ErrorSearchComponent)(); };
ErrorSearchComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ErrorSearchComponent, selectors: [["ycn-error-search"]], decls: 1, vars: 1, consts: [["buttonType", "icon-search", "searchType", "main", 1, "field-input-group", "input-group", "dropdown", "show", 3, "submitHandler", "appendToBody"]], template: function ErrorSearchComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "ycn-base-autocomplete", 0);
        i0.ɵɵlistener("submitHandler", function ErrorSearchComponent_Template_ycn_base_autocomplete_submitHandler_0_listener($event) { return ctx.searchHandler($event); });
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵproperty("appendToBody", true);
    } }, dependencies: [i1.BaseAutocompleteComponent], encapsulation: 2 });
