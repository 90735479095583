// Vendor
import { createReducer, on } from '@ngrx/store';
// Actions
import { deleteAllNotifications, setFetchNotificationsSuccess, } from 'NotificationsApp/ts/features/notifications-modal/state/notifications-modal/notifications-modal.actions';
import { deleteNotification, markNotificationRead, } from 'NotificationsApp/ts/features/notifications-modal/state/notifications-list-item/notification-list-item.action';
export const notifications = createReducer([], on(setFetchNotificationsSuccess, (state, action) => {
    return action.notifications;
}), on(deleteAllNotifications, () => {
    return [];
}), on(deleteNotification, (state, action) => {
    return state.filter((notification) => {
        return !(notification.id === action.notification.id && notification.type === action.notification.type);
    });
}), on(markNotificationRead, (state, action) => {
    return state.map((notification) => {
        return notification.id === action.notification.id && notification.type === action.notification.type
            ? { ...notification, read: true }
            : notification;
    });
}));
