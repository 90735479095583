import { Store } from '@ngrx/store';
import { deleteNotification, markNotificationRead, } from 'NotificationsApp/ts/features/notifications-modal/state/notifications-list-item/notification-list-item.action';
// Services
import { NotificationsService } from 'NotificationsApp/ts/services/notifications.service';
import * as i0 from "@angular/core";
import * as i1 from "NotificationsApp/ts/services/notifications.service";
import * as i2 from "@ngrx/store";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../../../../../ycharts/static/ts/features/directives/icon/icon.directive";
const _c0 = ["ycn-notifications-list-item", ""];
const _c1 = a0 => ({ "unread": a0 });
function NotificationsListItemComponent_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.errorMessage);
} }
function NotificationsListItemComponent_div_3_span_4_Template(rf, ctx) { if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 10);
    i0.ɵɵlistener("click", function NotificationsListItemComponent_div_3_span_4_Template_span_click_0_listener() { i0.ɵɵrestoreView(_r2); const ctx_r0 = i0.ɵɵnextContext(2); return i0.ɵɵresetView(ctx_r0.toggleExpanded()); });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.expanded ? "Less" : "More");
} }
function NotificationsListItemComponent_div_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵelementContainerStart(1);
    i0.ɵɵtext(2);
    i0.ɵɵelementContainerEnd();
    i0.ɵɵtext(3, "\u00A0 ");
    i0.ɵɵtemplate(4, NotificationsListItemComponent_div_3_span_4_Template, 2, 1, "span", 9);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r0.expandable && ctx_r0.expanded || !ctx_r0.expandable ? ctx_r0.notification.message : ctx_r0.notification.message.slice(0, 30) + "...", " ");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.expandable);
} }
export class NotificationsListItemComponent {
    constructor(notificationsService, store) {
        this.notificationsService = notificationsService;
        this.store = store;
        this.expandable = false;
        this.expanded = false;
    }
    ngOnInit() {
        this.expandable = !!this.notification.message && this.notification.message.length > 30;
    }
    deleteNotification() {
        this.notificationsService.deleteNotification(this.notification).subscribe({
            next: () => {
                this.store.dispatch(deleteNotification({ notification: this.notification }));
            },
            error: (rsp) => {
                this.errorMessage = rsp.error;
            },
        });
    }
    markNotificationRead() {
        if (!this.notification.read) {
            this.notificationsService.markNotificationAsRead(this.notification).subscribe({
                next: () => {
                    this.store.dispatch(markNotificationRead({ notification: this.notification }));
                },
                error: (rsp) => {
                    this.errorMessage = rsp.error;
                },
            });
        }
    }
    toggleExpanded() {
        this.expanded = !this.expanded;
    }
}
NotificationsListItemComponent.ɵfac = function NotificationsListItemComponent_Factory(t) { return new (t || NotificationsListItemComponent)(i0.ɵɵdirectiveInject(i1.NotificationsService), i0.ɵɵdirectiveInject(i2.Store)); };
NotificationsListItemComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: NotificationsListItemComponent, selectors: [["tr", "ycn-notifications-list-item", ""]], inputs: { notification: "notification" }, attrs: _c0, decls: 10, vars: 10, consts: [[3, "click"], ["class", "text-red", "style", "display: flex; justify-content: center; align-items: center", 4, "ngIf"], [1, "notification-title", 3, "ngClass", "innerHTML"], ["class", "notification-descr", 4, "ngIf"], [1, "notification-date"], [1, "btn", "btn-xs", "btn-circle", "btn-transparent", "btn-close", 3, "click"], ["ycnIcon", "", "name", "icon-close-circle"], [1, "text-red", 2, "display", "flex", "justify-content", "center", "align-items", "center"], [1, "notification-descr"], ["class", "readmore", 3, "click", 4, "ngIf"], [1, "readmore", 3, "click"]], template: function NotificationsListItemComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "td", 0);
        i0.ɵɵlistener("click", function NotificationsListItemComponent_Template_td_click_0_listener() { return ctx.markNotificationRead(); });
        i0.ɵɵtemplate(1, NotificationsListItemComponent_div_1_Template, 2, 1, "div", 1);
        i0.ɵɵelement(2, "div", 2);
        i0.ɵɵtemplate(3, NotificationsListItemComponent_div_3_Template, 5, 2, "div", 3);
        i0.ɵɵelementStart(4, "div", 4);
        i0.ɵɵtext(5);
        i0.ɵɵpipe(6, "date");
        i0.ɵɵelementEnd()();
        i0.ɵɵelementStart(7, "td")(8, "div", 5);
        i0.ɵɵlistener("click", function NotificationsListItemComponent_Template_div_click_8_listener() { return ctx.deleteNotification(); });
        i0.ɵɵnamespaceSVG();
        i0.ɵɵelement(9, "svg", 6);
        i0.ɵɵelementEnd()();
    } if (rf & 2) {
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.errorMessage);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(8, _c1, !ctx.notification.read))("innerHTML", ctx.notification.label, i0.ɵɵsanitizeHtml);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.notification.message);
        i0.ɵɵadvance(2);
        i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(6, 5, ctx.notification.create_date, "MMM d, y 'at' h:mm a"));
    } }, dependencies: [i3.NgClass, i3.NgIf, i4.IconDirective, i3.DatePipe], encapsulation: 2 });
