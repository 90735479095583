import { Store } from '@ngrx/store';
// NgRx
import { NOTIFICATIONS_MODAL_STATE_SLICE_KEY } from 'NotificationsApp/ts/features/notifications-modal/state/notifications-modal.store';
import { getUnreadNotificationsCount } from 'NotificationsApp/ts/features/notifications-modal/state/notifications-modal.selectors';
// Services
import { NotificationsService } from 'NotificationsApp/ts/services/notifications.service';
import * as i0 from "@angular/core";
import * as i1 from "NotificationsApp/ts/services/notifications.service";
import * as i2 from "@ngrx/store";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../../../../../ycharts/static/ts/features/directives/icon/icon.directive";
import * as i5 from "../../../../../../../../../ycharts/static/ts/features/directives/popovers/tooltip/tooltip.directive";
function NotificationsModalToggleComponent_div_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.unreadNotificationsCount > 10 ? "10+" : ctx_r0.unreadNotificationsCount);
} }
export class NotificationsModalToggleComponent {
    constructor(notificationsService, store) {
        this.notificationsService = notificationsService;
        this.store = store;
        this.unreadNotificationsCount = 0;
        this.mapStateToComponent = (state) => {
            this.unreadNotificationsCount = getUnreadNotificationsCount(state);
        };
        this.storeSubscription = this.store
            .select(NOTIFICATIONS_MODAL_STATE_SLICE_KEY)
            .subscribe(this.mapStateToComponent);
    }
    ngOnDestroy() {
        this.storeSubscription.unsubscribe();
    }
}
NotificationsModalToggleComponent.ɵfac = function NotificationsModalToggleComponent_Factory(t) { return new (t || NotificationsModalToggleComponent)(i0.ɵɵdirectiveInject(i1.NotificationsService), i0.ɵɵdirectiveInject(i2.Store)); };
NotificationsModalToggleComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: NotificationsModalToggleComponent, selectors: [["ycn-notifications-modal-toggle"]], decls: 4, vars: 2, consts: [["data-toggle", "collapse", "data-target", "notifications", "ycnTooltip", "", 1, "sidemenu-icon", "has-badge", 3, "tooltipTitle"], [1, "sidemenu-wrapper"], ["ycnIcon", "", "name", "icon-notification", 1, "icon-notification"], ["class", "badge bg-red notifications-badge", 4, "ngIf"], [1, "badge", "bg-red", "notifications-badge"]], template: function NotificationsModalToggleComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0)(1, "div", 1);
        i0.ɵɵnamespaceSVG();
        i0.ɵɵelement(2, "svg", 2);
        i0.ɵɵtemplate(3, NotificationsModalToggleComponent_div_3_Template, 2, 1, "div", 3);
        i0.ɵɵelementEnd()();
    } if (rf & 2) {
        i0.ɵɵproperty("tooltipTitle", "Notifications");
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", ctx.unreadNotificationsCount);
    } }, dependencies: [i3.NgIf, i4.IconDirective, i5.TooltipDirective], encapsulation: 2 });
