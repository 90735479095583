import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
// Modules
import { DisabledModule } from 'NewAngular/features/directives/disabled/disabled.module';
import { IconModule } from 'NewAngular/features/directives/icon/icon.module';
import { ModalBaseModule } from 'NewAngular/features/components/modals/modal-base/modal-base.module';
import { NgModelChangeDebouncedModule } from 'NewAngular/features/directives/ng-model-change-debounced/ng-model-change-debounced.module';
import * as i0 from "@angular/core";
export class InviteFriendModalModule {
}
InviteFriendModalModule.ɵfac = function InviteFriendModalModule_Factory(t) { return new (t || InviteFriendModalModule)(); };
InviteFriendModalModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: InviteFriendModalModule });
InviteFriendModalModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [CommonModule, DisabledModule, FormsModule, ModalBaseModule, NgModelChangeDebouncedModule, IconModule] });
