/**
 * Get an html string with special character codes converted into the actual characters. Ex: `&lt;` => `<`.
 * If the html string contains more than one node, the nodes will be joined by the given delimiter.
 *
 * Source: https://stackoverflow.com/questions/1248849/converting-sanitised-html-back-to-displayable-html
 *
 * @param htmlStr: A string of html.
 * @param delimiter: A string used to connect the nodes in htmlStr.
 */
export const decodeHtml = function (htmlStr, delimiter) {
    // Create an html element to get access to the DOM api
    const div = document.createElement('div');
    div.innerHTML = htmlStr;
    const htmlStrs = [];
    div.childNodes.forEach((childNode) => {
        if (childNode.nodeValue)
            htmlStrs.push(childNode.nodeValue);
    });
    return htmlStrs.join(delimiter);
};
/**
 * Get an html string with html characters converted into special characters.
 * Ex:
 * '<' into '&lt;'
 * '>' into '&gt;'
 *
 * @param htmlStr: A string of html.
 */
export const encodeHtml = function (htmlStr) {
    const textArea = document.createElement('div');
    textArea.innerText = htmlStr;
    let encodedOutput = textArea.innerHTML;
    const arr = encodedOutput.split('<br>');
    encodedOutput = arr.join('\n');
    return encodedOutput;
};
