// Vendor libraries
import { ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
// Utils / constants
import { EXTRA_CATEGORY_ORDER } from 'SearchApp/ts/constants';
// NgRx actions / selectors
import { blurAutocompleter, selectResultByIndex, setAutocompleteSelectedIndex, } from 'SearchApp/ts/features/base-autocomplete/state/autocompleter.actions';
import { AUTOCOMPLETER_STATE_SLICE_KEY } from 'SearchApp/ts/features/base-autocomplete/state/autocompleter.reducer';
// Services
import { AccountsService } from 'AccountsApp/ts/services/accounts.service';
import * as i0 from "@angular/core";
import * as i1 from "AccountsApp/ts/services/accounts.service";
import * as i2 from "@ngrx/store";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../../../../ycharts/static/ts/features/directives/popovers/tooltip/tooltip.directive";
import * as i5 from "../../../../../../../../ycharts/static/ts/features/directives/icon/icon.directive";
import * as i6 from "../../../../../../../../ycharts/static/ts/features/pipes/index-of/index-of.pipe";
const _forTrack0 = ($index, $item) => $item.label;
const _c0 = a0 => ({ "active": a0 });
function ExtraLinksComponent_Conditional_0_Template(rf, ctx) { if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "section", 1);
    i0.ɵɵlistener("click", function ExtraLinksComponent_Conditional_0_Template_section_click_0_listener() { i0.ɵɵrestoreView(_r1); const ctx_r1 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r1.clickInsideComponent = true); });
    i0.ɵɵelementStart(1, "div", 2)(2, "div", 3);
    i0.ɵɵtext(3, "No results containing all your search terms were found. ");
    i0.ɵɵelementStart(4, "div", 4)(5, "p", 5);
    i0.ɵɵtext(6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "p", 5);
    i0.ɵɵtext(8, "Suggestions:");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(9, "ul", 6)(10, "li");
    i0.ɵɵtext(11, "Check your spelling.");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(12, "li");
    i0.ɵɵtext(13, "Try different keywords.");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(14, "li");
    i0.ɵɵtext(15, "Try more general keywords");
    i0.ɵɵelementEnd()()()()()();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate1("Your search - ", ctx_r1.inputText, " - did not result in any matches.");
} }
function ExtraLinksComponent_For_2_Conditional_1_Template(rf, ctx) { if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 7)(1, "span", 9);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "a", 10);
    i0.ɵɵlistener("click", function ExtraLinksComponent_For_2_Conditional_1_Template_a_click_3_listener() { i0.ɵɵrestoreView(_r4); const extraCategory_r5 = i0.ɵɵnextContext().$implicit; const ctx_r1 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r1.toggleSection(extraCategory_r5)); });
    i0.ɵɵelementEnd()();
} if (rf & 2) {
    const extraCategory_r5 = i0.ɵɵnextContext().$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r1.extraLinksHeaders[extraCategory_r5] == null ? null : ctx_r1.extraLinksHeaders[extraCategory_r5].label);
    i0.ɵɵadvance();
    i0.ɵɵstyleProp("rotate", (ctx_r1.extraLinksHeaders[extraCategory_r5] == null ? null : ctx_r1.extraLinksHeaders[extraCategory_r5].isOpen) ? "0deg" : "90deg");
    i0.ɵɵproperty("tooltipTitle", (ctx_r1.extraLinksHeaders[extraCategory_r5] == null ? null : ctx_r1.extraLinksHeaders[extraCategory_r5].isOpen) ? "Close" : "Open");
} }
function ExtraLinksComponent_For_2_For_4_Case_0_Template(rf, ctx) { if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 11);
    i0.ɵɵpipe(1, "indexOf");
    i0.ɵɵlistener("mouseenter", function ExtraLinksComponent_For_2_For_4_Case_0_Template_div_mouseenter_0_listener() { i0.ɵɵrestoreView(_r6); const extraLink_r7 = i0.ɵɵnextContext().$implicit; const ctx_r1 = i0.ɵɵnextContext(2); return i0.ɵɵresetView(ctx_r1.setSelectedIndexOnMouseEnter(extraLink_r7)); })("click", function ExtraLinksComponent_For_2_For_4_Case_0_Template_div_click_0_listener() { i0.ɵɵrestoreView(_r6); const ctx_r1 = i0.ɵɵnextContext(3); return i0.ɵɵresetView(ctx_r1.clickResult()); });
    i0.ɵɵelementStart(2, "div", 3)(3, "span")(4, "span", 12);
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd();
    i0.ɵɵtext(6);
    i0.ɵɵelementEnd()()();
} if (rf & 2) {
    const extraLink_r7 = i0.ɵɵnextContext().$implicit;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(6, _c0, i0.ɵɵpipeBind2(1, 3, ctx_r1.flatResults, extraLink_r7) === ctx_r1.selectedIndex));
    i0.ɵɵadvance(5);
    i0.ɵɵtextInterpolate(extraLink_r7.label);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1("\u00A0", extraLink_r7.description, "");
} }
function ExtraLinksComponent_For_2_For_4_Case_1_Template(rf, ctx) { if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 13);
    i0.ɵɵpipe(1, "indexOf");
    i0.ɵɵlistener("mouseenter", function ExtraLinksComponent_For_2_For_4_Case_1_Template_a_mouseenter_0_listener() { i0.ɵɵrestoreView(_r8); const extraLink_r7 = i0.ɵɵnextContext().$implicit; const ctx_r1 = i0.ɵɵnextContext(2); return i0.ɵɵresetView(ctx_r1.setSelectedIndexOnMouseEnter(extraLink_r7)); })("click", function ExtraLinksComponent_For_2_For_4_Case_1_Template_a_click_0_listener() { i0.ɵɵrestoreView(_r8); const ctx_r1 = i0.ɵɵnextContext(3); return i0.ɵɵresetView(ctx_r1.clickResult()); });
    i0.ɵɵelementStart(2, "div", 3)(3, "div", 14)(4, "div");
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(5, "svg", 15);
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(6, "div", 16);
    i0.ɵɵtext(7);
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(8, "span", 17);
    i0.ɵɵtext(9);
    i0.ɵɵelementEnd()()()();
} if (rf & 2) {
    const extraLink_r7 = i0.ɵɵnextContext().$implicit;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(8, _c0, i0.ɵɵpipeBind2(1, 5, ctx_r1.flatResults, extraLink_r7) === ctx_r1.selectedIndex));
    i0.ɵɵadvance(4);
    i0.ɵɵclassMap("file-icon file-" + extraLink_r7.suffix);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(extraLink_r7.suffix);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(extraLink_r7.label);
} }
function ExtraLinksComponent_For_2_For_4_Case_2_Template(rf, ctx) { if (rf & 1) {
    const _r9 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 11);
    i0.ɵɵpipe(1, "indexOf");
    i0.ɵɵlistener("mouseenter", function ExtraLinksComponent_For_2_For_4_Case_2_Template_div_mouseenter_0_listener() { i0.ɵɵrestoreView(_r9); const extraLink_r7 = i0.ɵɵnextContext().$implicit; const ctx_r1 = i0.ɵɵnextContext(2); return i0.ɵɵresetView(ctx_r1.setSelectedIndexOnMouseEnter(extraLink_r7)); })("click", function ExtraLinksComponent_For_2_For_4_Case_2_Template_div_click_0_listener() { i0.ɵɵrestoreView(_r9); const ctx_r1 = i0.ɵɵnextContext(3); return i0.ɵɵresetView(ctx_r1.clickResult()); });
    i0.ɵɵelementStart(2, "div", 3)(3, "span");
    i0.ɵɵtext(4, "Search YCharts for \"");
    i0.ɵɵelementStart(5, "span", 12);
    i0.ɵɵtext(6);
    i0.ɵɵelementEnd();
    i0.ɵɵtext(7, "\"");
    i0.ɵɵelementEnd()()();
} if (rf & 2) {
    const extraLink_r7 = i0.ɵɵnextContext().$implicit;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(5, _c0, i0.ɵɵpipeBind2(1, 2, ctx_r1.flatResults, extraLink_r7) === ctx_r1.selectedIndex));
    i0.ɵɵadvance(6);
    i0.ɵɵtextInterpolate(ctx_r1.inputText);
} }
function ExtraLinksComponent_For_2_For_4_Case_3_Conditional_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵtext(0);
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵtextInterpolate1(" ", "Send \"" + ctx_r1.inputText + "\" as a prompt to the", " ");
} }
function ExtraLinksComponent_For_2_For_4_Case_3_Conditional_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵtext(0);
} if (rf & 2) {
    i0.ɵɵtextInterpolate1(" ", "Try the new", " ");
} }
function ExtraLinksComponent_For_2_For_4_Case_3_Template(rf, ctx) { if (rf & 1) {
    const _r10 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 18);
    i0.ɵɵpipe(1, "indexOf");
    i0.ɵɵlistener("mouseenter", function ExtraLinksComponent_For_2_For_4_Case_3_Template_a_mouseenter_0_listener() { i0.ɵɵrestoreView(_r10); const extraLink_r7 = i0.ɵɵnextContext().$implicit; const ctx_r1 = i0.ɵɵnextContext(2); return i0.ɵɵresetView(ctx_r1.setSelectedIndexOnMouseEnter(extraLink_r7)); })("click", function ExtraLinksComponent_For_2_For_4_Case_3_Template_a_click_0_listener() { i0.ɵɵrestoreView(_r10); const ctx_r1 = i0.ɵɵnextContext(3); return i0.ɵɵresetView(ctx_r1.clickResult()); });
    i0.ɵɵelementStart(2, "div", 3)(3, "span");
    i0.ɵɵtemplate(4, ExtraLinksComponent_For_2_For_4_Case_3_Conditional_4_Template, 1, 1)(5, ExtraLinksComponent_For_2_For_4_Case_3_Conditional_5_Template, 1, 1);
    i0.ɵɵelementStart(6, "span", 12);
    i0.ɵɵtext(7, "AI Chat Tool");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(8, "div", 19);
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(9, "svg", 20);
    i0.ɵɵelementEnd()();
} if (rf & 2) {
    const extraLink_r7 = i0.ɵɵnextContext().$implicit;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(5, _c0, i0.ɵɵpipeBind2(1, 2, ctx_r1.flatResults, extraLink_r7) === ctx_r1.selectedIndex));
    i0.ɵɵadvance(4);
    i0.ɵɵconditional(4, ctx_r1.inputText.trim() !== "" ? 4 : 5);
} }
function ExtraLinksComponent_For_2_For_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵtemplate(0, ExtraLinksComponent_For_2_For_4_Case_0_Template, 7, 8)(1, ExtraLinksComponent_For_2_For_4_Case_1_Template, 10, 10)(2, ExtraLinksComponent_For_2_For_4_Case_2_Template, 8, 7)(3, ExtraLinksComponent_For_2_For_4_Case_3_Template, 10, 7);
} if (rf & 2) {
    let tmp_20_0;
    const extraLink_r7 = ctx.$implicit;
    i0.ɵɵconditional(0, (tmp_20_0 = extraLink_r7.type) === "security" ? 0 : tmp_20_0 === "asset" ? 1 : tmp_20_0 === "searchall" ? 2 : tmp_20_0 === "ai" ? 3 : -1);
} }
function ExtraLinksComponent_For_2_Template(rf, ctx) { if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "section", 1);
    i0.ɵɵlistener("click", function ExtraLinksComponent_For_2_Template_section_click_0_listener() { i0.ɵɵrestoreView(_r3); const ctx_r1 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r1.clickInsideComponent = true); });
    i0.ɵɵtemplate(1, ExtraLinksComponent_For_2_Conditional_1_Template, 4, 4, "div", 7);
    i0.ɵɵelementStart(2, "div", 8);
    i0.ɵɵrepeaterCreate(3, ExtraLinksComponent_For_2_For_4_Template, 4, 1, null, null, _forTrack0);
    i0.ɵɵelementEnd()();
} if (rf & 2) {
    const extraCategory_r5 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵconditional(1, ctx_r1.extraLinksHeaders[extraCategory_r5] ? 1 : -1);
    i0.ɵɵadvance();
    i0.ɵɵstyleProp("display", ctx_r1.extraLinksHeaders[extraCategory_r5] === undefined || (ctx_r1.extraLinksHeaders[extraCategory_r5] == null ? null : ctx_r1.extraLinksHeaders[extraCategory_r5].isOpen) ? "block" : "none");
    i0.ɵɵadvance();
    i0.ɵɵrepeater(ctx_r1.extraLinksCategories[extraCategory_r5]);
} }
/**
 *
 * Extra Links Component
 *
 * This component is responsible for:
 *   1. Displaying extra links in autocompleter
 *   2. Handling when a result is clicked and hovered
 *   3. Displaying no results layout
 *
 * Input Properties:
 *    autocompleterId: The UUID that was created by the parent component to manage NgRx state.
 */
export class ExtraLinksComponent {
    // Handle blurring the search input (closing default links box) after it has lost focus in areas of the component
    // that do not navigate
    onClick(event) {
        if (!this.elementRef.nativeElement.contains(event.target) && this.clickInsideComponent) {
            this.store.dispatch(blurAutocompleter({ autocompleterId: this.autocompleterId }));
            this.clickInsideComponent = false;
        }
    }
    constructor(elementRef, accountsService, store) {
        this.elementRef = elementRef;
        this.accountsService = accountsService;
        this.store = store;
        this.extraLinksCategories = {};
        this.extraCategoryResults = [];
        this.extraLinksHeaders = {
            asset: { label: 'Recent Activity', isOpen: true },
            security: { label: 'Recently Visited', isOpen: true },
        };
        this.flatResults = [];
        this.inputText = '';
        this.isFetching = false;
        this.selectedIndex = -1;
        this.showResults = false;
        this.showNoResultsLayout = false;
        this.prevShowNoResultsLayout = false;
        this.clickInsideComponent = false;
        this.mapStateToComponent = (state) => {
            const autocompleterState = state[this.autocompleterId];
            if (!autocompleterState) {
                return;
            }
            const { selectedIndex, results, extraLinks, inputText, showResults, isFetching } = autocompleterState;
            this.selectedIndex = selectedIndex;
            this.flatResults = [...results, ...extraLinks];
            this.inputText = inputText;
            this.showResults = showResults;
            this.isFetching = isFetching;
            // keep no results layout shown if its fetching but it was already shown
            this.showNoResultsLayout =
                this.inputText.trim() !== '' &&
                    !this.showResults &&
                    (!this.isFetching || (this.isFetching && this.showNoResultsLayout));
            this.prevShowNoResultsLayout = this.showNoResultsLayout;
            // Convert extra links to dict of categories
            this.extraLinksCategories = extraLinks.reduce((extraLinksDict, extraLink) => {
                const { type } = extraLink;
                return {
                    ...extraLinksDict,
                    [type]: !extraLinksDict[type]
                        ? [extraLink]
                        : [...extraLinksDict[type], extraLink],
                };
            }, {});
            this.extraCategoryResults = EXTRA_CATEGORY_ORDER.filter((category) => this.extraLinksCategories[category]);
        };
        this.storeSubscription = this.store.select(AUTOCOMPLETER_STATE_SLICE_KEY).subscribe(this.mapStateToComponent);
    }
    ngOnInit() {
        this.accountsService.getUserPreferences().subscribe((preference) => {
            const preferenceHeaderSearchSections = preference.header_search_sections_toggle;
            if (!preferenceHeaderSearchSections || Object.keys(preferenceHeaderSearchSections).length === 0) {
                return;
            }
            this.extraLinksHeaders = Object.keys(this.extraLinksHeaders).reduce((acc, section) => {
                const isOpen = preferenceHeaderSearchSections[section] !== undefined
                    ? preferenceHeaderSearchSections[section]
                    : this.extraLinksHeaders[section]?.isOpen;
                return {
                    ...acc,
                    [section]: {
                        label: this.extraLinksHeaders[section]?.label,
                        isOpen,
                    },
                };
            }, {});
        });
    }
    ngOnDestroy() {
        this.storeSubscription.unsubscribe();
    }
    clickResult() {
        this.store.dispatch(selectResultByIndex({ autocompleterId: this.autocompleterId }));
    }
    setSelectedIndexOnMouseEnter(result) {
        // changes selected index on mouseover so highlighted item can be submitted via enter or submit.
        const selectedIndex = this.flatResults.indexOf(result);
        this.store.dispatch(setAutocompleteSelectedIndex({ autocompleterId: this.autocompleterId, selectedIndex }));
    }
    toggleSection(category) {
        if (this.extraLinksHeaders[category] !== undefined) {
            const { isOpen } = this.extraLinksHeaders[category];
            this.extraLinksHeaders[category] = {
                ...this.extraLinksHeaders[category],
                isOpen: !isOpen,
            };
            const headerSectionsToggle = Object.keys(this.extraLinksHeaders).reduce((acc, section) => ({
                ...acc,
                [section]: this.extraLinksHeaders[section]?.isOpen,
            }), {});
            const params = { header_search_sections_toggle: JSON.stringify(headerSectionsToggle) };
            this.accountsService.putUserPreferences(params).subscribe();
        }
    }
}
ExtraLinksComponent.ɵfac = function ExtraLinksComponent_Factory(t) { return new (t || ExtraLinksComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.AccountsService), i0.ɵɵdirectiveInject(i2.Store)); };
ExtraLinksComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ExtraLinksComponent, selectors: [["ycn-extra-links"]], hostBindings: function ExtraLinksComponent_HostBindings(rf, ctx) { if (rf & 1) {
        i0.ɵɵlistener("click", function ExtraLinksComponent_click_HostBindingHandler($event) { return ctx.onClick($event); }, false, i0.ɵɵresolveDocument);
    } }, inputs: { autocompleterId: "autocompleterId" }, decls: 3, vars: 1, consts: [[1, "dropdown-section"], [1, "dropdown-section", 3, "click"], [1, "dropdown-comment"], [1, "dropdown-item-text"], [1, "text-gray-30", "text-small", "pt-4px", "pb-4px"], [1, "mb-0"], [1, "mb-0", "pl-20"], [1, "dropdown-header"], [1, "dropdown-section-body"], [1, "text-gray-30"], ["href", "javascript:void(0)", "ycnTooltip", "", "tooltipPlacement", "bottom", 1, "btn", "btn-transparent", "arrow-right", 3, "click", "tooltipTitle"], [1, "dropdown-item", 3, "mouseenter", "click", "ngClass"], [1, "text-bold"], ["href", "javascript:void(0)", 1, "dropdown-item", 3, "mouseenter", "click", "ngClass"], [1, "file-name"], ["ycnIcon", "", "name", "icon-file"], [1, "file-abbr"], [1, "text-default"], [1, "dropdown-item", "align-items-center", "bg-ai", 3, "mouseenter", "click", "ngClass"], [1, "dropdown-item-actions"], ["ycnIcon", "", "name", "icon-collapse"]], template: function ExtraLinksComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, ExtraLinksComponent_Conditional_0_Template, 16, 1, "section", 0);
        i0.ɵɵrepeaterCreate(1, ExtraLinksComponent_For_2_Template, 5, 3, "section", 0, i0.ɵɵrepeaterTrackByIndex);
    } if (rf & 2) {
        i0.ɵɵconditional(0, ctx.showNoResultsLayout ? 0 : -1);
        i0.ɵɵadvance();
        i0.ɵɵrepeater(ctx.extraCategoryResults);
    } }, dependencies: [i3.NgClass, i4.TooltipDirective, i5.IconDirective, i6.IndexOfPipe], encapsulation: 2 });
