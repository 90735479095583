import * as i0 from "@angular/core";
/**
 * Index Of Pipe
 *
 * Pure pipe that finds the index of a value within an array.
 * Using this pipe is preferable to calling array.indexOf inside a template
 * because this pipe only executes when one of the inputs changes. The object reference of the
 * input array must be changed in order to trigger execution. Adding items to the input array will
 * not cause the pipe to run again.
 */
export class IndexOfPipe {
    transform(array, value) {
        return array.indexOf(value);
    }
}
IndexOfPipe.ɵfac = function IndexOfPipe_Factory(t) { return new (t || IndexOfPipe)(); };
IndexOfPipe.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "indexOf", type: IndexOfPipe, pure: true });
