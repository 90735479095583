/*
 * Number Format Reverser
 *
 * e.g. 10k -> 10000
 *      10M -> 10000000
 */
import { FIGURE, BIL_FIGURE, MIL_FIGURE, PERCENT, QUADRIL_FIGURE, THOU_FIGURE, TRIL_FIGURE, K, M, B, T, Q, QN, S, SP, FIGURE_MONEY, FIGURE_WHOLE, THOU_FIGURE_MONEY, MIL_FIGURE_MONEY, BIL_FIGURE_MONEY, TRIL_FIGURE_MONEY, QUADRIL_FIGURE_MONEY, } from './number_formatter';
const NUMBER_REGEX = {
    [FIGURE]: /(-?(?:[0-9]+)?(?:\.[0-9]*)?)(k|m|b|t|q)?/i,
    [THOU_FIGURE]: /(-?(?:[0-9]+)?(?:\.[0-9]+)?)(k|m|b|t)?/i,
    [MIL_FIGURE]: /(-?(?:[0-9]+)?(?:\.[0-9]+)?)(m|b|t|q)?/i,
    [BIL_FIGURE]: /(-?(?:[0-9]+)?(?:\.[0-9]+)?)(b|t|qn|q)?/i,
    [TRIL_FIGURE]: /(-?(?:[0-9]+)?(?:\.[0-9]+)?)(t|qn|q|s)?/i,
    [QUADRIL_FIGURE]: /(-?(?:[0-9]+)?(?:\.[0-9]+)?)(qn|q|sp|s)?/i,
    [PERCENT]: /(-?[0-9]*(?:\.[0-9]+)?)(k)?%?/i,
};
/*
 * Reverse Formatting Functions
 * */
/**
 * Reverses a formatted number into units. If no figure in the number, just returns the number. If the number format
 * is wrong, returns false.
 *
 * @param value: Reversed number from figure number.
 */
function figureReverser(value) {
    const match = NUMBER_REGEX[FIGURE].exec(value);
    if (match === null || match[0] !== value)
        return false;
    let formattedReturnValue = parseFloat(match[1]);
    if (Number.isNaN(formattedReturnValue))
        return false;
    if (match[2]) {
        const unit = match[2].toUpperCase();
        switch (unit) {
            case K:
                formattedReturnValue *= 1e3;
                break;
            case M:
                formattedReturnValue *= 1e6;
                break;
            case B:
                formattedReturnValue *= 1e9;
                break;
            case T:
                formattedReturnValue *= 1e12;
                break;
            case Q:
                formattedReturnValue *= 1e15;
                break;
            // no default
        }
    }
    return formattedReturnValue;
}
/**
 * Converts a thou figure number.
 *
 * @param value: Reversed figure.
 */
function thouFigureReverser(value) {
    const match = value.match(NUMBER_REGEX[THOU_FIGURE]);
    if (match === null || match[0] !== value)
        return false;
    let formattedReturnValue = parseFloat(match[1]);
    if (match[2]) {
        const unit = match[2].toUpperCase();
        switch (unit) {
            case M:
                formattedReturnValue *= 1e3;
                break;
            case B:
                formattedReturnValue *= 1e6;
                break;
            case T:
                formattedReturnValue *= 1e9;
                break;
            // no default
        }
    }
    return formattedReturnValue;
}
/**
 * Converts a million-figure number.
 *
 * @param value: Reversed figure.
 */
function milFigureReverser(value) {
    const match = value.match(NUMBER_REGEX[MIL_FIGURE]);
    if (match === null || match[0] !== value)
        return false;
    let formattedReturnValue = parseFloat(match[1]);
    if (match[2]) {
        const unit = match[2].toUpperCase();
        switch (unit) {
            case B:
                formattedReturnValue *= 1e3;
                break;
            case T:
                formattedReturnValue *= 1e6;
                break;
            case Q:
                formattedReturnValue *= 1e9;
                break;
            // no default
        }
    }
    return formattedReturnValue;
}
/**
 * Converts a billion-figure number.
 *
 * @param value: Reversed figure.
 */
function bilFigureReverser(value) {
    const match = value.match(NUMBER_REGEX[BIL_FIGURE]);
    if (match === null || match[0] !== value)
        return false;
    let formattedReturnValue = parseFloat(match[1]);
    if (match[2]) {
        const unit = match[2].toUpperCase();
        switch (unit) {
            case T:
                formattedReturnValue *= 1e3;
                break;
            case Q:
                formattedReturnValue *= 1e6;
                break;
            case QN:
                formattedReturnValue *= 1e9;
                break;
            // no default
        }
    }
    return formattedReturnValue;
}
/**
 * Converts a trillion-figure number.
 *
 * @param value: Reversed figure.
 */
function trilFigureReverser(value) {
    const match = value.match(NUMBER_REGEX[TRIL_FIGURE]);
    if (match === null || match[0] !== value)
        return false;
    let formattedReturnValue = parseFloat(match[1]);
    if (match[2]) {
        const unit = match[2].toUpperCase();
        switch (unit) {
            case Q:
                formattedReturnValue *= 1e3;
                break;
            case QN:
                formattedReturnValue *= 1e6;
                break;
            case S:
                formattedReturnValue *= 1e9;
                break;
            // no default
        }
    }
    return formattedReturnValue;
}
/**
 * Converts a quadrillion-figure number.
 *
 * @param value: Reversed figure.
 */
function quadrilFigureReverser(value) {
    const match = value.match(NUMBER_REGEX[QUADRIL_FIGURE]);
    if (match === null || match[0] !== value)
        return false;
    let formattedReturnValue = parseFloat(match[1]);
    if (match[2]) {
        const unit = match[2].toUpperCase();
        switch (unit) {
            case QN:
                formattedReturnValue *= 1e3;
                break;
            case S:
                formattedReturnValue *= 1e6;
                break;
            case SP:
                formattedReturnValue *= 1e9;
                break;
            // no default
        }
    }
    return formattedReturnValue;
}
/**
 * Converts a percent-figure number.
 *
 * @param value: Reversed figure.
 */
function percentReverser(value) {
    const match = value.match(NUMBER_REGEX[PERCENT]);
    if (match === null || match[0] !== value)
        return false;
    let formattedReturnValue = parseFloat(match[1]);
    if (match[2] && match[2].toUpperCase() === K) {
        formattedReturnValue *= 1e3;
    }
    return formattedReturnValue;
}
/*
 * Get Formatter Functions
 * */
/**
 * Converts a unit formatted value into a number depending on the type of format.
 *
 * @param type: Unit number format.
 * @param value: Format value
 */
export function getReversedFigure(type, value) {
    switch (type) {
        case FIGURE:
        case FIGURE_MONEY:
        case FIGURE_WHOLE:
            return figureReverser(value);
        case THOU_FIGURE:
        case THOU_FIGURE_MONEY:
            return thouFigureReverser(value);
        case MIL_FIGURE:
        case MIL_FIGURE_MONEY:
            return milFigureReverser(value);
        case BIL_FIGURE:
        case BIL_FIGURE_MONEY:
            return bilFigureReverser(value);
        case TRIL_FIGURE:
        case TRIL_FIGURE_MONEY:
            return trilFigureReverser(value);
        case QUADRIL_FIGURE:
        case QUADRIL_FIGURE_MONEY:
            return quadrilFigureReverser(value);
        case PERCENT:
            return percentReverser(value);
        // no default
    }
    return false;
}
export function getBasicReversed(value, type) {
    if (type.indexOf(FIGURE) !== -1) {
        return getReversedFigure(FIGURE, value);
    }
    if (type.indexOf(PERCENT) !== -1) {
        return getReversedFigure(PERCENT, value);
    }
    return getReversedFigure(type, value);
}
