import shallowEqual from 'NewAngular/utils/equal/shallow-equal';
/**
 *
 * Given an array, count the "number of levels" for URL serialization purposes and return the
 * appropriate top-level delimiter (i.e. 1, 2, or 3 commmas)
 * These are the structures we support:
 *     Level 1: [A, B, C]
 *     Level 2: [{A:X}, {B:Y}, {C:Z}]
 *     Level 3: [{A:X, B:Y}, {Q:X, P:Y}, {A:X, B:[1,2,3]}]
 */
function keyList(object) {
    // historically, keys are retrieved via `for key in obj`. This is notorious because it treats
    // null as an object with no keys. We maintain backward compatibility here by handling null.
    return object === null ? [] : Object.keys(object);
}
export const convertStringToProperType = (val) => {
    if (typeof val !== 'string' || val.trim().length !== val.length)
        return val;
    if (val === 'true')
        return true;
    if (val === 'false')
        return false;
    const parsedToNum = Number(val);
    return val !== '' && !Number.isNaN(parsedToNum) ? parsedToNum : val;
};
export const getDelimForArray = (arr) => {
    let delim = ',';
    for (let i = 0; i < arr.length; i += 1) {
        const arrItem = arr[i];
        // If an item in the array is an object, we know we're at least 2 levels
        if (typeof arrItem === 'object') {
            delim = ',,';
            // Check all properties of the object
            // If a value of the object is an array, then arr is 3 levels (the max we support)
            // If we got this point, we are the deepest we can be, so there is no point in continuing
            if (keyList(arrItem).some((key) => Array.isArray(arrItem[key]))) {
                return ',,,';
            }
        }
    }
    return delim;
};
// Given a URL param string, return the appropriate top-level delimiter (i.e. empty string or 1, 2, or 3 commas )
export const getDelimForUrlParamString = (str) => {
    let delim = ',,,';
    while (!str.includes(delim)) {
        delim = delim.substr(0, delim.length - 1);
    }
    return delim;
};
// Given an array (potentially containing objects), translate it into a comma-separated string
// so we can put it in the URL: #/?someComplexParam=translatedUrlParamString
export const translateArrayToUrlParamString = (arr) => {
    const itemDelim = getDelimForArray(arr);
    const suffixDelim = arr.length === 1 ? itemDelim : '';
    return (arr
        .map((arrItem) => {
        if (typeof arrItem === 'object') {
            const objPairDelim = itemDelim.substr(0, itemDelim.length - 1);
            const subArrayDelim = objPairDelim.substr(0, objPairDelim.length - 1);
            // Go through each key/value pair in the object and translate them
            return keyList(arrItem)
                .map((key) => {
                let val = arrItem[key];
                if (Array.isArray(val)) {
                    val = val.join(subArrayDelim);
                }
                // note this is differently from `${key}:${val}`, as val of undefined and null
                // will be converted to empty string
                return [key, val].join(':');
            })
                .join(objPairDelim);
        }
        return arrItem;
    })
        .join(itemDelim) + suffixDelim);
};
// Given a comma-separated URL param string, parse it into an array
// This methods expects all items in the array to have the same level of depth
export const parseUrlParamStringToArray = (str) => {
    const itemDelim = getDelimForUrlParamString(str);
    if (str === '')
        return [];
    // If delimiter is '', this means that the string was explicitly deserialized as an array.
    // If there are no delimiters (commas), the string is interpreted as a one element array.
    if (itemDelim === '')
        return [convertStringToProperType(str)];
    const arrItems = str.split(itemDelim);
    // Empty string is filtered out here to ignore leading/trailing delimiters, e.g. `,item1,item2,`
    // This wouldn't help if there is an empty string in the middle, e.g. `item1,,item2,item3,`,
    // in which case, the parser would have chosen the wrong delimiter at the beginning. So it is
    // very import to not have such values when translating
    return arrItems
        .filter((item) => !!item.trim())
        .map((item) => {
        const objPairDelim = itemDelim.substr(0, itemDelim.length - 1);
        const subArrayDelim = objPairDelim.substr(0, objPairDelim.length - 1);
        // If this item has object pairs (i.e. this item is an object), we need to parse them
        if (objPairDelim !== '') {
            const objItem = {};
            const keyValuePairs = item.split(objPairDelim);
            keyValuePairs.forEach((keyValuePair) => {
                const parts = keyValuePair.split(':');
                const key = parts.shift();
                let val = parts.join(':');
                // If this object value is an array, we need to parse it
                if (subArrayDelim !== '') {
                    if (val.includes(subArrayDelim)) {
                        val = val.split(subArrayDelim).map(convertStringToProperType);
                    }
                    else {
                        val = val === '' ? [] : [convertStringToProperType(val)];
                    }
                }
                else {
                    val = convertStringToProperType(val);
                }
                objItem[key] = val;
            });
            return objItem;
        }
        return convertStringToProperType(item);
    });
};
/**
 * Serializes a nested object 3 levels deep with key sorting on each level.
 */
export const serializeObjectSortedKeys = (obj, propertyDelim = ',,,', keyValueDelim = ':::') => Object.keys(obj)
    .sort()
    .map((key) => {
    if (typeof obj[key] === 'object') {
        return `${key}${keyValueDelim}${serializeObjectSortedKeys(obj[key], propertyDelim.slice(0, propertyDelim.length - 1), keyValueDelim.slice(0, keyValueDelim.length - 1))}`;
    }
    return `${key}${keyValueDelim}${obj[key]}`;
})
    .join(propertyDelim);
export const deserializeObject = (objString, propertyDelim = ',,,', keyValueDelim = ':::') => objString.split(propertyDelim).reduce((acc, obj) => {
    const [key, value] = obj.split(keyValueDelim);
    const newPropertyDelim = propertyDelim.slice(0, propertyDelim.length - 1);
    const newKeyValueDelim = keyValueDelim.slice(0, keyValueDelim.length - 1);
    if (newKeyValueDelim !== '' && value.includes(newKeyValueDelim)) {
        acc[key] = deserializeObject(value, newPropertyDelim, newKeyValueDelim);
    }
    else {
        acc[key] = convertStringToProperType(value);
    }
    return acc;
}, {});
export const formFlattenedObject = (object, keys, customSerializer) => keys.reduce((ret, key) => {
    let val = object[key];
    // Works around the behavior of url.search(), where keys with values of
    // undefined and null are eliminated
    if (customSerializer && key in customSerializer) {
        val = customSerializer[key](val);
    }
    else if (val === undefined || val === null) {
        val = '';
    }
    else if (Array.isArray(val)) {
        val = translateArrayToUrlParamString(val);
    }
    else if (typeof val === 'object') {
        val = serializeObjectSortedKeys(val);
    }
    return { ...ret, [key]: val };
}, {});
export const formFlattenedParamString = (object, keys, encode = true) => keys
    .map((key) => {
    let val = object[key];
    if (val === undefined || val === null) {
        val = '';
    }
    else if (Array.isArray(val)) {
        val = translateArrayToUrlParamString(val);
    }
    else if (typeof val === 'object') {
        val = serializeObjectSortedKeys(val);
    }
    if (encode) {
        val = encodeURIComponent(val);
    }
    return `${key}=${val}`;
})
    .join('&');
/**
 * Deserialize URL Params from url.search() to state.
 * A custom deserializer can be used if our generic logic needs to be overriden.
 *
 * It would be nice if this is simply the reverse of formFlattenedObject, but remember that:
 *     1. empty array will be translated to an empty string, and there is no way to tell
 *       when parsing an empty string, whether it was a string or array
 *       NOTE: Then enters the realm of schema validation!
 *     2. top level values should consist of only primitives, arrays or objects.
 *     3. In side the arrays, all children are expected to have same levels of depth
 *     4. lower level values are converted to strings, numbers, objects or arrays when appropriate.
 *
 * @param object {Object} : Object representing the URL parameters object.
 * @param keys {Array} : An Array of keys that we want to desearilize from the `object` param.
 * @param customDeserializer : A custom class that can defined methods that correspond to a key to perform custom deserialization.
 * @return {Object}
 */
export const parseFlattenedObject = (object, keys, customDeserializer) => keys.reduce((obj, key) => {
    // If the object doesn't have the key then don't deserialize it.
    if (key in object) {
        // Unlike the old implementation in app state. Not all values are coerced to String
        // so numbers / boolean can be validated as numbers in schema.
        // NOTE: url.search(), which provides the url params input for this function, returns
        // values with the same types that are set to them.
        // SEE: https://www.bennadel.com/blog/2805-location-search-parameter-data-type-depends-on-source-in-angularjs.htm
        // If we are provided a custom deserializer for this key, use it
        if (key in customDeserializer) {
            const urlValue = object[key];
            const customDeserializedValue = customDeserializer[key](urlValue);
            return { ...obj, [key]: customDeserializedValue };
        }
        let val = convertStringToProperType(object[key]);
        if (typeof val === 'string' && val.includes(':::')) {
            val = deserializeObject(val);
        }
        if (typeof val === 'string' && val.includes(',')) {
            // val can be a number, etc;
            val = parseUrlParamStringToArray(val);
        }
        return { ...obj, [key]: val };
    }
    return { ...obj };
}, {});
/**
 * Deserializes empty URL state to empty arrays if required, otherwise passes to
 * `parseUrlParamStringToArray`; for use in custom `serializers` modules
 *
 * @param urlValue
 * @returns {Array}
 */
export const arrayParamDeserializerHelper = (urlValue) => {
    return urlValue === '' ? [] : parseUrlParamStringToArray(urlValue);
};
/**
 * Deserializes empty URL state to empty arrays if required, otherwise passes to `parseUrlParamStringToArray`
 * Metric filters need to be deserialized as (left: `array`, middle: `str`, right: `array`}
 *
 * @param urlValue
 * @returns {Array}
 */
export const metricFilterDeserializerHelper = (urlValue) => {
    if (urlValue === '')
        return [];
    const metricFilters = parseUrlParamStringToArray(urlValue);
    return metricFilters.map((mf) => ({
        left: mf.left,
        middle: mf.middle[0],
        right: mf.right,
    }));
};
/**
 * Deserializes empty URL state to empty objects if required, otherwise passes to
 * `deserializeObject`; for use in custom `serializers` modules.
 *
 * Note: This method assumes `urlValue` is a string representing an object that is three levels deep -
 *  meaning it contains the ':::' delimiter.
 *
 * @param urlValue
 * @returns {Object}
 */
export const objectParamDeserializerHelper = (urlValue) => {
    return urlValue === '' ? {} : deserializeObject(urlValue);
};
/**
 * Returns a Date object from a string formatted like 'mm/dd/yyyy'
 *
 * @param date
 * @returns {Date}
 */
export const getDateFromString = (date) => {
    if (date === null)
        return null;
    const regex = /[0-9]{2}\/[0-9]{2}\/[0-9]{4}/;
    if (!regex.test(date))
        return null;
    const [month, day, year] = date.split('/');
    return new Date(Number(year), Number(month) - 1, Number(day));
};
/**
 * Compares two arrays like they were sets. The size and elements should be the same. To accomplish that with arrays,
 * it'll first serialize the arrays' items and then sort them before doing a shallowEqual.
 */
export const isSetEqual = (a, b) => {
    if (a == null || b == null || a.length !== b.length)
        return false;
    const sortedA = a.sort();
    const sortedB = b.sort();
    return shallowEqual(sortedA, sortedB);
};
