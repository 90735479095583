// Services
import { AjaxService } from 'NewAngular/services/core/ajax.service';
import { UrlsService } from 'NewAngular/services/core/urls.service';
import * as i0 from "@angular/core";
import * as i1 from "NewAngular/services/core/ajax.service";
import * as i2 from "NewAngular/services/core/urls.service";
/**
 * Service that supports checking available and allowed features.
 */
export class FeatureService {
    constructor(ajaxService, urlsService) {
        this.ajaxService = ajaxService;
        this.urlsService = urlsService;
        this.USER = window.USER;
        this.allFeaturesAvailable = false;
    }
    /**
     * Returns true if user has access to all the input features.
     *
     * @param features features to check the user's access for
     */
    userHasFeatures(features) {
        if (this.allFeaturesAvailable)
            return true;
        return features.every((feature) => {
            return feature === undefined || feature === '' || this.USER.features.includes(feature);
        });
    }
    /**
     * Returns true if user has access to any of the input features.
     *
     * @param features: features to check the user's access for
     */
    userHasSomeFeatures(features) {
        if (this.allFeaturesAvailable)
            return true;
        return features.some((feature) => this.USER.features.includes(feature));
    }
    allowAllFeaturesAccess() {
        // provides access to all features to current user
        // works as a one way switch and remains active for the current application
        this.allFeaturesAvailable = true;
    }
    getFeature(featureKey, nextUrl) {
        const urlParams = { featureKey };
        const postParams = { nextUrl };
        const url = this.urlsService.getUrl(this.urlsService.FEATURE_NEEDED_POPUP.GET_FEATURE, urlParams);
        return this.ajaxService.postFormEncodedDataObserveBody(url, postParams);
    }
}
FeatureService.ɵfac = function FeatureService_Factory(t) { return new (t || FeatureService)(i0.ɵɵinject(i1.AjaxService), i0.ɵɵinject(i2.UrlsService)); };
FeatureService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: FeatureService, factory: FeatureService.ɵfac, providedIn: 'root' });
