// Vendor libraries
import { createReducer, on } from '@ngrx/store';
// Utils / constants
import { CATEGORY_ORDER } from 'SearchApp/ts/constants';
// Action imports
import { addAutocompleterId, blurAutocompleter, decrementSelectedIndex, focusAutocompleter, incrementSelectedIndex, resetAutocompleter, selectResultByIndex, setAutocompleteCategoriesResults, setAutocompleteDefaultLinks, setAutocompleteInputText, setAutocompleteListResults, setAutocompleteSelectedIndex, setAutocompleteShouldBlur, setAutocompleteShowResults, } from './autocompleter.actions';
export const AUTOCOMPLETER_STATE_SLICE_KEY = 'autocompleter';
export const initialAutocompleterState = {
    blurred: true,
    categoriesResults: {},
    inputText: '',
    results: [],
    selectedIndex: -1,
    selectedResult: null,
    shouldBlur: true,
    showResults: false,
    isFetching: false,
    extraLinks: [],
    defaultLinks: [],
};
export const autocompleter = createReducer({}, on(addAutocompleterId, (state, { autocompleterId }) => {
    const next = { ...state };
    next[autocompleterId] = { ...initialAutocompleterState };
    return next;
}), on(setAutocompleteInputText, (state, { autocompleterId, inputText, isFetching }) => {
    const next = { ...state };
    const isEmptyInput = inputText.trim() === '';
    const extraLinks = isEmptyInput ? next[autocompleterId].defaultLinks : next[autocompleterId].extraLinks;
    const results = isEmptyInput ? [] : next[autocompleterId].results;
    const categoriesResults = isEmptyInput ? {} : next[autocompleterId].categoriesResults;
    next[autocompleterId] = {
        ...next[autocompleterId],
        inputText,
        categoriesResults,
        extraLinks,
        results,
        isFetching,
        selectedIndex: results.length > 0 || extraLinks.length > 0 ? 0 : -1,
    };
    return next;
}), on(setAutocompleteListResults, (state, { autocompleterId, results }) => {
    const next = { ...state };
    const hasResults = results.length > 0;
    const { extraLinks } = next[autocompleterId];
    next[autocompleterId] = {
        ...next[autocompleterId],
        results,
        selectedIndex: hasResults || extraLinks.length > 0 ? 0 : -1,
        isFetching: false,
        showResults: hasResults,
    };
    return next;
}), on(setAutocompleteCategoriesResults, (state, { autocompleterId, results, extraLinks }) => {
    const next = { ...state };
    let flatResults = [];
    for (let i = 0; i < CATEGORY_ORDER.length; i += 1) {
        const typeResults = results[CATEGORY_ORDER[i]];
        if (typeResults !== undefined) {
            flatResults = flatResults.concat(typeResults);
        }
    }
    const hasResults = flatResults.length > 0;
    // when no results just show AI link
    let newExtraLinks = [...extraLinks];
    if (!hasResults) {
        newExtraLinks = newExtraLinks.filter((item) => item.type !== 'searchall');
    }
    newExtraLinks =
        next[autocompleterId].inputText.trim() === '' ? [...next[autocompleterId].defaultLinks] : newExtraLinks;
    next[autocompleterId] = {
        ...next[autocompleterId],
        results: flatResults,
        categoriesResults: results,
        selectedIndex: hasResults || newExtraLinks.length > 0 ? 0 : -1,
        showResults: hasResults,
        isFetching: false,
        extraLinks: newExtraLinks,
    };
    return next;
}), on(setAutocompleteDefaultLinks, (state, { autocompleterId, defaultLinks }) => {
    const next = { ...state };
    next[autocompleterId] = {
        ...next[autocompleterId],
        defaultLinks,
    };
    return next;
}), on(setAutocompleteSelectedIndex, (state, { autocompleterId, selectedIndex }) => {
    const next = { ...state };
    next[autocompleterId] = {
        ...next[autocompleterId],
        selectedIndex,
    };
    return next;
}), on(setAutocompleteShowResults, (state, { autocompleterId, showResults }) => {
    const next = { ...state };
    next[autocompleterId] = {
        ...next[autocompleterId],
        showResults,
    };
    return next;
}), on(resetAutocompleter, (state, { autocompleterId }) => {
    const next = { ...state };
    const oldDefaultLinks = next[autocompleterId].defaultLinks;
    next[autocompleterId] = { ...initialAutocompleterState, defaultLinks: oldDefaultLinks };
    return next;
}), on(focusAutocompleter, (state, { autocompleterId }) => {
    const next = { ...state };
    const hasResults = next[autocompleterId].results.length > 0;
    const extraLinks = next[autocompleterId].inputText.trim() === ''
        ? [...next[autocompleterId].defaultLinks]
        : [...next[autocompleterId].extraLinks];
    next[autocompleterId] = {
        ...next[autocompleterId],
        blurred: false,
        selectedIndex: hasResults || extraLinks.length > 0 ? 0 : -1,
        showResults: hasResults,
        extraLinks,
    };
    return next;
}), on(blurAutocompleter, (state, { autocompleterId }) => {
    const next = { ...state };
    next[autocompleterId] = {
        ...next[autocompleterId],
        blurred: true,
        selectedIndex: -1,
        showResults: false,
    };
    return next;
}), on(setAutocompleteShouldBlur, (state, { autocompleterId, shouldBlur }) => {
    const next = { ...state };
    next[autocompleterId] = {
        ...next[autocompleterId],
        shouldBlur,
    };
    return next;
}), on(selectResultByIndex, (state, { autocompleterId }) => {
    const next = { ...state };
    const { selectedIndex, results, extraLinks } = next[autocompleterId];
    if (selectedIndex === -1) {
        return next;
    }
    // All we need to do here is set `selectedResult`. When the BaseAutocompleteComponent detects this change,
    // it will output the result and then dispatch `resetAutocompleter`
    // The selectedResult could be in the result list or in the extralinks result
    const selectedResult = selectedIndex < results.length
        ? results[selectedIndex]
        : extraLinks[selectedIndex - results.length];
    next[autocompleterId] = {
        ...next[autocompleterId],
        selectedResult,
    };
    return next;
}), on(decrementSelectedIndex, (state, { autocompleterId }) => {
    const next = { ...state };
    const { selectedIndex, results, extraLinks } = next[autocompleterId];
    let nextIndex = selectedIndex - 1;
    const allItemsCount = results.length + extraLinks.length;
    if (nextIndex < 0) {
        nextIndex = allItemsCount - 1;
    }
    next[autocompleterId] = {
        ...next[autocompleterId],
        selectedIndex: nextIndex,
    };
    return next;
}), on(incrementSelectedIndex, (state, { autocompleterId }) => {
    const next = { ...state };
    const { selectedIndex, results, extraLinks } = next[autocompleterId];
    let nextIndex = selectedIndex + 1;
    const allItemsCount = results.length + extraLinks.length;
    if (nextIndex >= allItemsCount) {
        nextIndex = 0;
    }
    next[autocompleterId] = {
        ...next[autocompleterId],
        selectedIndex: nextIndex,
    };
    return next;
}));
