// Vendor
import { combineReducers } from '@ngrx/store';
// Reducers
import { notifications } from './notifications-modal/notifications-modal.reducer';
import { unreadNotificationsCount } from './unread-notifications-count/unread-notifications-count.reducer';
export const NOTIFICATIONS_MODAL_STATE_SLICE_KEY = 'notificationsModal';
export const notificationsModal = combineReducers({
    notifications,
    unreadNotificationsCount,
});
