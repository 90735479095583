import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
// Modules
import { AppendToBodyModule } from 'NewAngular/features/directives/append-to-body/append-to-body.module';
import { DisabledModule } from 'NewAngular/features/directives/disabled/disabled.module';
import { FeatureNeededModule } from 'EntitlementsApp/ts/features/feature-needed/feature-needed.module';
import { IconModule } from 'NewAngular/features/directives/icon/icon.module';
import { TooltipModule } from 'NewAngular/features/directives/popovers/tooltip/tooltip.module';
// NgRx reducers
import { autocompleter, AUTOCOMPLETER_STATE_SLICE_KEY, } from 'SearchApp/ts/features/base-autocomplete/state/autocompleter.reducer';
// Pipes
import { IndexOfPipeModule } from 'NewAngular/features/pipes/index-of/index-of-pipe.module';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class BaseAutocompleteModule {
}
BaseAutocompleteModule.ɵfac = function BaseAutocompleteModule_Factory(t) { return new (t || BaseAutocompleteModule)(); };
BaseAutocompleteModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: BaseAutocompleteModule });
BaseAutocompleteModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [AppendToBodyModule,
        CommonModule,
        DisabledModule,
        TooltipModule,
        FeatureNeededModule,
        FormsModule,
        IconModule,
        IndexOfPipeModule,
        StoreModule.forFeature(AUTOCOMPLETER_STATE_SLICE_KEY, autocompleter)] });
