// Make sure if any of the below category or search lists are updated,
// we also update apps/search/static/search/js/redesign/autocomplete/autocomplete.component.js
export const CATEGORY_ORDER = [
    'co',
    'act_co',
    'mf',
    'act_mf',
    'sa',
    'cf',
    'mp',
    'pmp',
    'fmp',
    'cmp',
    'fpmp',
    'idx',
    'cs',
    'ind',
    'mwl',
    'iwl',
];
export const CATEGORY_LABELS = {
    co: 'Equities',
    act_co: 'Equities',
    mf: 'Mutual Funds',
    act_mf: 'Mutual Funds',
    sa: 'Separate Account',
    cf: 'ETFs',
    mp: 'Portfolios',
    pmp: 'Public Portfolios',
    fmp: 'Portfolios',
    cmp: 'Portfolios',
    fpmp: 'Public Portfolios',
    idx: 'Indices',
    cs: 'Custom Securities',
    ind: 'Economic Data',
    mwl: 'Security Watchlists',
    iwl: 'Indicator Watchlists',
};
export const SEARCH_TYPES = {
    co: 'search_companies',
    mf: 'search_mutual_funds',
    idx: 'search_indices',
    ind: 'search_indicators',
    mp: 'search_model_portfolios',
    cmp: 'search_model_portfolios',
    pmp: 'search_model_portfolios',
    fmp: 'search_model_portfolios',
    fpmp: 'search_model_portfolios',
    sa: 'search_separate_accounts',
    cs: 'search_custom_securities',
    mwl: 'multi_asset_watchlists',
    iwl: 'indicator_watchlists',
};
export const SEARCH_TYPES_TO_CODES = {
    mutual_fund: 'mf',
    index: 'idx',
    indicator: 'ind',
    model_portfolio: 'mp',
    separate_account: 'sa',
};
export const SEARCH_ALL_METHODS = [
    {
        searchMethod: 'search_companies',
        autocompleteCategory: 'co',
        label: 'Equities',
    },
    {
        searchMethod: 'search_mutual_funds',
        autocompleteCategory: 'mf',
        label: 'Mutual Funds',
    },
    {
        searchMethod: 'search_separate_accounts',
        autocompleteCategory: 'sa',
        label: 'Separately Managed Accounts',
    },
    {
        searchMethod: 'search_model_portfolios',
        autocompleteCategory: 'mp',
        label: 'Model Portfolios',
    },
    {
        searchMethod: 'search_indices',
        autocompleteCategory: 'idx',
        label: 'Indices',
    },
    {
        searchMethod: 'search_custom_securities',
        autocompleteCategory: 'cs',
        label: 'Custom Securities',
    },
    {
        searchMethod: 'search_indicators',
        autocompleteCategory: 'ind',
        label: 'Indicators',
    },
];
export const SAVED_ITEMS_ICONS_SUFFIX = {
    CustomEmailReport: 'er',
    CustomSecurity: 'cs',
    SavedFundamentalChart: 'fc',
    ModelPortfolio: 'p',
    FundSavedScreen: 'fs',
    CompanySavedScreen: 'ss',
    SavedTechnicalChartRedesign: 'tc',
    SavedTimeseriesTable: 'ts',
    SavedScatterPlot: 'sp',
    CustomPDFReport: 'pdf',
    Watchlist: 'wl',
};
export const EXTRA_CATEGORY_ORDER = ['security', 'asset', 'searchall', 'ai'];
