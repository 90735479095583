// Vendor
import { createReducer, on } from '@ngrx/store';
// Actions
import { deleteAllNotifications, setFetchNotificationsSuccess, } from 'NotificationsApp/ts/features/notifications-modal/state/notifications-modal/notifications-modal.actions';
import { deleteNotification, markNotificationRead, } from 'NotificationsApp/ts/features/notifications-modal/state/notifications-list-item/notification-list-item.action';
export const unreadNotificationsCount = createReducer(0, on(setFetchNotificationsSuccess, (state, action) => {
    const unreadNotifications = action.notifications.filter((notification) => {
        return !notification.read;
    });
    return unreadNotifications.length;
}), on(deleteAllNotifications, () => {
    return 0;
}), on(deleteNotification, markNotificationRead, (state, action) => {
    return action.notification.read ? state : state - 1;
}));
