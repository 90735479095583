export const unsavedChangesDialogText = 'There are unsaved changes, are you sure you want to leave?';
/*
 *
 * A place for generic / widely used constants
 *
 */
export const COMPANY_SALES_HOURS = '9am-6pm ET';
export const DELETE = 'delete';
export const DOWNLOAD = 'download';
export const DUPLICATE = 'duplicate';
export const GENERATE_REPORT = 'generate_report';
export const EDIT = 'edit';
export const EMBED = 'embed';
export const EMBED_TYPE_IMAGE_CODE = 'image_code';
export const EMBED_TYPE_IMAGE_CONTENTS = 'image_contents';
export const EXPORT = 'export';
export const COPY_URL_LINK = 'link';
export const PRINT = 'print';
export const SHARE = 'share';
export const TWEET = 'tweet';
// Security Types
export const BENCHMARK_PORTFOLIO = 'benchmark_portfolio';
export const CEF = 'cef';
export const CLIENT_PORTFOLIO = 'client_portfolio';
export const COMPANY = 'company';
export const COMPANY_FUND = 'company_fund';
export const CUSTOM_SECURITY = 'custom_security';
export const ETF = 'etf';
export const HOUSEHOLD_PORTFOLIO = 'household_portfolio';
export const INDEX = 'index';
export const INDICATOR = 'indicator';
export const MODEL_PORTFOLIO = 'model_portfolio';
export const MONEY_MARKET_FUND = 'money_market_fund';
export const MUTUAL_FUND = 'mutual_fund';
export const SEPARATE_ACCOUNT = 'separate_account';
export const STOCK = 'stock';
export const UIT = 'uit';
export const UNLISTED_CEF = 'unlisted_cef';
// Security Type Prefixes
export const CUSTOM_SECURITY_ID_PREFIX = 'Y:';
export const INDEX_ID_PREFIX = '^';
export const INDICATOR_ID_PREFIX = 'I:';
export const MODEL_PORTFOLIO_ID_PREFIX = 'P:';
export const MUTUAL_FUND_ID_PREFIX = 'M:';
export const SEPARATE_ACCOUNT_ID_PREFIX = 'S:';
// Security Type URL Prefixes
export const COMPANY_URL_PREFIX = '/companies/';
export const CUSTOM_SECURITY_URL_PREFIX = '/custom_securities/';
export const INDEX_URL_PREFIX = '/indices/';
export const INDICATOR_URL_PREFIX = '/indicators/';
export const MODEL_PORTFOLIO_URL_PREFIX = '/model_portfolios/';
export const MUTUAL_FUND_URL_PREFIX = '/mutual_funds/';
export const SEPARATE_ACCOUNT_URL_PREFIX = '/separate_accounts/';
// Security Lists
export const FUND = 'fund';
export const MODEL_PORTFOLIO_PREFIX = 'model-portfolio-';
export const SECURITYLIST = 'securitylist';
export const SECURITYLIST_GROUP_MULTI = 'multi';
export const WATCHLIST_PREFIX = 'watchlist-';
export const FUND_SAVED_SCREEN_PREFIX = 'fund-screen-';
export const STOCK_SAVED_SCREEN_PREFIX = 'equity-screen-';
export const SAVED_TIMESERIES_TABLE_PREFIX = 'timeseries-table-';
export const SAVED_COMP_TABLE_PREFIX = 'comp-table-';
// Charts
export const SCATTER = 'scatter';
export const TECH_CHART = 'tech';
// Column types use across apps
export const COLUMN_CUSTOM_PERIOD = 'customPeriod';
export const COLUMN_INFO_FIELD = 'infoField';
export const COLUMN_METRIC = 'metric';
export const COLUMN_SCORE = 'score';
// Colors we use all over the site
export const CORE_COLOR_MAP = {
    1: {
        code: '#6833c9',
    },
    2: {
        code: '#ffa86f',
    },
    3: {
        code: '#568cd8',
    },
    4: {
        code: '#9bcc4f',
    },
    5: {
        code: '#dd1baa',
    },
    6: {
        code: '#bf9375',
    },
    7: {
        code: '#28753a',
    },
    8: {
        code: '#e85b35',
    },
    9: {
        code: '#b59ae4',
    },
    10: {
        code: '#a9c4eb',
    },
    11: {
        code: '#56284f',
    },
    12: {
        code: '#ffb8ee',
    },
};
// Sort directions
export const ASC = 'asc';
export const DESC = 'desc';
// Data formats
export const JSON_FORMAT = 'json';
export const CSV_FORMAT = 'csv';
export const PDF_FORMAT = 'pdf';
// marketing site url
export const MARKETING_SITE_DOMAIN = 'https://get.ycharts.com';
// Watchlists
export const MULTI_WATCHLIST = 'multi_watchlist';
export const INDICATOR_WATCHLIST = 'indicator_watchlist';
// Saved Screens
export const STOCK_SAVED_SCREEN = 'company_saved_screen';
export const FUND_SAVED_SCREEN = 'fund_saved_screen';
// Strategy
export const STRATEGY = 'strategy';
// Recessions
/*
 * An array dump of recession cycles from - http://www.nber.org/cycles/cyclesmain.html
 * A script that takes the list of dates converted into a python structure and then dump out as json
 * can be found in the apps/charts/docs directory (or at https://gist.github.com/d8ac996a332bf6332015)
 */
export const RECESSION_CYCLES = [
    [-3552854400000, -3505507200000],
    [-3447619200000, -3426624000000],
    [-3305664000000, -3221510400000],
    [-3174163200000, -3126816000000],
    [-3037392000000, -2866579200000],
    [-2771884800000, -2671920000000],
    [-2614118400000, -2579817600000],
    [-2508883200000, -2482617600000],
    [-2429827200000, -2385244800000],
    [-2337897600000, -2290550400000],
    [-2227478400000, -2180131200000],
    [-2124921600000, -2064441600000],
    [-1977782400000, -1943481600000],
    [-1893456000000, -1830384000000],
    [-1798761600000, -1738368000000],
    [-1622678400000, -1604361600000],
    [-1577923200000, -1530662400000],
    [-1472860800000, -1435968000000],
    [-1364947200000, -1330732800000],
    [-1275523200000, -1162512000000],
    [-1031011200000, -996796800000],
    [-786240000000, -765331200000],
    [-667958400000, -639100800000],
    [-520819200000, -494553600000],
    [-391910400000, -370915200000],
    [-307756800000, -281318400000],
    [-2678400000, 26265600000],
    [120960000000, 162864000000],
    [315532800000, 331257600000],
    [362793600000, 404956800000],
    [646790400000, 667785600000],
    [983404800000, 1004572800000],
    [1196467200000, 1243814400000],
    [1580515200000, 1585699200000],
];
