import { HttpClient } from '@angular/common/http';
import { catchError, map, of } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
// Services
import { AjaxService } from 'NewAngular/services/core/ajax.service';
import { UrlsService } from 'NewAngular/services/core/urls.service';
// Constants
import { LEGACY_DATA_FEATURE } from 'EntitlementsApp/ts/features/feature-needed/feature-needed.constants';
import * as i0 from "@angular/core";
import * as i1 from "NewAngular/services/core/ajax.service";
import * as i2 from "@angular/common/http";
import * as i3 from "NewAngular/services/core/urls.service";
export class AccountsService {
    constructor(ajaxService, httpClient, urlsService) {
        this.ajaxService = ajaxService;
        this.httpClient = httpClient;
        this.urlsService = urlsService;
        this.cachedUserPreferences = this.fetchUserPreferences();
        this.cachedClientGroupPreferences = this.fetchClientGroupPreferences();
    }
    fetchUserPreferences() {
        const userPreferencesUrl = this.urlsService.ACCOUNTS.USER_PREFERENCES;
        return this.ajaxService.getDataObserveBody(userPreferencesUrl).pipe(
        // shareReplay allows multiple subscribers to subscribe to this observable
        // without triggering additional HTTP requests.
        shareReplay(1));
    }
    fetchClientGroupPreferences() {
        const clientGroupPreferencesUrl = this.urlsService.ACCOUNTS.CLIENT_GROUP_PREFERENCES;
        return this.ajaxService.getDataObserveBody(clientGroupPreferencesUrl).pipe(
        // shareReplay allows multiple subscribers to subscribe to this observable
        // without triggering additional HTTP requests.
        shareReplay(1));
    }
    getClientGroupPreferences() {
        return this.cachedClientGroupPreferences;
    }
    getUserPreferences() {
        return this.cachedUserPreferences;
    }
    postReferFriends(emails) {
        const url = this.urlsService.ACCOUNTS.REFER_FRIEND;
        const postParams = { emails };
        return this.ajaxService.postFormEncodedDataObserveResponse(url, postParams);
    }
    putUserPreferences(putParams) {
        const url = this.urlsService.ACCOUNTS.USER_PREFERENCES;
        return this.ajaxService.putDataObserveBody(url, putParams);
    }
    putUserPreferencesAndRefreshCache(putParams, errorCallback) {
        this.putUserPreferences(putParams).subscribe({
            next: () => {
                this.cachedUserPreferences = this.fetchUserPreferences();
            },
            error: errorCallback,
        });
    }
    updateProfile(profileData) {
        const url = this.urlsService.ACCOUNTS.ACCOUNT_BASICS;
        return this.ajaxService.postFormEncodedDataObserveBody(url, profileData);
    }
    updatePassword(passwordData) {
        const url = this.urlsService.ACCOUNTS.CHANGE_PASSWORD;
        return this.ajaxService.postFormEncodedDataObserveBody(url, passwordData);
    }
    createChangeEmailRequest(changeEmailData) {
        const url = this.urlsService.ACCOUNTS.CHANGE_EMAIL;
        return this.ajaxService.postFormEncodedDataObserveBody(url, changeEmailData);
    }
    getMFADevices() {
        const url = this.urlsService.ACCOUNTS.MFA_DEVICE_COLLECTION;
        return this.ajaxService.getDataObserveBody(url);
    }
    manageMFADevices(postData) {
        const url = this.urlsService.ACCOUNTS.MFA_DEVICE_MANAGEMENT;
        return this.ajaxService.postFormEncodedDataObserveBody(url, postData);
    }
    useGenericData(overrides) {
        const requiredFeatures = [...(overrides ?? []), LEGACY_DATA_FEATURE];
        if (window.USER.features.find((feature) => requiredFeatures.includes(feature)) == null) {
            return of(true);
        }
        return this.getUserPreferences().pipe(map((preferences) => preferences.use_generic_data_display), catchError(() => of(true)));
    }
}
AccountsService.ɵfac = function AccountsService_Factory(t) { return new (t || AccountsService)(i0.ɵɵinject(i1.AjaxService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.UrlsService)); };
AccountsService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: AccountsService, factory: AccountsService.ɵfac, providedIn: 'root' });
