import { finalize } from 'rxjs/operators';
// Services
import { SalesSupportService } from '../../services/sales-support.service';
import { COMPANY_SALES_HOURS } from 'NewAngular/constants/core.constants';
import * as i0 from "@angular/core";
import * as i1 from "../../services/sales-support.service";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../../ycharts/static/ts/features/components/loaders/skeleton-loaders-list/skeleton-loaders-list.component";
function SalesRepComponent_div_2_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵtext(1, " An error occurred, please try and reload the page. ");
    i0.ɵɵelementEnd();
} }
function SalesRepComponent_div_2_div_2_a_13_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 10);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵpropertyInterpolate("href", ctx_r0.salesRep.meeting_url, i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1("Book a Meeting with ", ctx_r0.salesRep.first_name, "");
} }
function SalesRepComponent_div_2_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7)(1, "strong");
    i0.ɵɵtext(2, "Contact your Dedicated Support Representative:");
    i0.ɵɵelementEnd();
    i0.ɵɵelement(3, "br");
    i0.ɵɵtext(4);
    i0.ɵɵelement(5, "br");
    i0.ɵɵelementStart(6, "a", 8);
    i0.ɵɵtext(7);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(8, "br");
    i0.ɵɵelementStart(9, "a", 8);
    i0.ɵɵtext(10);
    i0.ɵɵelementEnd();
    i0.ɵɵtext(11);
    i0.ɵɵelement(12, "br");
    i0.ɵɵtemplate(13, SalesRepComponent_div_2_div_2_a_13_Template, 2, 2, "a", 9);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate2(" ", ctx_r0.salesRep.first_name, " ", ctx_r0.salesRep.last_name, " ");
    i0.ɵɵadvance(2);
    i0.ɵɵpropertyInterpolate1("href", "mailto:", ctx_r0.salesRep.email, "", i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.salesRep.email);
    i0.ɵɵadvance(2);
    i0.ɵɵpropertyInterpolate1("href", "tel:", ctx_r0.salesRep.phone_number, "", i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.salesRep.phone_number);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.salesRep.companyHours);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.salesRep.meeting_url);
} }
function SalesRepComponent_div_2_div_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 11);
    i0.ɵɵelement(1, "img", 12);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵpropertyInterpolate("src", ctx_r0.salesRep == null ? null : ctx_r0.salesRep.image_url, i0.ɵɵsanitizeUrl);
} }
function SalesRepComponent_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtemplate(1, SalesRepComponent_div_2_div_1_Template, 2, 0, "div", 3)(2, SalesRepComponent_div_2_div_2_Template, 14, 10, "div", 4)(3, SalesRepComponent_div_2_div_3_Template, 2, 1, "div", 5);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.error);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r0.error && ctx_r0.salesRep !== null);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r0.error && (ctx_r0.salesRep == null ? null : ctx_r0.salesRep.image_url));
} }
export class SalesRepComponent {
    constructor(salesSupportService) {
        this.salesSupportService = salesSupportService;
        this.salesRep = null;
        this.loading = true;
        this.error = false;
    }
    ngOnInit() {
        this.salesSupportService
            .getSalesRep()
            .pipe(finalize(() => {
            this.loading = false;
        }))
            .subscribe({
            next: (salesRep) => {
                this.salesRep = salesRep;
                this.salesRep.companyHours = ` (${COMPANY_SALES_HOURS})`;
            },
            error: () => {
                this.error = true;
            },
        });
    }
}
SalesRepComponent.ɵfac = function SalesRepComponent_Factory(t) { return new (t || SalesRepComponent)(i0.ɵɵdirectiveInject(i1.SalesSupportService)); };
SalesRepComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: SalesRepComponent, selectors: [["ycn-sales-rep"]], decls: 3, vars: 3, consts: [[3, "loading", "numOfSkeletonLoaders"], ["class", "row", 4, "ngIf"], [1, "row"], ["class", "col-12", 4, "ngIf"], ["class", "col-8 col-md-9", 4, "ngIf"], ["class", "col-4 col-md-3", 4, "ngIf"], [1, "col-12"], [1, "col-8", "col-md-9"], [3, "href"], ["class", "text-blue", 3, "href", 4, "ngIf"], [1, "text-blue", 3, "href"], [1, "col-4", "col-md-3"], [1, "img-fluid", 3, "src"]], template: function SalesRepComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div");
        i0.ɵɵelement(1, "ycn-skeleton-loaders-list", 0);
        i0.ɵɵtemplate(2, SalesRepComponent_div_2_Template, 4, 3, "div", 1);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance();
        i0.ɵɵproperty("loading", ctx.loading)("numOfSkeletonLoaders", 3);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", !ctx.loading);
    } }, dependencies: [i2.NgIf, i3.SkeletonLoadersListComponent], encapsulation: 2 });
