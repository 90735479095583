import { MARKETING_SITE_DOMAIN } from 'NewAngular/constants/core.constants';
import { AI_CHAT_FEATURE, AGG_CALCS_FEATURE, ALERTS_FEATURE, CEFS_PAGE_FEATURE, CHARTS_FEATURE, CUSTOM_EMAIL_REPORTS_FEATURE, CUSTOM_SECURITIES_FEATURE, DASHBOARD_FEATURE, DATA_TABLES_FEATURE, ECONOMIC_CALENDAR_PAGE_FEATURE, ECONOMIC_INDICATORS_PAGE_FEATURE, EDIT_SCATTER_PLOT_FEATURE, ETFS_PAGE_FEATURE, EVENTS_CALENDAR_PAGE_FEATURE, EXCEL_FEATURE, INDICES_PAGE_FEATURES, INTEGRATIONS_FEATURE, INTERACTIVE_ANALYSIS_FEATURE, MODEL_PORTFOLIOS_FEATURE, MUTUAL_FUND_DATA_CAN_FEATURE, MUTUAL_FUND_DATA_USA_FEATURE, NEWS_FEATURE, PDF_REPORT_BUILDER_FEATURE, PORTFOLIO_OPTIMIZER_FEATURE, RISK_PROFILES_FEATURE, SCENARIOS_FEATURE, SCREENER_FEATURE, STOCKS_PAGE_FEATURE, STRATEGY_FEATURE, TALKING_POINTS_FEATURE, WATCHLIST_FEATURE, } from 'EntitlementsApp/ts/features/feature-needed/feature-needed.constants';
export const API_TOOLS_OPTIONS = [
    {
        type: 'divider',
        label: null,
        href: null,
    },
    {
        type: 'option',
        label: 'API',
        href: '/api',
    },
];
export const INTERACTIVE_ANALYSIS_TOOL_OPTION = {
    type: 'option',
    label: 'Interactive Analysis',
    href: '/analysis_plan/',
    features: [INTERACTIVE_ANALYSIS_FEATURE],
};
export const RISK_PROFILES_OPTION = {
    type: 'option',
    label: 'Risk Profiles',
    href: '/risk_profiles/list/',
    features: [RISK_PROFILES_FEATURE],
};
export const FREE_TRIAL_ITEM = {
    itemClass: 'sidemenu-item btn-wrap',
    linkClass: 'btn btn-30 btn-success btn-success-outline',
    href: '/start_trial',
    text: 'Free Trial',
};
export const HEADER_MAIN_MENU_OPTIONS = {
    DATA: {
        title: 'Data',
        columns: [
            {
                title: 'Markets',
                class: 'order-md-1',
                list: [
                    {
                        type: 'option',
                        label: 'Stocks',
                        href: '/stocks',
                        features: [STOCKS_PAGE_FEATURE],
                    },
                    {
                        type: 'option',
                        label: 'Indices',
                        href: '/indices',
                        features: [INDICES_PAGE_FEATURES],
                    },
                    {
                        type: 'option',
                        label: 'Events Calendar',
                        href: '/events/calendar/',
                        features: [EVENTS_CALENDAR_PAGE_FEATURE],
                    },
                ],
            },
            {
                title: 'Economy',
                class: 'order-md-3',
                list: [
                    {
                        type: 'option',
                        label: 'Economic Indicators',
                        href: '/indicators',
                        features: [ECONOMIC_INDICATORS_PAGE_FEATURE],
                    },
                    {
                        type: 'option',
                        label: 'Economic Calendar',
                        href: '/indicators/economic_calendar',
                        features: [ECONOMIC_CALENDAR_PAGE_FEATURE],
                    },
                ],
            },
            {
                title: 'Funds',
                class: 'order-md-2',
                list: [
                    {
                        type: 'option',
                        label: 'Mutual Funds',
                        href: '/mutual_funds',
                        features: [MUTUAL_FUND_DATA_USA_FEATURE, MUTUAL_FUND_DATA_CAN_FEATURE],
                        someFeatures: true,
                    },
                    {
                        type: 'option',
                        label: 'ETFs',
                        href: '/etfs',
                        features: [ETFS_PAGE_FEATURE],
                    },
                    {
                        type: 'option',
                        label: 'CEFs',
                        href: '/cefs',
                        features: [CEFS_PAGE_FEATURE],
                    },
                ],
            },
        ],
    },
    TOOLS: {
        title: 'Tools',
    },
    SUPPORT: {
        title: 'Support',
    },
    PLANS: {
        title: 'Plans',
        href: `${MARKETING_SITE_DOMAIN}/plans/`,
    },
    USE_CASES: {
        title: 'Use Cases',
        href: `${MARKETING_SITE_DOMAIN}/solutions/success-stories/`,
    },
    ABOUT: {
        title: 'About',
        href: `${MARKETING_SITE_DOMAIN}/about/`,
    },
    TRIAL_WALL: {
        title: 'Plans',
        href: '/entitlements/unpaid_trial_wall',
    },
    FREE_TRIAL: {
        title: 'Free Trial',
        href: '/start_trial',
    },
    SCHEDULE_DEMO: {
        title: 'Schedule Demo',
        href: 'https://meetings.hubspot.com/kovi-katz/ycharts-discussion',
    },
};
export const SIGN_IN_ITEM = {
    itemClass: 'sidemenu-item',
    linkClass: 'sidemenu-item-link',
    text: 'Log In',
};
export const SUPPORT_INDICATOR_DATA_MANAGERS_OPTIONS = [
    {
        type: 'divider',
        label: null,
        href: null,
    },
    {
        type: 'option',
        label: 'Indicator Review',
        href: '/indicators/review',
    },
];
export const SUPPORT_LIST_OPTIONS = [
    {
        title: 'Resources',
        class: 'order-md-1',
        list: [
            {
                type: 'option',
                label: 'Tutorials',
                href: '/tutorials',
            },
            {
                type: 'option',
                label: 'FAQs',
                href: '/support',
            },
            {
                type: 'option',
                label: 'Integrations',
                href: '/accounts/account_settings/integrations/',
                features: [INTEGRATIONS_FEATURE],
            },
            {
                type: 'option',
                label: 'Financial Glossary',
                href: '/glossary',
            },
        ],
    },
    {
        title: 'Info',
        class: 'order-md-3',
        list: [
            {
                type: 'option',
                label: 'About Us',
                href: `${MARKETING_SITE_DOMAIN}/about/`,
                newTab: true,
            },
            {
                type: 'option',
                label: 'Contact Us',
                href: '/contact_us',
            },
            {
                type: 'option',
                label: 'Product Release Notes',
                href: '/product-release-notes',
            },
        ],
    },
    {
        title: 'Content',
        class: 'order-md-2',
        list: [
            {
                type: 'option',
                label: 'Webinars',
                href: '/webinars',
            },
            {
                type: 'option',
                label: 'Case Studies',
                href: `${MARKETING_SITE_DOMAIN}/solutions/success-stories/`,
                newTab: true,
            },
            {
                type: 'option',
                label: 'Insights & Visuals',
                href: '/insights-and-visuals',
            },
            {
                type: 'option',
                label: 'Blog',
                href: `${MARKETING_SITE_DOMAIN}/resources/blog/`,
                newTab: true,
            },
        ],
    },
];
export const SUPPORT_SYSTEMS_OPTIONS = [
    {
        type: 'divider',
        label: null,
        href: null,
    },
    {
        type: 'option',
        label: 'Systems Review',
        href: '/systems/review/',
    },
    {
        type: 'option',
        label: 'Airflow Web UI',
        href: '/systems/airflow/',
    },
];
export const TOOLS_OPTIONS = [
    {
        title: 'Monitor',
        class: 'order-md-1',
        list: [
            {
                type: 'option',
                label: 'Dashboard',
                href: '/dashboard/',
                features: [DASHBOARD_FEATURE],
            },
            {
                type: 'option',
                label: 'Watchlists',
                href: '/watchlists/list/',
                features: [WATCHLIST_FEATURE],
            },
            {
                type: 'option',
                label: 'Alerts',
                href: '/alerts/list/',
                features: [ALERTS_FEATURE],
            },
            {
                type: 'option',
                label: 'News',
                href: '/news/',
                features: [NEWS_FEATURE],
            },
        ],
    },
    {
        title: 'Visualize',
        class: 'order-md-3',
        list: [
            {
                type: 'option',
                label: 'Fundamental Chart',
                href: '/charts/fundamental_chart/',
                features: [CHARTS_FEATURE],
            },
            {
                type: 'option',
                label: 'Scatter Plot',
                href: '/charts/scatter_plot/',
                features: [EDIT_SCATTER_PLOT_FEATURE],
            },
            {
                type: 'option',
                label: 'Technical Chart',
                href: '/charts/technical_chart/',
                features: [CHARTS_FEATURE],
            },
        ],
    },
    {
        title: 'Report',
        class: 'order-md-5',
        list: [
            {
                type: 'option',
                label: 'Report Builder',
                href: '/custom_pdf_reports/list/',
                features: [PDF_REPORT_BUILDER_FEATURE],
            },
            {
                type: 'option',
                label: 'Talking Points',
                href: '/talking_points/list/',
                features: [TALKING_POINTS_FEATURE],
            },
            {
                type: 'option',
                label: 'Email Reports',
                href: '/email_reports/list/',
                features: [CUSTOM_EMAIL_REPORTS_FEATURE],
            },
        ],
    },
    {
        title: 'Research',
        class: 'order-md-2',
        list: [
            {
                type: 'option',
                label: 'Stock Screener',
                href: '/screener/stock/',
                features: [SCREENER_FEATURE],
            },
            {
                type: 'option',
                label: 'Fund Screener',
                href: '/screener/mutual_fund_and_etf/',
                features: [SCREENER_FEATURE],
            },
            {
                type: 'option',
                label: 'Comp Tables',
                href: '/tables/comparison/',
                features: [DATA_TABLES_FEATURE],
            },
            {
                type: 'option',
                label: 'Timeseries Analysis',
                href: '/tables/timeseries/',
                features: [DATA_TABLES_FEATURE],
            },
            {
                type: 'option',
                label: 'Excel',
                href: '/excel',
                features: [EXCEL_FEATURE],
            },
            {
                type: 'option',
                label: 'Sectors',
                href: '/classifications/sectors',
                features: [AGG_CALCS_FEATURE],
            },
            {
                type: 'option',
                label: 'Investment Strategies',
                href: '/strategies',
                features: [STRATEGY_FEATURE],
            },
            {
                type: 'option',
                label: 'AI Chat',
                href: '/ai_chat/',
                features: [AI_CHAT_FEATURE],
                overrideMissingFeaturePreference: 'hide',
                iconName: 'icon-sparkle',
            },
        ],
    },
    {
        title: 'Manage',
        class: 'order-md-4',
        list: [
            {
                type: 'option',
                label: 'Portfolios',
                href: '/model_portfolios/list/',
                features: [MODEL_PORTFOLIOS_FEATURE],
            },
            {
                type: 'option',
                label: 'Custom Securities',
                href: '/custom_securities/list/',
                features: [CUSTOM_SECURITIES_FEATURE],
            },
            {
                type: 'option',
                label: 'Scenarios',
                href: '/scenarios/',
                features: [SCENARIOS_FEATURE],
            },
            {
                type: 'option',
                label: 'Portfolio Optimizer',
                href: '/portfolio_optimizer/',
                features: [PORTFOLIO_OPTIMIZER_FEATURE],
            },
        ],
    },
];
