// Vendor libraries
import { ElementRef } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "NewAngular/apps/error-app/components/404-page/404-page.component";
import * as i3 from "NewAngular/apps/error-app/components/500-page/500-page.component";
function ErrorAppComponent_ng_container_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "ycn-500-page", 2);
    i0.ɵɵelementContainerEnd();
} }
function ErrorAppComponent_ng_template_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "ycn-404-page", 2);
} }
export class ErrorAppComponent {
    constructor(eltRef) {
        this.eltRef = eltRef;
        this.errorCode = this.eltRef.nativeElement.getAttribute('errorcode');
    }
}
ErrorAppComponent.ɵfac = function ErrorAppComponent_Factory(t) { return new (t || ErrorAppComponent)(i0.ɵɵdirectiveInject(i0.ElementRef)); };
ErrorAppComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ErrorAppComponent, selectors: [["ycn-error-app"]], decls: 3, vars: 2, consts: [["isNot500", ""], [4, "ngIf", "ngIfElse"], [1, "page-content"]], template: function ErrorAppComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, ErrorAppComponent_ng_container_0_Template, 2, 0, "ng-container", 1)(1, ErrorAppComponent_ng_template_1_Template, 1, 0, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    } if (rf & 2) {
        const isNot500_r1 = i0.ɵɵreference(2);
        i0.ɵɵproperty("ngIf", ctx.errorCode === "500")("ngIfElse", isNot500_r1);
    } }, dependencies: [i1.NgIf, i2.YC404PageComponent, i3.YC500PageComponent], encapsulation: 2 });
