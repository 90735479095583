import { FormsModule } from '@angular/forms';
// Module imports
import { BrowserModule } from '@angular/platform-browser';
import { ConfigModule } from 'NewAngular/config/config.module';
import * as i0 from "@angular/core";
export class SupportFormModule {
}
SupportFormModule.ɵfac = function SupportFormModule_Factory(t) { return new (t || SupportFormModule)(); };
SupportFormModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: SupportFormModule });
SupportFormModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [BrowserModule, ConfigModule, FormsModule] });
