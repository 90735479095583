// Vendor libraries
import { ChangeDetectorRef } from '@angular/core';
import { finalize } from 'rxjs/operators';
// Services
import { AccountsService } from 'AccountsApp/ts/services/accounts.service';
import * as i0 from "@angular/core";
import * as i1 from "AccountsApp/ts/services/accounts.service";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../../ycharts/static/ts/features/directives/disabled/disabled.directive";
import * as i4 from "@angular/forms";
import * as i5 from "../../../../../../../ycharts/static/ts/features/components/modals/modal-base/components/modal-base.component";
import * as i6 from "../../../../../../../ycharts/static/ts/features/components/modals/modal-base/components/simple-modal-header.component";
import * as i7 from "../../../../../../../ycharts/static/ts/features/directives/icon/icon.directive";
const _c0 = a0 => ({ "btn-danger": a0 });
function InviteFriendModalComponent_div_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 10)(1, "p");
    i0.ɵɵtext(2, " We appreciate your enthusiastic support, however, we have a limit on referral submissions. We\u2019ll review your submissions and get back to you soon. In the meantime, please contact us with any questions. ");
    i0.ɵɵelementEnd()();
} }
function InviteFriendModalComponent_div_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 10)(1, "div", 11);
    i0.ɵɵtext(2, "Thank you for referring colleagues to YCharts");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "p");
    i0.ɵɵtext(4, "We\u2019ll email them some information on how to get started.");
    i0.ɵɵelementEnd()();
} }
function InviteFriendModalComponent_div_5_div_24_Template(rf, ctx) { if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 26)(1, "span");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 27);
    i0.ɵɵlistener("click", function InviteFriendModalComponent_div_5_div_24_Template_div_click_3_listener() { const i_r2 = i0.ɵɵrestoreView(_r1).index; const ctx_r2 = i0.ɵɵnextContext(2); return i0.ɵɵresetView(ctx_r2.removeEmail(i_r2)); });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(4, "svg", 28);
    i0.ɵɵelementEnd()();
} if (rf & 2) {
    const email_r4 = ctx.$implicit;
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(3, _c0, !email_r4.isValid));
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(email_r4.value);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("name", "icon-close");
} }
function InviteFriendModalComponent_div_5_input_25_Template(rf, ctx) { if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "input", 29);
    i0.ɵɵtwoWayListener("ngModelChange", function InviteFriendModalComponent_div_5_input_25_Template_input_ngModelChange_0_listener($event) { i0.ɵɵrestoreView(_r5); const ctx_r2 = i0.ɵɵnextContext(2); i0.ɵɵtwoWayBindingSet(ctx_r2.emailInput, $event) || (ctx_r2.emailInput = $event); return i0.ɵɵresetView($event); });
    i0.ɵɵlistener("blur", function InviteFriendModalComponent_div_5_input_25_Template_input_blur_0_listener() { i0.ɵɵrestoreView(_r5); const ctx_r2 = i0.ɵɵnextContext(2); return i0.ɵɵresetView(ctx_r2.onInputBlur()); })("keydown", function InviteFriendModalComponent_div_5_input_25_Template_input_keydown_0_listener($event) { i0.ɵɵrestoreView(_r5); const ctx_r2 = i0.ɵɵnextContext(2); return i0.ɵɵresetView(ctx_r2.onInputKeyDown($event)); });
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵtwoWayProperty("ngModel", ctx_r2.emailInput);
} }
function InviteFriendModalComponent_div_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 12)(1, "div", 13)(2, "div", 14);
    i0.ɵɵtext(3, "Unlock Additional Benefits When You Refer Colleagues");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "div", 15)(5, "div", 16)(6, "div", 17);
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(7, "svg", 18);
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(8, "div", 19);
    i0.ɵɵtext(9, "Share model strategies, proposals and more");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(10, "div", 16)(11, "div", 17);
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(12, "svg", 20);
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(13, "div", 19);
    i0.ɵɵtext(14, "Create consistent branding across teams");
    i0.ɵɵelementEnd()()();
    i0.ɵɵelementStart(15, "div", 16)(16, "div", 17);
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(17, "svg", 21);
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(18, "div", 19);
    i0.ɵɵtext(19, "Save time on administrative tasks");
    i0.ɵɵelementEnd()()()()();
    i0.ɵɵelementStart(20, "div", 22);
    i0.ɵɵtext(21, "Enter an email address to invite people to YCharts.");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(22, "div", 22)(23, "div", 23);
    i0.ɵɵtemplate(24, InviteFriendModalComponent_div_5_div_24_Template, 5, 5, "div", 24)(25, InviteFriendModalComponent_div_5_input_25_Template, 1, 1, "input", 25);
    i0.ɵɵelementEnd()()();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(7);
    i0.ɵɵproperty("name", "icon-benefit-1-color");
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("name", "icon-benefit-2-color");
    i0.ɵɵadvance(5);
    i0.ɵɵproperty("name", "icon-benefit-3-color");
    i0.ɵɵadvance(7);
    i0.ɵɵproperty("ngForOf", ctx_r2.emails);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.showAddEmail);
} }
function InviteFriendModalComponent_div_8_Template(rf, ctx) { if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 30);
    i0.ɵɵlistener("click", function InviteFriendModalComponent_div_8_Template_div_click_0_listener() { i0.ɵɵrestoreView(_r6); const ctx_r2 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r2.closeModal()); });
    i0.ɵɵtext(1, " Cancel ");
    i0.ɵɵelementEnd();
} }
function InviteFriendModalComponent_a_9_Template(rf, ctx) { if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 31);
    i0.ɵɵlistener("click", function InviteFriendModalComponent_a_9_Template_a_click_0_listener() { i0.ɵɵrestoreView(_r7); const ctx_r2 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r2.processingRequest || ctx_r2.emails.length === 0 || !ctx_r2.allEmailsValid || ctx_r2.submitReferral()); });
    i0.ɵɵtext(1, " Invite ");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("disabled", ctx_r2.processingRequest || ctx_r2.emails.length === 0 || !ctx_r2.allEmailsValid);
} }
function InviteFriendModalComponent_div_10_Template(rf, ctx) { if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 32);
    i0.ɵɵlistener("click", function InviteFriendModalComponent_div_10_Template_div_click_0_listener() { i0.ɵɵrestoreView(_r8); const ctx_r2 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r2.closeModal()); });
    i0.ɵɵtext(1, " Close ");
    i0.ɵɵelementEnd();
} }
/**
 *
 * Invite Friend Modal Component
 *
 * This component is responsible for:
 *  1. Accepting user input of the email addresses for friends they wish to invite to YCharts
 *  2. Handling when the Send button is clicked
 *
 */
export class InviteFriendModalComponent {
    constructor(accountsService, cdr) {
        this.accountsService = accountsService;
        this.cdr = cdr;
        this.allEmailsValid = true;
        this.emails = [];
        this.emailInput = '';
        this.modalOpen = false;
        this.processingRequest = false;
        this.showAddEmail = true;
        this.showCompletionModal = false;
        this.showLimitReached = false;
        this.MAX_NUM_EMAILS = 10;
        this.reset();
    }
    openModal() {
        this.modalOpen = true;
    }
    closeModal() {
        /**
         * Closes the modal and resets to the default state.
         */
        this.modalOpen = false;
        this.reset();
    }
    submitReferral() {
        /**
         * POSTs the emails to the backend view. Depending on whether the backend returns a success or error response,
         * we show various messages to the user.
         */
        this.processingRequest = true;
        const emailValues = this.emails.filter((email) => !!email.value).map((email) => email.value.trim());
        const combinedEmails = `${emailValues.join()},`;
        this.accountsService
            .postReferFriends(combinedEmails)
            .pipe(
        // Finalize gets called after both `next` and after `error`
        finalize(() => {
            this.processingRequest = false;
        }))
            .subscribe({
            next: (res) => {
                if (res.status === 200) {
                    this.showCompletionModal = true;
                }
            },
            error: (error) => {
                if (error.status === 400) {
                    const invalidEmails = error.error.invalid_emails;
                    for (let i = 0; i < this.emails.length; i += 1) {
                        this.emails[i].isValid = !invalidEmails.includes(this.emails[i].value);
                    }
                }
                else if (error.status === 403) {
                    if (error.error.over_email_limit) {
                        this.showLimitReached = true;
                    }
                }
            },
            complete: () => {
                // Parent component uses OnPush change detection, so manually trigger change detection here.
                this.cdr.detectChanges();
            },
        });
    }
    onInputKeyDown(event) {
        if (event.key === 'Enter') {
            if (this.emailInput !== '')
                this.addEmail(this.emailInput);
        }
    }
    onInputBlur() {
        if (this.emailInput.trim() !== '')
            this.addEmail(this.emailInput);
    }
    validateEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    addEmail(val) {
        /**
         * As long as we have not hit MAX_NUM_EMAILS, add an empty email to the array.
         */
        const newEmail = {
            value: val.trim(),
            isValid: this.validateEmail(val),
        };
        this.emails.push(newEmail);
        if (this.emails.length >= this.MAX_NUM_EMAILS) {
            this.showAddEmail = false;
        }
        if (!newEmail.isValid) {
            this.allEmailsValid = false;
        }
        this.emailInput = '';
    }
    removeEmail(index) {
        /**
         * Removes an email from the array.
         */
        this.emails.splice(index, 1);
        this.allEmailsValid = this.emails.every((email) => email.isValid);
        if (this.emails.length < this.MAX_NUM_EMAILS) {
            this.showAddEmail = true;
        }
    }
    reset() {
        /**
         * Resets all variables to their default state. This is called whenever the modal is closed, meaning we will
         * always start with a fresh slate each time the modal is opened.
         */
        this.showLimitReached = false;
        this.showCompletionModal = false;
        this.showAddEmail = true;
        this.processingRequest = false;
        this.emailInput = '';
        this.emails = [];
    }
}
InviteFriendModalComponent.ɵfac = function InviteFriendModalComponent_Factory(t) { return new (t || InviteFriendModalComponent)(i0.ɵɵdirectiveInject(i1.AccountsService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef)); };
InviteFriendModalComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: InviteFriendModalComponent, selectors: [["ycn-invite-friend-modal"]], decls: 11, vars: 7, consts: [[3, "closeModalHandler", "open"], [3, "closeModalHandler"], [1, "modal-body"], ["class", "modal-section", 4, "ngIf"], ["class", "modal-section border-b border-color-gray-80", 4, "ngIf"], [1, "modal-footer"], [1, "modal-footer-actions"], ["class", "btn btn-text", 3, "click", 4, "ngIf"], ["class", "btn btn-primary", "href", "javascript:void(0)", "role", "button", "ycnDisabled", "", 3, "disabled", "click", 4, "ngIf"], ["class", "btn btn-primary", 3, "click", 4, "ngIf"], [1, "modal-section"], [1, "mb-2", "form-title", "text-blue"], [1, "modal-section", "border-b", "border-color-gray-80"], [1, "mb-4"], [1, "form-title", "mb-4"], [1, "row"], [1, "col-4"], [1, "benefit"], ["ycnIcon", "", "role", "img", 1, "icon-benefit-1-color", "benefit-icon", 3, "name"], [1, "benefit-text"], ["ycnIcon", "", "role", "img", 1, "icon-benefit-2-color", "benefit-icon", 3, "name"], ["ycnIcon", "", "role", "img", 1, "icon-benefit-3-color", "benefit-icon", 3, "name"], [1, "mb-2"], [1, "queue"], ["class", "btn queue-item", 3, "ngClass", 4, "ngFor", "ngForOf"], ["class", "queue-input ng-pristine ng-untouched ng-valid ng-empty", "type", "text", 3, "ngModel", "ngModelChange", "blur", "keydown", 4, "ngIf"], [1, "btn", "queue-item", 3, "ngClass"], [1, "btn", "btn-xs", "btn-circle", "btn-transparent", "btn-close", 3, "click"], ["ycnIcon", "", "role", "img", 3, "name"], ["type", "text", 1, "queue-input", "ng-pristine", "ng-untouched", "ng-valid", "ng-empty", 3, "ngModelChange", "blur", "keydown", "ngModel"], [1, "btn", "btn-text", 3, "click"], ["href", "javascript:void(0)", "role", "button", "ycnDisabled", "", 1, "btn", "btn-primary", 3, "click", "disabled"], [1, "btn", "btn-primary", 3, "click"]], template: function InviteFriendModalComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "ycn-modal-base", 0);
        i0.ɵɵlistener("closeModalHandler", function InviteFriendModalComponent_Template_ycn_modal_base_closeModalHandler_0_listener() { return ctx.closeModal(); });
        i0.ɵɵelementStart(1, "ycn-simple-modal-header", 1);
        i0.ɵɵlistener("closeModalHandler", function InviteFriendModalComponent_Template_ycn_simple_modal_header_closeModalHandler_1_listener() { return ctx.closeModal(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵtemplate(3, InviteFriendModalComponent_div_3_Template, 3, 0, "div", 3)(4, InviteFriendModalComponent_div_4_Template, 5, 0, "div", 3)(5, InviteFriendModalComponent_div_5_Template, 26, 5, "div", 4);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(6, "div", 5)(7, "div", 6);
        i0.ɵɵtemplate(8, InviteFriendModalComponent_div_8_Template, 2, 0, "div", 7)(9, InviteFriendModalComponent_a_9_Template, 2, 1, "a", 8)(10, InviteFriendModalComponent_div_10_Template, 2, 0, "div", 9);
        i0.ɵɵelementEnd()()();
    } if (rf & 2) {
        i0.ɵɵproperty("open", ctx.modalOpen);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", ctx.showLimitReached);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.showCompletionModal);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", !ctx.showCompletionModal && !ctx.showLimitReached);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", !ctx.showCompletionModal && !ctx.showLimitReached);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", !ctx.showCompletionModal && !ctx.showLimitReached);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.showCompletionModal || ctx.showLimitReached);
    } }, dependencies: [i2.NgClass, i2.NgForOf, i2.NgIf, i3.DisabledDirective, i4.DefaultValueAccessor, i4.NgControlStatus, i4.NgModel, i5.ModalBaseComponent, i6.SimpleModalHeaderComponent, i7.IconDirective], encapsulation: 2 });
