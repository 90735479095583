// Constants
import { UNKNOWN_HTTP_FAILURE_RESPONSE_REGEX } from 'NewAngular/constants/sentry.constants';
// https://docs.sentry.io/clients/javascript/tips/
export default {
    ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        'You have been signed out due to a concurrent sign in using your credentials.',
        'You are signed out. Please sign in again.',
        'It looks like your website URL has been changed.',
        'Non-Error exception captured',
        /^.*undefined is not an object \(evaluating 'this.chart.hoverSeries'\)$/,
        /^.*undefined \(reading '(?:onContainerMouseLeave|reset|drop)'\)$/,
        UNKNOWN_HTTP_FAILURE_RESPONSE_REGEX,
        /change_ua/,
        /^Server returned code 503.*/,
        /^.*503 Service Unavailable.*/,
        /^Possibly unhandled rejection:.*\/quickflows\/quickflows_list.*/,
        'highlighter is not defined',
    ],
    denyUrls: [
        // Firefox extensions
        /^resource:\/\//i,
        // Chrome extensions
        /extensions?\//i,
        /^chrome:\/\//i,
    ],
};
