// Vendor libraries
import { CommonModule } from '@angular/common';
// Modules
import { IconModule } from 'NewAngular/features/directives/icon/icon.module';
import { InviteFriendModalModule } from 'AccountsApp/ts/features/invite-friend-modal/invite-friend-modal.module';
import { TooltipModule } from 'NewAngular/features/directives/popovers/tooltip/tooltip.module';
import { TopBarNavAutocompleteModule } from 'SearchApp/ts/features/top-bar-nav-autocomplete/top-bar-nav-autocomplete.module';
import { NotificationsModalModule } from 'NotificationsApp/ts/features/notifications-modal/notifications-modal.module';
import * as i0 from "@angular/core";
export class NavigationModule {
}
NavigationModule.ɵfac = function NavigationModule_Factory(t) { return new (t || NavigationModule)(); };
NavigationModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: NavigationModule });
NavigationModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [CommonModule,
        IconModule,
        InviteFriendModalModule,
        NotificationsModalModule,
        TooltipModule,
        TopBarNavAutocompleteModule] });
