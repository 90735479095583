// Vendor
import { createSelector } from '@ngrx/store';
import { createDeepEqualSelector } from 'NewAngular/state/selectors';
const notifications = (state) => state.notifications;
const getUnreadNotificationsCountState = (state) => state.unreadNotificationsCount;
export const getNotifications = createDeepEqualSelector(notifications, (state) => {
    return state;
});
export const getUnreadNotificationsCount = createSelector(getUnreadNotificationsCountState, (unreadNotificationsCountState) => {
    return unreadNotificationsCountState;
});
