import { Store } from '@ngrx/store';
// Services
import { UrlsService } from 'NewAngular/services/core/urls.service';
import { AjaxService } from 'NewAngular/services/core/ajax.service';
import { CUSTOM_PDF_REPORT_BUILDER_APP_STATE_SLICE_KEY } from 'CustomPDFReportsApp/ts/apps/custom-pdf-report-builder-app/state/custom-pdf-report-builder.reducer';
import { AccountsService } from 'AccountsApp/ts/services/accounts.service';
import * as i0 from "@angular/core";
import * as i1 from "NewAngular/services/core/ajax.service";
import * as i2 from "NewAngular/services/core/urls.service";
import * as i3 from "AccountsApp/ts/services/accounts.service";
import * as i4 from "@ngrx/store";
export class SearchService {
    constructor(ajaxService, urlsService, accountsService, store) {
        this.ajaxService = ajaxService;
        this.urlsService = urlsService;
        this.accountsService = accountsService;
        this.store = store;
    }
    getSuggestDataCategoryResults(searchType, q) {
        const urlParams = { searchType };
        const getParams = { q };
        const requestUrl = this.urlsService.getUrl(this.urlsService.SEARCH.SUGGEST, urlParams);
        return this.ajaxService.getDataObserveBody(requestUrl, getParams);
    }
    getSuggestDataListResults(searchType, queryParams, defaultOnly) {
        const urlParams = { searchType };
        const getParams = { ...queryParams };
        if (defaultOnly) {
            getParams.defaultOnly = defaultOnly;
        }
        const requestUrl = this.urlsService.getUrl(this.urlsService.SEARCH.SUGGEST, urlParams);
        return this.ajaxService.getDataObserveBody(requestUrl, getParams);
    }
    getSearchAllResults(searchType, queryInput, pageNum, perPage = 50) {
        const urlParams = { searchType, queryInput, pageNum, perPage };
        const requestUrl = this.urlsService.getUrl(this.urlsService.SEARCH.SEARCH_ALL_DATA);
        return this.ajaxService.getDataObserveBody(requestUrl, urlParams);
    }
}
SearchService.ɵfac = function SearchService_Factory(t) { return new (t || SearchService)(i0.ɵɵinject(i1.AjaxService), i0.ɵɵinject(i2.UrlsService), i0.ɵɵinject(i3.AccountsService), i0.ɵɵinject(i4.Store)); };
SearchService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: SearchService, factory: SearchService.ɵfac, providedIn: 'root' });
