// Vendor libraries
// We need to import svgxuse so that our external loading of SVGs works with Mobile Safari.
import 'svgxuse';
import { ElementRef, Renderer2, RendererFactory2, SecurityContext, SimpleChanges, } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
// Services
import { StaticFileUrlService } from 'NewAngular/services/static-file-url.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
import * as i2 from "NewAngular/services/static-file-url.service";
/**
 *
 * Icon Directive
 *
 * Displays an icon svg
 *
 * Input Properties:
 *
 *  name: The name of the icon (e.g. "icon-save")
 *
 */
export class IconDirective {
    constructor(el, rendererFactory, sanitizer, staticFileUrlService) {
        this.el = el;
        this.rendererFactory = rendererFactory;
        this.sanitizer = sanitizer;
        this.staticFileUrlService = staticFileUrlService;
        this.fallbackUrl = '';
        this.iconSVG = undefined;
        this.XML_NAMESPACE = 'http://www.w3.org/2000/svg';
        this.renderer = rendererFactory.createRenderer(null, null);
    }
    ngOnChanges(changes) {
        if (changes?.name.currentValue !== changes?.name.previousValue) {
            if (this.iconSVG)
                this.destroyIcon();
            this.createIcon();
        }
    }
    createIcon() {
        this.renderer.setAttribute(this.el.nativeElement, 'xmlns', this.XML_NAMESPACE);
        this.renderer.addClass(this.el.nativeElement, this.name);
        this.fallbackUrl = `/media/images/redesign/icons.svg#${this.name}`;
        this.staticFileUrlService
            .getRelativeUrl('images/redesign/icons.svg')
            .pipe(map((relativeUrl) => {
            // Allow angular to trust the icon.svg file we are requesting to load dynamically.
            const resourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${relativeUrl}#${this.name}`);
            const sanitizedUrl = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, resourceUrl);
            // If sanitizing fails, fall back to a non-sanitized URL.
            if (sanitizedUrl !== null) {
                return sanitizedUrl;
            }
            return this.fallbackUrl;
        }))
            .subscribe({
            next: (url) => {
                // Once we have our sanitized URl or fallback URL create the svg icon.
                this.createSVGElement(url);
            },
            error: () => {
                // If at any point an error is thrown, use the fallback url
                this.createSVGElement(this.fallbackUrl);
            },
        });
    }
    createSVGElement(url) {
        /**
         * 1. Sets the <svg> element class and href attributes.
         * 2. Creates the <use> element as a child of the <svg> element.
         */
        this.iconSVG = this.renderer.createElement('use', this.XML_NAMESPACE);
        this.renderer.setAttribute(this.iconSVG, 'href', url);
        this.renderer.appendChild(this.el.nativeElement, this.iconSVG);
    }
    destroyIcon() {
        this.renderer.removeChild(this.el.nativeElement, this.iconSVG);
        this.iconSVG = undefined;
    }
}
IconDirective.ɵfac = function IconDirective_Factory(t) { return new (t || IconDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.RendererFactory2), i0.ɵɵdirectiveInject(i1.DomSanitizer), i0.ɵɵdirectiveInject(i2.StaticFileUrlService)); };
IconDirective.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: IconDirective, selectors: [["svg", "ycnIcon", ""]], inputs: { name: "name" }, features: [i0.ɵɵNgOnChangesFeature] });
