import { createAction, props } from '@ngrx/store';
/**
 * This action is dispatched when a new autocompleter is instantiated.
 */
export const addAutocompleterId = createAction('[Autocompleter] Add Autocompleter ID', props());
export const blurAutocompleter = createAction('[Autocompleter] Blur', props());
export const decrementSelectedIndex = createAction('[Autocompleter] Decrement Selected Index', props());
export const focusAutocompleter = createAction('[Autocompleter] Focus', props());
export const incrementSelectedIndex = createAction('[Autocompleter] Increment Selected Index', props());
export const setAutocompleteInputText = createAction('[Autocompleter] Set Input Text', props());
export const setAutocompleteListResults = createAction('[Autocompleter] Set List Results', props());
export const setAutocompleteCategoriesResults = createAction('[Autocompleter] Set Categories Results and extra links', props());
export const setAutocompleteDefaultLinks = createAction('[Autocompleter] Set default links', props());
export const setAutocompleteSelectedIndex = createAction('[Autocompleter] Set Selected Index', props());
export const resetAutocompleter = createAction('[Autocompleter] Reset Autocompleter', props());
export const setAutocompleteShouldBlur = createAction('[Autocompleter] Set Should Blur', props());
export const selectResultByIndex = createAction('[Autocompleter] Select Result By Index', props());
export const setAutocompleteShowResults = createAction('[Autocompleter] Set Show Results', props());
