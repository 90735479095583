import getSymbolFromCurrency from 'currency-symbol-map';
// number constants
export const FIGURE = 'figure';
export const FIGURE_MONEY = 'figure_money';
export const FIGURE_WHOLE = 'figure_whole';
export const PERCENT = 'percent';
export const THOU_FIGURE = 'thou_figure';
export const THOU_FIGURE_MONEY = 'thou_figure_money';
export const MIL_FIGURE = 'mil_figure';
export const MIL_FIGURE_MONEY = 'mil_figure_money';
export const BIL_FIGURE = 'bil_figure';
export const BIL_FIGURE_MONEY = 'bil_figure_money';
export const TRIL_FIGURE = 'tril_figure';
export const TRIL_FIGURE_MONEY = 'tril_figure_money';
export const QUADRIL_FIGURE = 'quadril_figure';
export const QUADRIL_FIGURE_MONEY = 'quadril_figure_money';
// Unit constants
export const K = 'K';
export const M = 'M';
export const B = 'B';
export const T = 'T';
export const Q = 'Q';
export const QN = 'QN';
export const S = 'S';
export const SP = 'SP';
export const O = 'O';
export const N = 'N';
const PLACES_OPTIONS = {
    6: 1,
    5: 2,
    4: 2,
    3: 2,
    2: 2,
    1: 3,
};
const UNITS = ['', K, M, B, T, Q, QN, S, SP, O, N];
function rtrim(str, chars) {
    const newChars = chars || '\\s';
    return str.replace(new RegExp(`[${newChars}]+$`, 'g'), '');
}
// Get the right number of places, based on the value and asked for places
function getFractionalDigits(inputVal, requestedPlaces, dataType) {
    let val = inputVal;
    if (requestedPlaces === 0) {
        // If the number is not same rounded as not rounded,
        // and the func call says to round to 0 places, ignore
        // that and round to at least one place
        return 1;
    }
    if (requestedPlaces === undefined || requestedPlaces === null) {
        // If no requestedPlaces where given, then we get the number
        // of places based on the magnitude of the value and the type
        let maxPlaces = 4;
        // In this block we try to figure out how many significant
        // digits exist after a decimal point.
        // First round number to more places than we'll need because
        // floats are weird
        const exp = 10 ** (maxPlaces + 1); // eslint-disable-line no-restricted-properties
        val = Math.round(val * exp) / exp;
        let valStr = val.toString();
        let valLength;
        // Remove the '-' negative sumbol so we can process the decimal places.
        if (valStr.charAt(0) === '-') {
            valStr = valStr.substr(1);
        }
        let decPos = val.toString().indexOf('.');
        if (decPos !== -1) {
            // If we find a decimal point, strip off any trailing zeroes
            // as those don't add any precision
            valStr = rtrim(valStr, '0');
            decPos = valStr.indexOf('.');
            maxPlaces = valStr.length - (decPos + 1);
            valLength = decPos;
        }
        else {
            // If we have no decimal point (ie 5) then only allow a max of 2 digits
            maxPlaces = 2;
            valLength = valStr.length;
        }
        // Force at least 2 places, even if we don't need it.
        maxPlaces = Math.max(maxPlaces, 2);
        // Uses length of valStr in order to determine hundreds,
        // thou, mil, etc to get dec places.
        let places;
        if (dataType === PERCENT) {
            if (valLength >= 4) {
                places = 0;
            }
            else if (valLength === 3) {
                places = 1;
            }
            else {
                places = 2;
            }
        }
        else if (valLength >= 7) {
            // At 7+ places before decimal, we just dont have room to display decimal
            places = 0;
        }
        else {
            // @ts-ignore
            places = PLACES_OPTIONS[valLength] || 4;
        }
        return Math.min(places, maxPlaces);
    }
    // Else we just return back the number of places;
    return requestedPlaces;
}
function percentFormatter(inputVal, places) {
    let val = inputVal;
    let unit = '';
    // If the percent is greater than or less than 1000, break it
    // down by dividing by 1000 and adding K
    if (val >= 1e3 || val <= -1e3) {
        val /= 1e3;
        unit = K;
    }
    const fractionDigits = getFractionalDigits(val, places, PERCENT);
    return `${val.toFixed(fractionDigits)}${unit}%`;
}
function getFigureFormatter(whole, currencyCode) {
    return (inputVal, places) => {
        let val = inputVal;
        let unit = '';
        // Only add the K unit if we have a currency, otherwise we don't use it because its too
        // small a unit to show prices or most calculations in.
        const absVal = Math.abs(inputVal);
        if (currencyCode && absVal < 1e6 && val !== 0) {
            unit = K;
            val /= 1e3;
        }
        else if (absVal >= 1e6 && absVal < 1e9) {
            unit = M;
            val /= 1e6;
        }
        else if (absVal >= 1e9 && absVal < 1e12) {
            unit = B;
            val /= 1e9;
        }
        else if (absVal >= 1e12 && absVal < 1e15) {
            unit = T;
            val /= 1e12;
        }
        else if (absVal >= 1e15 && absVal < 1e18) {
            unit = Q;
            val /= 1e15;
        }
        // Only get fractional digits if its not being formatted as whole number.
        const fractionDigits = whole ? 0 : getFractionalDigits(val, places, FIGURE);
        if (currencyCode) {
            let currencySymbol = getSymbolFromCurrency(currencyCode);
            if (currencySymbol === undefined) {
                currencySymbol = '';
            }
            return `${currencySymbol}${val.toFixed(fractionDigits)}${unit}`;
        }
        return `${val.toFixed(fractionDigits)}${unit}`;
    };
}
function getSimplifiedValueWithUnit(inputVal, targetBaseUnit) {
    let val = inputVal;
    if (val === 0)
        return [val, ''];
    let unitIdx = UNITS.indexOf(targetBaseUnit);
    while (val >= 1e3 || val <= -1e3) {
        unitIdx += 1;
        val /= 1e3;
    }
    return [val, UNITS[unitIdx]];
}
function getUnitFormatter(inputUnitType, currencyCode) {
    return (inputVal, places) => {
        const [finalVal, unit] = getSimplifiedValueWithUnit(inputVal, inputUnitType);
        const fractionDigits = getFractionalDigits(finalVal, places);
        if (currencyCode) {
            let currencySymbol = getSymbolFromCurrency(currencyCode);
            if (currencySymbol === undefined) {
                currencySymbol = '';
            }
            return `${currencySymbol}${finalVal.toFixed(fractionDigits)}${unit}`;
        }
        return `${finalVal.toFixed(fractionDigits)}${unit}`;
    };
}
function getFormatter(dataType, currencyCode) {
    switch (dataType) {
        case PERCENT:
            return percentFormatter;
        case FIGURE:
            return getFigureFormatter(false);
        case FIGURE_MONEY:
            return getFigureFormatter(false, currencyCode);
        case FIGURE_WHOLE:
            return getFigureFormatter(true);
        case THOU_FIGURE:
            return getUnitFormatter(K);
        case THOU_FIGURE_MONEY:
            return getUnitFormatter(K, currencyCode);
        case MIL_FIGURE:
            return getUnitFormatter(M);
        case MIL_FIGURE_MONEY:
            return getUnitFormatter(M, currencyCode);
        case BIL_FIGURE:
            return getUnitFormatter(B);
        case BIL_FIGURE_MONEY:
            return getUnitFormatter(B, currencyCode);
        case TRIL_FIGURE:
            return getUnitFormatter(T);
        case TRIL_FIGURE_MONEY:
            return getUnitFormatter(T, currencyCode);
        case QUADRIL_FIGURE:
            return getUnitFormatter(Q);
        case QUADRIL_FIGURE_MONEY:
            return getUnitFormatter(Q, currencyCode);
        default:
            return () => null;
    }
}
function getBasicFormatted(value, dataType, places) {
    if (dataType.includes(FIGURE))
        return getFormatter(FIGURE)(value, places);
    if (dataType.includes(PERCENT))
        return getFormatter(PERCENT)(value, places);
    return getFormatter(dataType)(value, places);
}
export { getBasicFormatted, getFormatter, getFractionalDigits };
