// Vendor libraries
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
// Modules
import { DisabledModule } from 'NewAngular/features/directives/disabled/disabled.module';
import { IconModule } from 'NewAngular/features/directives/icon/icon.module';
import * as i0 from "@angular/core";
export class ModalBaseModule {
}
ModalBaseModule.ɵfac = function ModalBaseModule_Factory(t) { return new (t || ModalBaseModule)(); };
ModalBaseModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: ModalBaseModule });
ModalBaseModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [CommonModule, DisabledModule, IconModule, FormsModule] });
