// Vendor libraries
import { ElementRef, NgZone } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
// Directives
import { PopoverBaseDirective } from 'NewAngular/features/directives/popovers/base/popover-base.directive';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
/**
 * Tooltip Directive
 *
 * Extends the base popover directive and sets the event listeners to be on mouseenter for showing and on mouseleave
 * for hiding
 *
 */
export class TooltipDirective extends PopoverBaseDirective {
    constructor(el, sanitizer, ngZone) {
        super(el, sanitizer);
        this.el = el;
        this.ngZone = ngZone;
        this.ngZone.runOutsideAngular(() => {
            this.el.nativeElement.addEventListener('mouseenter', () => {
                this.showTooltip();
            });
            this.el.nativeElement.addEventListener('mouseleave', () => {
                this.hideTooltip();
            });
        });
    }
}
TooltipDirective.ɵfac = function TooltipDirective_Factory(t) { return new (t || TooltipDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.DomSanitizer), i0.ɵɵdirectiveInject(i0.NgZone)); };
TooltipDirective.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: TooltipDirective, selectors: [["", "ycnTooltip", ""]], features: [i0.ɵɵInheritDefinitionFeature] });
