// Vendor libraries
import { ElementRef, NgZone, Renderer2, RendererFactory2 } from '@angular/core';
// Enums
import { AppendToBodyPositionTypeEnum } from 'NewAngular/enums/append-to-body-position-type.enum';
import * as i0 from "@angular/core";
/**
 *
 * AppendToBody Directive
 *
 */
export class AppendToBodyDirective {
    constructor(el, ngZone, rendererFactory) {
        this.el = el;
        this.ngZone = ngZone;
        this.rendererFactory = rendererFactory;
        this.ycnAppendToBodyPosition = AppendToBodyPositionTypeEnum.BOTTOM_LEFT;
        this.renderer = rendererFactory.createRenderer(null, null);
    }
    ngAfterViewInit() {
        /**
         * The logic to have the element track its parent is done in regular
         * javascript in order to remove it from angular's digest loop.
         * We are essentially creating an interval that continuously calls
         * setXYCoordinates until the cutoff time is reached
         * to avoid infinite calls and poor performance.
         */
        if (!this.ycnAppendToBody) {
            return;
        }
        // Run outside of Angular so that setTimeout doesn't trigger unnecessary change detection cycles
        this.ngZone.runOutsideAngular(() => {
            /**
             * Add a custom class to mark the component as using ycnAppendToBody.
             * This is used to ensure clicking on autocomplete results part of a modal do not close the modal it is part of.
             */
            this.renderer.addClass(this.el.nativeElement, 'ycnAppendToBody');
            const { body } = document;
            const element = this.el.nativeElement;
            const parent = this.renderer.parentNode(element);
            let globalIntervalId;
            let globalTimerId;
            this.setXYCoordinates(element, parent);
            this.renderer.appendChild(body, element);
            const parentEventHandler = () => {
                clearInterval(globalIntervalId);
                clearTimeout(globalTimerId);
                [globalIntervalId, globalTimerId] = this.trackParent(element, parent, 100, 20000);
            };
            //  keyup is the most widely supported key event. When a user types anything in the parent,
            //  we begin tracking for a specified period of time. If multiple keys are pressed, we are
            //  clearing the original interval and setting a new one.
            parent.addEventListener('keyup', parentEventHandler);
            //  mouseup is needed for elements that are activated with a click instead of a key, like the date picker.
            parent.addEventListener('mouseup', parentEventHandler);
            //  when a user resizes the window, we are tracking the movement of the parent again
            window.addEventListener('resize', () => {
                clearInterval(globalIntervalId);
                clearTimeout(globalTimerId);
                [globalIntervalId, globalTimerId] = this.trackParent(element, parent, 100, 10000);
            });
        });
    }
    calculateTop(parentClientRect) {
        if (this.ycnAppendToBodyPosition === AppendToBodyPositionTypeEnum.BOTTOM_LEFT) {
            return parentClientRect.top + parentClientRect.height + window.scrollY;
        }
        if (this.ycnAppendToBodyPosition === AppendToBodyPositionTypeEnum.TOP_RIGHT) {
            return parentClientRect.top + window.scrollY;
        }
        return 0;
    }
    calculateLeft(elementRect, parentClientRect) {
        if (this.ycnAppendToBodyPosition === AppendToBodyPositionTypeEnum.BOTTOM_LEFT) {
            if (elementRect.width + parentClientRect.left >= window.innerWidth) {
                return parentClientRect.left - (elementRect.width - parentClientRect.width);
            }
            return parentClientRect.left;
        }
        if (this.ycnAppendToBodyPosition === AppendToBodyPositionTypeEnum.TOP_RIGHT) {
            return parentClientRect.left + parentClientRect.width;
        }
        return 0;
    }
    setXYCoordinates(element, parent) {
        const elementRect = element.getBoundingClientRect();
        // If the element has no width because it is hidden then do not set the X / Y coordinates.
        if (elementRect.width === 0) {
            return;
        }
        const parentRect = parent.getBoundingClientRect();
        const calculatedTop = this.calculateTop(parentRect);
        const calculatedLeft = this.calculateLeft(elementRect, parentRect);
        this.renderer.setStyle(element, 'zIndex', '10000');
        this.renderer.setStyle(element, 'top', `${calculatedTop}px`);
        this.renderer.setStyle(element, 'left', `${calculatedLeft}px`);
    }
    trackParent(element, parent, interval, cutoff) {
        const intervalId = setInterval(() => {
            this.setXYCoordinates(element, parent);
        }, interval);
        const timerId = setTimeout(() => {
            clearTimeout(intervalId);
        }, cutoff);
        return [Number(intervalId), Number(timerId)];
    }
}
AppendToBodyDirective.ɵfac = function AppendToBodyDirective_Factory(t) { return new (t || AppendToBodyDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.RendererFactory2)); };
AppendToBodyDirective.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: AppendToBodyDirective, selectors: [["", "ycnAppendToBody", ""]], inputs: { ycnAppendToBody: "ycnAppendToBody", ycnAppendToBodyPosition: "ycnAppendToBodyPosition" } });
