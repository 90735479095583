// Vendor libraries
import { ErrorHandler } from '@angular/core';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
// Services
import { SentryErrorHandler } from 'NewAngular/services/sentry/sentry.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
// This module sets the CSRF tokens. It must be imported into every app.module.ts!
export class ConfigModule {
}
ConfigModule.ɵfac = function ConfigModule_Factory(t) { return new (t || ConfigModule)(); };
ConfigModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: ConfigModule });
ConfigModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ providers: [
        {
            provide: ErrorHandler,
            useClass: SentryErrorHandler,
        },
    ], imports: [HttpClientModule,
        HttpClientXsrfModule.withOptions({
            cookieName: 'csrftoken',
            headerName: 'X-CSRFToken',
        })] });
