import * as i0 from "@angular/core";
import * as i1 from "../../../../features/directives/icon/icon.directive";
import * as i2 from "NewAngular/apps/error-app/components/404-page/error-search.component";
export class YC404PageComponent {
}
YC404PageComponent.ɵfac = function YC404PageComponent_Factory(t) { return new (t || YC404PageComponent)(); };
YC404PageComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: YC404PageComponent, selectors: [["ycn-404-page"]], decls: 14, vars: 0, consts: [[1, "page", "page-error"], [1, "container", "justify-content-center", "align-items-center"], [1, "panel"], [1, "form-row"], ["href", "/support", 1, "page-error-link"], ["ycnIcon", "", "name", "icon-right-arrow"]], template: function YC404PageComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div", 2)(3, "h1");
        i0.ɵɵtext(4, "Page Not Found");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(5, "p");
        i0.ɵɵtext(6, "We\u2019re sorry, but the page you\u2019re looking for is missing or no longer exists. Try searching for what you\u2019re looking to find.");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(7, "div", 3);
        i0.ɵɵelement(8, "ycn-error-search");
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(9, "p")(10, "a", 4)(11, "span");
        i0.ɵɵtext(12, "Visit Support Topics");
        i0.ɵɵelementEnd();
        i0.ɵɵnamespaceSVG();
        i0.ɵɵelement(13, "svg", 5);
        i0.ɵɵelementEnd()()()()();
    } }, dependencies: [i1.IconDirective, i2.ErrorSearchComponent], encapsulation: 2 });
