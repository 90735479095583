// Vendor libraries
import { ElementRef, EventEmitter, NgZone, SimpleChanges, } from '@angular/core';
// Services
import { UtilsService } from 'NewAngular/services/core/utils.service';
import { ModalManagerService } from '../services/modal-manager-service';
import * as i0 from "@angular/core";
import * as i1 from "NewAngular/services/core/utils.service";
import * as i2 from "../services/modal-manager-service";
import * as i3 from "@angular/common";
const _c0 = ["modalBaseDiv"];
const _c1 = ["*"];
const _c2 = a0 => ({ show: a0 });
const _c3 = a0 => ({ "display": a0 });
/**
 *
 * Modal Base Component
 *
 * Input Properties:
 *
 *  extraModalClasses: class names to be added to modal-dialog div
 *  focusElementId: id of element to initially set focus
 *  modalBaseClass: class for the surrounding div
 *  open: Whether the modal should be open
 *  requiresAction: Whether the modal requires an explict action (X, submit, cancel) to close.
 *                  Modal will not automatically close when user clicks outside the modal's content if true
 *
 *
 * Output Properties:
 *
 *  closeModalHandler: EventEmitter that emits when the modal is closed
 *
 */
export class ModalBaseComponent {
    constructor(utilsService, modalManagerService, ngZone) {
        this.utilsService = utilsService;
        this.modalManagerService = modalManagerService;
        this.ngZone = ngZone;
        this.requiresAction = false;
        this.closeModalHandler = new EventEmitter();
        this.modalManager = { open() { }, close() { } };
    }
    ngAfterViewInit() {
        /**
         * This lifecycle hook is called after Angular has fully initialized the view, meaning that modalBaseDiv has
         * been initialized and we can now get its modal manager
         */
        const closeHandler = () => {
            // Re-enter angular zone so change detection runs when to the `open` property is updated
            // and the modal can close properly
            this.ngZone.run(() => {
                if (this.open) {
                    this.closeModalHandler.emit();
                }
            });
        };
        this.modalManager = this.modalManagerService.getModalManager(this.modalBaseDiv.nativeElement, closeHandler, this.requiresAction);
    }
    ngOnChanges(changes) {
        if (changes.open && !changes.open.isFirstChange()) {
            const { currentValue, previousValue } = changes.open;
            if (currentValue && !previousValue) {
                this.modalManager.open();
                setTimeout(() => {
                    if (this.focusElementId) {
                        const el = document.querySelector(`.modal.show #${this.focusElementId}`);
                        if (el)
                            el.focus();
                    }
                });
            }
            if (previousValue && !currentValue)
                this.modalManager.close();
        }
    }
    ngOnDestroy() {
        if (this.open)
            this.modalManager.close();
    }
}
ModalBaseComponent.ɵfac = function ModalBaseComponent_Factory(t) { return new (t || ModalBaseComponent)(i0.ɵɵdirectiveInject(i1.UtilsService), i0.ɵɵdirectiveInject(i2.ModalManagerService), i0.ɵɵdirectiveInject(i0.NgZone)); };
ModalBaseComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ModalBaseComponent, selectors: [["ycn-modal-base"]], viewQuery: function ModalBaseComponent_Query(rf, ctx) { if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.modalBaseDiv = _t.first);
    } }, inputs: { extraModalClasses: "extraModalClasses", focusElementId: "focusElementId", modalBaseClass: "modalBaseClass", open: "open", requiresAction: "requiresAction" }, outputs: { closeModalHandler: "closeModalHandler" }, features: [i0.ɵɵNgOnChangesFeature], ngContentSelectors: _c1, decls: 5, vars: 10, consts: [["modalBaseDiv", ""], ["aria-hidden", "true", "role", "dialog", 3, "ngClass", "ngStyle"], [1, "modal-dialog", 3, "ngClass"], [1, "modal-content"]], template: function ModalBaseComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵelementStart(0, "div", 1, 0)(2, "div", 2)(3, "div", 3);
        i0.ɵɵprojection(4);
        i0.ɵɵelementEnd()()();
    } if (rf & 2) {
        i0.ɵɵclassMapInterpolate1("modal ", ctx.modalBaseClass || "", "");
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(6, _c2, ctx.open))("ngStyle", i0.ɵɵpureFunction1(8, _c3, ctx.open ? "block" : "none"));
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngClass", ctx.extraModalClasses);
    } }, dependencies: [i3.NgClass, i3.NgStyle], encapsulation: 2 });
