// Vendor libraries
import { ChangeDetectorRef } from '@angular/core';
import { finalize } from 'rxjs/operators';
// Services
import { FeatureService } from 'EntitlementsApp/ts/features/feature-needed/feature.service';
// Utilities
import { YChartsSentryUtils } from 'NewAngular/services/sentry/sentry-utils';
import * as i0 from "@angular/core";
import * as i1 from "EntitlementsApp/ts/features/feature-needed/feature.service";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../../ycharts/static/ts/features/components/modals/modal-base/components/modal-base.component";
import * as i4 from "../../../../../../../ycharts/static/ts/features/components/modals/modal-base/components/simple-modal-header.component";
function FeatureNeededComponent_ycn_simple_modal_header_1_Template(rf, ctx) { if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ycn-simple-modal-header", 6);
    i0.ɵɵlistener("closeModalHandler", function FeatureNeededComponent_ycn_simple_modal_header_1_Template_ycn_simple_modal_header_closeModalHandler_0_listener() { i0.ɵɵrestoreView(_r1); const ctx_r1 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r1.closeModal()); });
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("header", ctx_r1.state.featureInfo.feature_headline);
} }
function FeatureNeededComponent_div_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7)(1, "div", 8)(2, "div", 9);
    i0.ɵɵtext(3, "Loading...");
    i0.ɵɵelementEnd();
    i0.ɵɵelement(4, "div", 10);
    i0.ɵɵelementEnd()();
} }
function FeatureNeededComponent_div_4_span_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵelement(1, "br")(2, "br");
    i0.ɵɵtext(3, "Please note that this feature is only available as an add-on to YCharts subscriptions. ");
    i0.ɵɵelementEnd();
} }
function FeatureNeededComponent_div_4_span_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵelement(1, "br")(2, "br");
    i0.ɵɵtext(3, "Please note that this feature requires full activation of your account and is not permitted during the free trial period. ");
    i0.ɵɵelementEnd();
} }
function FeatureNeededComponent_div_4_span_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 17);
    i0.ɵɵtext(1, " No credit card required. ");
    i0.ɵɵelementEnd();
} }
function FeatureNeededComponent_div_4_div_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtext(1, " Already a subscriber? ");
    i0.ɵɵelementStart(2, "a", 18)(3, "strong");
    i0.ɵɵtext(4, "Sign in.");
    i0.ɵɵelementEnd()()();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("href", ctx_r1.state.featureInfo.login_url, i0.ɵɵsanitizeUrl);
} }
function FeatureNeededComponent_div_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7)(1, "p", 11);
    i0.ɵɵelement(2, "span", 12);
    i0.ɵɵtemplate(3, FeatureNeededComponent_div_4_span_3_Template, 4, 0, "span", 13)(4, FeatureNeededComponent_div_4_span_4_Template, 4, 0, "span", 13);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "p", 14);
    i0.ɵɵelement(6, "a", 15);
    i0.ɵɵtemplate(7, FeatureNeededComponent_div_4_span_7_Template, 2, 0, "span", 16);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(8, FeatureNeededComponent_div_4_div_8_Template, 5, 1, "div", 13);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("textContent", ctx_r1.state.featureInfo.feature_description);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.state.featureInfo.warn_about_addon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.state.featureInfo.warn_about_activate);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("href", ctx_r1.state.featureInfo.feature_payment_url, i0.ɵɵsanitizeUrl)("textContent", ctx_r1.state.featureInfo.call_to_action);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.state.featureInfo.is_upgrade && !ctx_r1.state.featureInfo.is_activate && !ctx_r1.state.featureInfo.is_addon);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.state.featureInfo.is_upgrade && !ctx_r1.state.featureInfo.is_activate && !ctx_r1.state.featureInfo.is_addon);
} }
function FeatureNeededComponent_div_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7)(1, "div", 19);
    i0.ɵɵtext(2, "An error has occurred.");
    i0.ɵɵelementEnd()();
} }
/**
 *
 * Feature Needed Component
 *
 * Modal indicating to the user that they do not have the required feature to support certain functionality.
 *
 */
export class FeatureNeededComponent {
    constructor(featureService, changeDetector) {
        this.featureService = featureService;
        this.changeDetector = changeDetector;
        this.state = {
            featureInfo: null,
            loading: true,
            modalOpen: false,
            error: false,
        };
    }
    openModal(feature) {
        /**
         * The caller of `openModal` should check whether the use has the feature before opening
         * the modal.
         */
        this.state.loading = true;
        this.state.error = false;
        this.featureService
            .getFeature(feature, window.location.pathname)
            .pipe(
        // Finalize gets called after both `next` and after `error`
        finalize(() => {
            this.state.loading = false;
            this.state.modalOpen = true;
            this.changeDetector.markForCheck();
        }))
            .subscribe({
            next: (data) => {
                this.state.featureInfo = data;
            },
            error: (e) => {
                // The only way there could be an error is if it is a bug or network error, alert sentry here
                this.state.error = true;
                YChartsSentryUtils.captureException(e);
            },
        });
    }
    closeModal() {
        this.state.modalOpen = false;
        this.changeDetector.markForCheck();
    }
}
FeatureNeededComponent.ɵfac = function FeatureNeededComponent_Factory(t) { return new (t || FeatureNeededComponent)(i0.ɵɵdirectiveInject(i1.FeatureService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef)); };
FeatureNeededComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: FeatureNeededComponent, selectors: [["ycn-feature-needed"]], decls: 8, vars: 6, consts: [[3, "closeModalHandler", "extraModalClasses", "open"], [3, "header", "closeModalHandler", 4, "ngIf"], [1, "modal-body"], ["class", "modal-section", 4, "ngIf"], [1, "modal-footer"], [1, "modal-footer-actions"], [3, "closeModalHandler", "header"], [1, "modal-section"], [1, "loader"], [1, "loader-message"], [1, "loader-spinner"], [1, "public-intro"], [3, "textContent"], [4, "ngIf"], [1, "form-inline"], [1, "btn", "btn-lg", "btn-primary", 3, "href", "textContent"], ["class", "form-control-plaintext", 4, "ngIf"], [1, "form-control-plaintext"], [1, "text-blue", 3, "href"], [1, "modal-message"]], template: function FeatureNeededComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "ycn-modal-base", 0);
        i0.ɵɵlistener("closeModalHandler", function FeatureNeededComponent_Template_ycn_modal_base_closeModalHandler_0_listener() { return ctx.closeModal(); });
        i0.ɵɵtemplate(1, FeatureNeededComponent_ycn_simple_modal_header_1_Template, 1, 1, "ycn-simple-modal-header", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵtemplate(3, FeatureNeededComponent_div_3_Template, 5, 0, "div", 3)(4, FeatureNeededComponent_div_4_Template, 9, 7, "div", 3)(5, FeatureNeededComponent_div_5_Template, 3, 0, "div", 3);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(6, "div", 4);
        i0.ɵɵelement(7, "div", 5);
        i0.ɵɵelementEnd()();
    } if (rf & 2) {
        i0.ɵɵproperty("extraModalClasses", "public modal-no-border")("open", ctx.state.modalOpen);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.state.featureInfo);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.state.loading);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.state.featureInfo);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.state.error);
    } }, dependencies: [i2.NgIf, i3.ModalBaseComponent, i4.SimpleModalHeaderComponent], encapsulation: 2, changeDetection: 0 });
