import { createSelectorFactory, defaultMemoize } from '@ngrx/store';
import equal from 'NewAngular/utils/equal/deep-equal';
export const createDeepEqualSelector = createSelectorFactory((projectionFn) => {
    /**
     * This is a custom selector that compares arguments using the YCharts `equal` rather than
     * the default of a strict object equality (===). We do this since our states in certain
     * situations are replaces as objects even though they have not changed.
     */
    return defaultMemoize(projectionFn, equal);
});
