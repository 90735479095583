// Vendor libraries
import { ChangeDetectorRef, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
// NgRx actions / selectors
import { AUTOCOMPLETER_STATE_SLICE_KEY } from 'SearchApp/ts/features/base-autocomplete/state/autocompleter.reducer';
// Services
import { UrlsService } from 'NewAngular/services/core/urls.service';
// Directives
import { SearchResultsBaseDirective } from 'SearchApp/ts/features/base-autocomplete/directives/search-results.directive';
import * as i0 from "@angular/core";
import * as i1 from "NewAngular/services/core/urls.service";
import * as i2 from "@ngrx/store";
import * as i3 from "@angular/common";
import * as i4 from "./extra-links.component";
import * as i5 from "../../../../../../../../ycharts/static/ts/features/pipes/index-of/index-of.pipe";
const _c0 = a0 => ({ "active": a0 });
function CategoriesResultsComponent_section_2_ng_container_1_a_3_Template(rf, ctx) { if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 9);
    i0.ɵɵlistener("click", function CategoriesResultsComponent_section_2_ng_container_1_a_3_Template_a_click_0_listener() { i0.ɵɵrestoreView(_r1); const category_r2 = i0.ɵɵnextContext(2).$implicit; const ctx_r2 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r2.navigateToMoreResultsUrl(category_r2)); });
    i0.ɵɵelementEnd();
} }
function CategoriesResultsComponent_section_2_ng_container_1_div_5_dt_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "dt", 14);
} if (rf & 2) {
    const result_r5 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("textContent", result_r5.display_security_id);
} }
function CategoriesResultsComponent_section_2_ng_container_1_div_5_div_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "div", 15);
} if (rf & 2) {
    const result_r5 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("textContent", result_r5.desc);
} }
function CategoriesResultsComponent_section_2_ng_container_1_div_5_Template(rf, ctx) { if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 10);
    i0.ɵɵpipe(1, "indexOf");
    i0.ɵɵlistener("click", function CategoriesResultsComponent_section_2_ng_container_1_div_5_Template_div_click_0_listener() { i0.ɵɵrestoreView(_r4); const ctx_r2 = i0.ɵɵnextContext(3); return i0.ɵɵresetView(ctx_r2.clickResult()); })("mouseenter", function CategoriesResultsComponent_section_2_ng_container_1_div_5_Template_div_mouseenter_0_listener() { const result_r5 = i0.ɵɵrestoreView(_r4).$implicit; const ctx_r2 = i0.ɵɵnextContext(3); return i0.ɵɵresetView(ctx_r2.setSelectedIndexOnMouseEnter(result_r5)); });
    i0.ɵɵtemplate(2, CategoriesResultsComponent_section_2_ng_container_1_div_5_dt_2_Template, 1, 1, "dt", 11);
    i0.ɵɵelementStart(3, "dd", 12);
    i0.ɵɵtext(4);
    i0.ɵɵtemplate(5, CategoriesResultsComponent_section_2_ng_container_1_div_5_div_5_Template, 1, 1, "div", 13);
    i0.ɵɵelementEnd()();
} if (rf & 2) {
    const result_r5 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(7, _c0, i0.ɵɵpipeBind2(1, 4, ctx_r2.flatResults, result_r5) === ctx_r2.selectedIndex));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", result_r5.display_security_id);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1("", result_r5.name, " ");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", result_r5.desc);
} }
function CategoriesResultsComponent_section_2_ng_container_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 5);
    i0.ɵɵtext(2);
    i0.ɵɵtemplate(3, CategoriesResultsComponent_section_2_ng_container_1_a_3_Template, 1, 0, "a", 6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "div", 7);
    i0.ɵɵtemplate(5, CategoriesResultsComponent_section_2_ng_container_1_div_5_Template, 6, 9, "div", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const category_r2 = i0.ɵɵnextContext().$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r2.CATEGORY_LABELS[category_r2], " ");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.showMoreResultsUrl);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngForOf", ctx_r2.results[category_r2]);
} }
function CategoriesResultsComponent_section_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "section", 3);
    i0.ɵɵtemplate(1, CategoriesResultsComponent_section_2_ng_container_1_Template, 6, 3, "ng-container", 4);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const category_r2 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.results && ctx_r2.results[category_r2] && ctx_r2.results[category_r2].length > 0);
} }
function CategoriesResultsComponent_Conditional_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "ycn-extra-links", 2);
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("autocompleterId", ctx_r2.autocompleterId);
} }
/**
 *
 * Categories Result Component
 *
 * This component is responsible for:
 *   1. Displaying results returned from the backend
 *   2. Handling when a result is clicked and hovered
 *
 * Input Properties:
 *   showMoreResultsUrl: Whether to show the more results icon
 *   dropdownMenuClass: Add class to dropdown component
 *   globalSearch: is global search (top nav search)
 *
 */
export class CategoriesResultsComponent extends SearchResultsBaseDirective {
    constructor(changeDetector, eltRef, urlsService, store) {
        super(urlsService, store);
        this.changeDetector = changeDetector;
        this.eltRef = eltRef;
        this.store = store;
        this.globalSearch = false;
        this.flatResults = [];
        this.results = {};
        this.selectedIndex = -1;
        this.showResults = false;
        this.mapStateToComponent = (state) => {
            const autocompleterState = state[this.autocompleterId];
            if (!autocompleterState) {
                return;
            }
            const { inputText, categoriesResults, results, selectedIndex, showResults, extraLinks } = autocompleterState;
            this.inputText = inputText;
            this.results = categoriesResults;
            this.flatResults = results;
            this.selectedIndex = selectedIndex;
            this.showResults = showResults;
            const detectWhenGlobalNoResults = this.globalSearch && this.inputText.trim() !== '' && !this.showResults;
            // Disable change detection when results are hidden
            if (this.showResults || extraLinks.length > 0 || detectWhenGlobalNoResults) {
                this.changeDetector.reattach();
                this.changeDetector.detectChanges();
            }
            else {
                this.changeDetector.detectChanges();
                this.changeDetector.detach();
            }
        };
        this.storeSubscription = this.store.select(AUTOCOMPLETER_STATE_SLICE_KEY).subscribe(this.mapStateToComponent);
    }
    ngOnInit() {
        if (this.dropdownMenuClass)
            this.eltRef.nativeElement.classList.add(this.dropdownMenuClass);
    }
    ngOnDestroy() {
        this.storeSubscription.unsubscribe();
    }
    setSelectedIndexOnMouseEnter(result) {
        // changes selected index on mouseover so highlighted item can be submitted via enter or submit.
        const selectedIndex = this.flatResults.indexOf(result);
        this.setSelectedIndex(selectedIndex);
    }
}
CategoriesResultsComponent.ɵfac = function CategoriesResultsComponent_Factory(t) { return new (t || CategoriesResultsComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.UrlsService), i0.ɵɵdirectiveInject(i2.Store)); };
CategoriesResultsComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: CategoriesResultsComponent, selectors: [["ycn-categories-results"]], inputs: { showMoreResultsUrl: "showMoreResultsUrl", dropdownMenuClass: "dropdownMenuClass", globalSearch: "globalSearch" }, features: [i0.ɵɵInheritDefinitionFeature], decls: 4, vars: 2, consts: [["tabindex", "-1", 3, "mouseenter", "mouseleave"], ["class", "dropdown-section", 4, "ngFor", "ngForOf"], [3, "autocompleterId"], [1, "dropdown-section"], [4, "ngIf"], [1, "dropdown-header"], ["class", "btn btn-transparent arrow-right", "href", "javascript:void(0)", 3, "click", 4, "ngIf"], [1, "dropdown-section-body"], ["class", "dropdown-item", 3, "ngClass", "click", "mouseenter", 4, "ngFor", "ngForOf"], ["href", "javascript:void(0)", 1, "btn", "btn-transparent", "arrow-right", 3, "click"], [1, "dropdown-item", 3, "click", "mouseenter", "ngClass"], ["class", "dropdown-item-term", 3, "textContent", 4, "ngIf"], [1, "dropdown-item-text"], ["class", "dropdown-item-descr", 3, "textContent", 4, "ngIf"], [1, "dropdown-item-term", 3, "textContent"], [1, "dropdown-item-descr", 3, "textContent"]], template: function CategoriesResultsComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵlistener("mouseenter", function CategoriesResultsComponent_Template_div_mouseenter_0_listener() { return ctx.setShouldBlur(false); })("mouseleave", function CategoriesResultsComponent_Template_div_mouseleave_0_listener() { return ctx.setShouldBlur(true); });
        i0.ɵɵelementStart(1, "div");
        i0.ɵɵtemplate(2, CategoriesResultsComponent_section_2_Template, 2, 1, "section", 1)(3, CategoriesResultsComponent_Conditional_3_Template, 1, 1, "ycn-extra-links", 2);
        i0.ɵɵelementEnd()();
    } if (rf & 2) {
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngForOf", ctx.CATEGORY_ORDER);
        i0.ɵɵadvance();
        i0.ɵɵconditional(3, ctx.globalSearch ? 3 : -1);
    } }, dependencies: [i3.NgClass, i3.NgForOf, i3.NgIf, i4.ExtraLinksComponent, i5.IndexOfPipe], encapsulation: 2 });
