import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../features/directives/icon/icon.directive";
import * as i3 from "../../../../../../../apps/sales_support/static/sales_support/ts/features/sales-rep/sales-rep.component";
import * as i4 from "../../../../../../../apps/sales_support/static/sales_support/ts/features/support-form/support-form.component";
function YC500PageComponent_p_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtext(1, " We\u2019re sorry, but we seem to be having an internal error. Please let us know what happened so we can help. ");
    i0.ɵɵelementEnd();
} }
function YC500PageComponent_p_6_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtext(1, " Thank you for reaching out, someone should be contacting you shortly. ");
    i0.ɵɵelementEnd();
} }
function YC500PageComponent_div_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div")(1, "div", 4)(2, "a", 5);
    i0.ɵɵtext(3, "Contact Us");
    i0.ɵɵelementEnd()();
    i0.ɵɵelement(4, "hr");
    i0.ɵɵelementStart(5, "p")(6, "a", 6)(7, "span");
    i0.ɵɵtext(8, "Visit Support Topics");
    i0.ɵɵelementEnd();
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(9, "svg", 7);
    i0.ɵɵelementEnd()()();
} }
function YC500PageComponent_div_8_ycn_support_form_1_Template(rf, ctx) { if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "ycn-support-form", 9);
    i0.ɵɵlistener("submitHandler", function YC500PageComponent_div_8_ycn_support_form_1_Template_ycn_support_form_submitHandler_0_listener() { i0.ɵɵrestoreView(_r1); const ctx_r1 = i0.ɵɵnextContext(2); return i0.ɵɵresetView(ctx_r1.formSubmitHandler()); });
    i0.ɵɵelementEnd();
} }
function YC500PageComponent_div_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, YC500PageComponent_div_8_ycn_support_form_1_Template, 1, 0, "ycn-support-form", 8);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.isFormSubmitted);
} }
function YC500PageComponent_div_9_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelement(1, "hr")(2, "ycn-sales-rep");
    i0.ɵɵelementEnd();
} }
export class YC500PageComponent {
    constructor() {
        this.USER = window.USER;
        this.isFormSubmitted = false;
    }
    formSubmitHandler() {
        this.isFormSubmitted = true;
    }
}
YC500PageComponent.ɵfac = function YC500PageComponent_Factory(t) { return new (t || YC500PageComponent)(); };
YC500PageComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: YC500PageComponent, selectors: [["ycn-500-page"]], decls: 10, vars: 5, consts: [[1, "page", "page-error"], [1, "container", "justify-content-center", "align-items-center"], [1, "panel"], [4, "ngIf"], [1, "form-row"], ["href", "/contact_redirect", 1, "btn", "btn-primary"], ["href", "/support", 1, "page-error-link"], ["ycnIcon", "", "name", "icon-right-arrow"], ["placeholder", "Tell us what happened...", "emailSubject", "500 Error Page Support Form", "requestType", "error", 3, "submitHandler", 4, "ngIf"], ["placeholder", "Tell us what happened...", "emailSubject", "500 Error Page Support Form", "requestType", "error", 3, "submitHandler"]], template: function YC500PageComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div", 2)(3, "h1");
        i0.ɵɵtext(4, "Something Went Wrong");
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(5, YC500PageComponent_p_5_Template, 2, 0, "p", 3)(6, YC500PageComponent_p_6_Template, 2, 0, "p", 3)(7, YC500PageComponent_div_7_Template, 10, 0, "div", 3)(8, YC500PageComponent_div_8_Template, 2, 1, "div", 3)(9, YC500PageComponent_div_9_Template, 3, 0, "div", 3);
        i0.ɵɵelementEnd()()();
    } if (rf & 2) {
        i0.ɵɵadvance(5);
        i0.ɵɵproperty("ngIf", !ctx.isFormSubmitted);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.isFormSubmitted);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", !ctx.USER.authenticated);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.USER.authenticated);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.USER.authenticated && ctx.USER.features.includes("dedicatedSupport"));
    } }, dependencies: [i1.NgIf, i2.IconDirective, i3.SalesRepComponent, i4.SupportFormComponent], encapsulation: 2 });
