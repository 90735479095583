// Vendor libraries
import { BrowserModule } from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';
// Module
import { BaseAutocompleteModule } from 'SearchApp/ts/features/base-autocomplete/base-autocomplete.module';
import { ConfigModule } from 'NewAngular/config/config.module';
import { NavigationModule } from 'NewAngular/features/components/navigation/navigation.module';
import { SalesRepModule } from 'SalesSupportApp/ts/features/sales-rep/sales-rep.module';
import { SupportFormModule } from 'SalesSupportApp/ts/features/support-form/support-form.module';
// Component
import { TopBarNavComponent } from 'NewAngular/features/components/navigation/top-bar-nav/top-bar-nav.component';
import { IconModule } from 'NewAngular/features/directives/icon/icon.module';
import { ErrorAppComponent } from 'NewAngular/apps/error-app/components/error-app.component';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class ErrorPageModule {
}
ErrorPageModule.ɵfac = function ErrorPageModule_Factory(t) { return new (t || ErrorPageModule)(); };
ErrorPageModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: ErrorPageModule, bootstrap: [TopBarNavComponent, ErrorAppComponent] });
ErrorPageModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [BaseAutocompleteModule,
        BrowserModule,
        ConfigModule,
        IconModule,
        NavigationModule,
        SalesRepModule,
        SupportFormModule,
        StoreModule.forRoot({})] });
