import { CookieService } from 'ngx-cookie-service';
import * as i0 from "@angular/core";
import * as i1 from "ngx-cookie-service";
import * as i2 from "@angular/common";
function UserMenuComponent_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3)(1, "a", 4);
    i0.ɵɵtext(2, " Subscribe Now ");
    i0.ɵɵelementEnd()();
} if (rf & 2) {
    i0.ɵɵadvance();
    i0.ɵɵproperty("href", "/entitlements/unpaid_trial_wall", i0.ɵɵsanitizeUrl);
} }
function UserMenuComponent_a_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "a", 5);
    i0.ɵɵelement(1, "div", 6);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const option_r1 = ctx.$implicit;
    i0.ɵɵproperty("href", option_r1.href, i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance();
    i0.ɵɵproperty("textContent", option_r1.title);
} }
/**
 *
 * UserMenu Component
 *
 */
export class UserMenuComponent {
    constructor(cookieService) {
        this.cookieService = cookieService;
        this.USER = window.USER;
        this.menuOptions = [];
    }
    ngOnInit() {
        const nextLogoutLink = `/logout?next=${window.location.pathname}`;
        const nextLoginLink = `/login?next=${window.location.pathname}`;
        const authenticatedOptions = [
            {
                title: 'Account Settings',
                href: '/accounts/account_settings/account_basics',
            },
            {
                title: 'Return to Admin User',
                href: '/accounts/return_to_admin_user',
                includeCondition: () => this.cookieService.get('ADMIN_USER_SESSION_KEY'),
            },
            {
                title: 'Sign Out',
                href: nextLogoutLink,
            },
        ];
        const unauthenticatedOptions = [
            {
                title: 'Free Trial',
                href: '/start_trial',
            },
            {
                title: 'Sign In',
                href: nextLoginLink,
            },
        ];
        if (this.USER.authenticated) {
            authenticatedOptions.forEach((option) => {
                if (!option.includeCondition || option.includeCondition()) {
                    this.menuOptions.push(option);
                }
            });
        }
        else {
            unauthenticatedOptions.forEach((option) => this.menuOptions.push(option));
        }
    }
}
UserMenuComponent.ɵfac = function UserMenuComponent_Factory(t) { return new (t || UserMenuComponent)(i0.ɵɵdirectiveInject(i1.CookieService)); };
UserMenuComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: UserMenuComponent, selectors: [["ycn-user-menu"]], decls: 3, vars: 2, consts: [[1, "dropdown-menu", "dropdown-menu-right"], ["class", "dropdown-item btn-wrap", 4, "ngIf"], ["class", "dropdown-item", 3, "href", 4, "ngFor", "ngForOf"], [1, "dropdown-item", "btn-wrap"], [1, "btn", "btn-block", "btn-success", 3, "href"], [1, "dropdown-item", 3, "href"], [1, "dropdown-item-text", 3, "textContent"]], template: function UserMenuComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, UserMenuComponent_div_1_Template, 3, 1, "div", 1)(2, UserMenuComponent_a_2_Template, 2, 2, "a", 2);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", !!ctx.USER.daysRemainingInTrial);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngForOf", ctx.menuOptions);
    } }, dependencies: [i2.NgForOf, i2.NgIf], encapsulation: 2 });
