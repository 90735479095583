// Vendor libraries
import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../directives/icon/icon.directive";
function SimpleModalHeaderComponent_div_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "div", 9);
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("textContent", ctx_r0.header);
} }
function SimpleModalHeaderComponent_div_2_Template(rf, ctx) { if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 10)(1, "a", 11);
    i0.ɵɵlistener("click", function SimpleModalHeaderComponent_div_2_Template_a_click_1_listener() { i0.ɵɵrestoreView(_r2); const ctx_r0 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r0.back.emit()); });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(2, "svg", 12);
    i0.ɵɵnamespaceHTML();
    i0.ɵɵelementStart(3, "span");
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd()()();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("name", "icon-back");
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r0.header);
} }
function SimpleModalHeaderComponent_span_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 13);
    i0.ɵɵtext(1, " \u00A0(edited) ");
    i0.ɵɵelementEnd();
} }
function SimpleModalHeaderComponent_div_5_span_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "span", 16);
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("textContent", ctx_r0.resetModalText);
} }
function SimpleModalHeaderComponent_div_5_Template(rf, ctx) { if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 6)(1, "div", 14);
    i0.ɵɵlistener("click", function SimpleModalHeaderComponent_div_5_Template_div_click_1_listener() { i0.ɵɵrestoreView(_r3); const ctx_r0 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r0.resetModalHandler.emit()); });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(2, "svg", 8);
    i0.ɵɵtemplate(3, SimpleModalHeaderComponent_div_5_span_3_Template, 1, 1, "span", 15);
    i0.ɵɵelementEnd()();
} if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("name", "icon-reset");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.resetModalText);
} }
/**
 *
 * Simple Modal Header Component
 *
 * Input Properties:
 *
 *  header: The text to use for the header
 *  resetModalText: The text to use for the reset button
 *  showUnsavedStatus: Whether or not to show '(edited)' in the header
 *  selectedObject: A RadioButton (no label necessary) representing the selected value.
 *
 *
 * Output Properties:
 *
 *  closeModalHandler: EventEmitter that emits when the user hits the X button
 *  resetModalHandler: EventEmitter that emits when the user hits the reset button
 *
 */
export class SimpleModalHeaderComponent {
    constructor() {
        this.showBackButton = false;
        this.closeModalHandler = new EventEmitter();
        this.resetModalHandler = new EventEmitter();
        this.back = new EventEmitter();
        this.showCloseModalHandler = false;
        this.showResetModalHandler = false;
    }
    ngOnInit() {
        this.showCloseModalHandler = this.closeModalHandler.observers.length > 0;
        this.showResetModalHandler = this.resetModalHandler.observers.length > 0;
    }
}
SimpleModalHeaderComponent.ɵfac = function SimpleModalHeaderComponent_Factory(t) { return new (t || SimpleModalHeaderComponent)(); };
SimpleModalHeaderComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: SimpleModalHeaderComponent, selectors: [["ycn-simple-modal-header"]], inputs: { header: "header", showBackButton: "showBackButton", resetModalText: "resetModalText", showUnsavedStatus: "showUnsavedStatus" }, outputs: { closeModalHandler: "closeModalHandler", resetModalHandler: "resetModalHandler", back: "back" }, decls: 9, vars: 5, consts: [[1, "modal-header", "user-select-none"], ["class", "modal-title", 3, "textContent", 4, "ngIf"], ["class", "modal-title", 4, "ngIf"], ["class", "modal-title text-blue", 4, "ngIf"], [1, "modal-header-actions"], ["class", "modal-header-action", 4, "ngIf"], [1, "modal-header-action"], ["data-test-id", "simple-modal-header-close", "aria-label", "Close", 1, "close", "btn", "btn-modal-header", 3, "click"], ["ycnIcon", "", 3, "name"], [1, "modal-title", 3, "textContent"], [1, "modal-title"], ["href", "javascript:void(0);", 1, "d-flex", "align-items-center", 3, "click"], ["ycnIcon", "", 1, "mr-1", 3, "name"], [1, "modal-title", "text-blue"], ["aria-label", "Reset", 1, "btn", "btn-modal-header", 3, "click"], [3, "textContent", 4, "ngIf"], [3, "textContent"]], template: function SimpleModalHeaderComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, SimpleModalHeaderComponent_div_1_Template, 1, 1, "div", 1)(2, SimpleModalHeaderComponent_div_2_Template, 5, 2, "div", 2)(3, SimpleModalHeaderComponent_span_3_Template, 2, 0, "span", 3);
        i0.ɵɵelementStart(4, "div", 4);
        i0.ɵɵtemplate(5, SimpleModalHeaderComponent_div_5_Template, 4, 2, "div", 5);
        i0.ɵɵelementStart(6, "div", 6)(7, "div", 7);
        i0.ɵɵlistener("click", function SimpleModalHeaderComponent_Template_div_click_7_listener() { return ctx.closeModalHandler.emit(); });
        i0.ɵɵnamespaceSVG();
        i0.ɵɵelement(8, "svg", 8);
        i0.ɵɵelementEnd()()()();
    } if (rf & 2) {
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", !ctx.showBackButton);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.showBackButton);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.showUnsavedStatus);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.showResetModalHandler);
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("name", "icon-close");
    } }, dependencies: [i1.NgIf, i2.IconDirective], encapsulation: 2 });
