// Services
import { UtilsService } from './utils.service';
import * as i0 from "@angular/core";
import * as i1 from "./utils.service";
// Only add URLs that are actually used from A9 components / services
export class UrlsService {
    constructor(utilsService) {
        this.utilsService = utilsService;
        this.ACCOUNTS = {
            CLIENT_GROUP_PREFERENCES: '/accounts/client_group_preferences',
            CLIENT_GROUP_REPORT_PREVIEW: '/accounts/client_group_preferences/preview',
            RESET_CLIENT_GROUP_PREFERENCES: '/accounts/client_group_preferences/reset_preferences',
            REFER_FRIEND: '/accounts/refer_friends',
            USER_PREFERENCES: '/accounts/preferences',
            // Account Settings URLs
            ACCOUNT_BASICS: '/accounts/account_settings/account_basics',
            CHANGE_PASSWORD: '/accounts/account_settings/change_password',
            CHANGE_EMAIL: '/accounts/account_settings/change_email',
            BILLING_INFO: '/accounts/account_settings/billing_info/',
            INTEGRATIONS: '/accounts/account_settings/integrations/',
            MEMBERSHIP: '/accounts/account_settings/membership/',
            MFA_BACKUP_TOKENS: '/accounts/mfa/backup_tokens/',
            MFA_DEVICE_COLLECTION: '/accounts/account_settings/mfa_devices',
            MFA_DEVICE_MANAGEMENT: '/accounts/account_settings/account_basics/manage_mfa',
            MFA_DEVICE_SETUP: '/accounts/mfa/setup/{{ device_type }}',
            REPORT_HISTORY: '/accounts/account_settings/report_history/',
            USER_PREFERENCE_SETTINGS: '/accounts/account_settings/user_preferences',
            REPORT_DISPLAY_SETTINGS: '/accounts/account_settings/report_display',
            SHARE_GROUPS_PAGE: '/accounts/account_settings/share_groups',
            SHARE_GROUPS_COLLECTION: '/accounts/share_groups/',
            SHARE_GROUPS: '/accounts/share_groups/{{ shareGroupId }}/',
            SHARE_GROUPS_MEMBERSHIPS: '/accounts/share_groups/{{ shareGroupId }}/memberships',
            SHARE_GROUP_ROLES: '/accounts/share_groups/roles/',
        };
        this.AI_CHAT = {
            CHAT: '/ai_chat/#/',
            PROMPT_SUGGESTIONS: '/ai_chat/prompt_suggestions',
            CREATE_CONVERSATION: '/ai_chat/conversation',
            CONVERSATION: '/ai_chat/conversation/{{ conversationId }}',
            EDIT_CONVERSATION: '/ai_chat/edit_conversation',
            CONVERSATION_HISTORY: '/ai_chat/conversation_history',
            DISCLOSURE: '/ai_chat/disclosure',
        };
        this.ALERTS = {
            ALERT: '/alerts/{{ alertType }}/{{ objectType }}/{{ alertId }}',
            ALERT_COLLECTION: '/alerts/{{ alertType }}/{{ objectType }}',
            ALERT_GROUP: '/alerts/group/{{ objectType }}/{{ objectId }}',
            ALERT_GROUP_COLLECTION: '/alerts/',
            ALERT_MESSAGE_COLLECTION: '/alerts/alert_messages',
            ALERT_MESSAGE: '/alerts/alert_message/{{ messageId }}',
            ALERT_NOTE_COLLECTION: '/alerts/notes',
            ALERT_NOTE: '/alerts/notes/{{ id }}',
            DISABLE_ALERT: '/alerts/{{ alertType }}/{{ objectType }}/{{ alertId }}/preference',
            ENABLE_ALERT: '/alerts/preference/{{ disabledAlertPreferenceId }}',
            LIST: '/alerts/list/',
        };
        this.API = {
            API_KEY: '/api/keys/', // pragma: allowlist secret
        };
        this.AWKWARD = {
            // This is actually a tracking endpoint. The weird URL is to prevent adblockers
            // from blocking requests to it.
            NAMING: '/awkward/naming',
        };
        this.CALCULATIONS = {
            CALC_INFO: '/calculations/calc_info',
            METRIC_RANGE_INFO: '/calculations/range_data/{{ securityGroup }}/{{ metrics }}',
            METRIC_SET_COLLECTION: '/calculations/metric_sets',
            METRIC_SET: '/calculations/metric_sets/{{ id }}',
            SAVED_SCORE_COLLECTION: '/calculations/saved_scores',
            SAVED_SCORE: '/calculations/saved_scores/{{ scoreId }}',
        };
        this.CHARTS = {
            CHART_VIEW_COLLECTION: '/charts/chart_views/{{ chartType }}',
            CHART_VIEW: '/charts/chart_views/{{ chartType }}/{{ id }}',
            FUND_CHART_PAGE: '/charts/fundamental_chart/',
            FUND_CHART_CALC: '/charts/fund_chart_metric',
            FUND_CHART_DATA_JSON: '/charts/fund_data.json',
            FUND_CHART_DATA: {
                json: '/charts/fund_data.json',
                csv: '/charts/fund_data.csv',
            },
            SECURITY_METRIC_CHARTABLE: '/charts/security_calc_chartable?securityId={{ security_id }}&calc={{ metric }}',
            SECURITY_METRIC_LABELS_BULK: '/charts/security_calc_labels_bulk?securityId={{ security_id }}',
            TREEMAP_CHART_DATA: '/charts/treemap_data/',
            TECH_CHART_PAGE: '/charts/technical_chart/',
            TECH_CHART_DATA: {
                json: '/charts/tech_data.json',
                csv: '/charts/tech_data.csv',
            },
        };
        this.COMPLIANCE = {
            DOWNLOAD_REPORT: '/compliance/download_report/{{ complianceReportId }}',
            COLLECTION: '/compliance/reports',
        };
        this.CLASSIFICATIONS = {
            ALL_SECTORS_DATA_VIEW: '/classifications/sectors.json',
            INDUSTRIES_IN_SECTOR_DATA_VIEW: '/classifications/sectors/{{ sectorBaseName }}/industries.json',
            INDUSTRIES_IN_SECTOR_EXPORT: '/classifications/sectors/{{ sectorBaseName }}/industries.xlsx',
            INDUSTRY_EVENTS: '/classifications/industries/{{ industryBaseName }}/events/1',
            INDUSTRY_KEY_STATS_EXPORT: '/classifications/industries/{{ industryBaseName }}/key_stats.xlsx',
            INDUSTRY_OVERVIEW_EVENTS_DATA_VIEW: '/classifications/industries/{{ industryBaseName }}/overview/events.json',
            SECTORS_OVERVIEW_DATA_EXPORT: '/classifications/sectors.xlsx',
            SECTOR_KEY_STATS_EXPORT: '/classifications/sectors/{{ sectorBaseName }}/key_stats.xlsx',
            STOCKS_IN_INDUSTRY_DATA_VIEW: '/classifications/industries/{{ industryBaseName }}/stocks.json',
            STOCKS_IN_INDUSTRY_EXPORT: '/classifications/industries/{{ industryBaseName }}/stocks.xlsx',
        };
        this.CUSTOM_PDF_REPORTS = {
            COLLECTION: '/custom_pdf_reports/',
            DUPLICATE: '/custom_pdf_reports/duplicate/{{ reportId }}',
            EDIT: '/custom_pdf_reports/builder/?reportId={{ reportId }}',
            LIST: '/custom_pdf_reports/list_collection',
            LIST_PAGE: '/custom_pdf_reports/list/',
            MODULE_MENU: '/custom_pdf_reports/module_category_menu/{{ reportType }}',
            NEW: '/custom_pdf_reports/builder/?reportId=',
            PDF_REPORT: '/custom_pdf_reports/{{ reportId }}',
            PDF_TO_IMAGE: '/custom_pdf_reports/pdf_to_image',
            PREVIEW_IMAGE: '/custom_pdf_reports/module_preview_image/{{ reportType }}',
            PREVIEW_PDF_REPORT: '/custom_pdf_reports/{{ reportId }}/preview',
            REORIENT_PDF: '/custom_pdf_reports/repaginate_report',
            TALKING_POINT_PREVIEW_IMAGE: '/custom_pdf_reports/talking_point_preview_image',
            TEMPLATES: '/custom_pdf_reports/templates',
            VALIDATE_MODULE: '/custom_pdf_reports/validate_module/{{ reportType }}',
            VALIDATE_SECURITY_LIST: '/custom_pdf_reports/validate_list/{{ reportId }}/{{ securityListName }}/{{ securityGroup }}/{{ isOnePageReport }}',
            VALIDATE_SECURITIES: '/custom_pdf_reports/validate_report_securities/{{ reportId }}',
            TEARSHEET_COLLECTION: '/custom_pdf_reports/tearsheet_collection',
            UNSAVED_REPORT_GENERATION: '/custom_pdf_reports/generate',
            VALIDATE_DATA_SOURCE: '/custom_pdf_reports/validate_data_source',
            WIZARD_STEPS: '/custom_pdf_reports/wizard_steps',
        };
        this.COMPANIES = {
            STOCKS_PAGE_EVENTS: '/companies/stocks/events',
            STOCKS_PAGE_GAINERS_LOSERS: '/companies/stocks/gainers_losers',
            STOCKS_PAGE_INDEX_PERFORMANCE: '/companies/stocks/index_performance',
            STOCKS_PAGE_INDICES: '/companies/stocks/indices',
            STOCKS_PAGE_INDICES_TABS: '/companies/stocks/indices_tabs',
            STOCKS_PAGE_NEWS: '/companies/stocks/news',
            STOCKS_PAGE_SECTORS: '/companies/stocks/sectors',
        };
        this.CUSTOM_SECURITIES = {
            COLLECTION: '/custom_securities/',
            CUSTOM_SECURITY: '/custom_securities/{{ customSecurityId }}',
            CUSTOM_SECURITY_COLLECTION_STATUS: '/custom_securities/collection_status',
            CUSTOM_SECURITY_STATUS: '/custom_securities/{{ customSecurityId }}/status',
            DATA_EXPORT: '/custom_securities/{{ customSecurityId }}/data',
            DOWNLOAD_SOURCE_FILE: '/custom_securities/{{ customSecurityId }}/latest_file_download',
            DOWNLOAD_TEMPLATE: '/custom_securities/download_template',
            DUPLICATE_CUSTOM_SECURITY: '/custom_securities/{{ customSecurityId }}/duplicate',
            EDIT: '/custom_securities/{{ customSecurityId }}/edit/',
            LIST: '/custom_securities/list/',
            NEW: '/custom_securities/new/',
            VALUES_UPLOAD: '/custom_securities/values_upload',
            QUOTE: '/custom_securities/{{ customSecurityId }}/quote',
            UPDATE_AUTO_REFRESH_FREQUENCY: '/custom_securities/auto_refresh_frequency',
        };
        this.DASHBOARD = {
            // Dashboard, Tab, and Module Views
            COLLECTION: '/dashboard/dashboard_collection',
            DASHBOARD: '/dashboard/{{ dashboardId }}',
            LAYOUT: '/dashboard/{{ dashboardId }}/layout',
            MODULE: '/dashboard/{{ moduleId }}/{{ moduleType }}/module',
            MODULE_VIEW: '/dashboard/{{ moduleId }}/{{ moduleType }}/module_view',
            TAB: '/dashboard/{{ dashboardTabId }}/tab',
            TAB_COLLECTION: '/dashboard/tabs',
            // Module Data Views
            CUSTOM_TEXT_MODULE_DATA: '/dashboard/custom_text_data/{{ moduleId }}',
            FUND_CHART_MODULE_DATA: '/dashboard/fund_chart_module_data/{{ fundChartId }}',
            TECH_CHART_MODULE_DATA: '/dashboard/tech_chart_module_data/{{ techChartId }}',
            HEATMAP_MODULE_DATA: '/dashboard/heatmap_data/{{ moduleId }}',
            INDICATOR_LIST_MODULE_OVERVIEW: '/dashboard/indicator_list_module_overview/{{ securityListName }}/',
            INDICATOR_LIST_MODULE_EXPORT: '/dashboard/indicator_list_module_export/{{ securityListName }}/',
            SCATTER_PLOT_MODULE_DATA: '/dashboard/scatter_plot_module_data/{{ scatterPlotId }}',
            SECURITY_LIST_MODULE_DATA: '/dashboard/security_list_module_data/{{ securityListName }}/{{ securityGroup }}',
            SECURITY_LIST_MODULE_DIVIDENDS_EVENTS: '/dashboard/security_list_module_events/dividends/{{ securityListName }}/{{ securityGroup }}',
            SECURITY_LIST_MODULE_EARNINGS_EVENTS: '/dashboard/security_list_module_events/earnings/{{ securityListName }}/{{ securityGroup }}',
            SECURITY_LIST_MODULE_HOLDINGS: '/dashboard/security_list_module_holdings/{{ securityListName }}/{{ securityGroup }}',
            SECURITY_LIST_MODULE_ACCOUNT: '/dashboard/security_list_module_account/{{ securityListName }}/{{ securityGroup }}',
            SECURITY_LIST_MODULE_HOLDINGS_OVERLAP: '/dashboard/security_list_module_holdings_overlap/{{ modelPortfolioId }}',
            SECURITY_LIST_MODULE_OTHER_EVENTS: '/dashboard/security_list_module_events/other/{{ securityListName }}/{{ securityGroup }}',
            SECURITY_LIST_MODULE_OVERVIEW: '/dashboard/security_list_module_overview/{{ securityListName }}/{{ securityGroup }}',
            SECURITY_LIST_MODULE_SPLIT_EVENTS: '/dashboard/security_list_module_events/split_spinoff/{{ securityListName }}/{{ securityGroup }}',
            SECURITY_LIST_MODULE_EXPORT: '/dashboard/security_list_module_export/{{ securityListName }}/{{ securityGroup }}',
        };
        this.DATA_TEMPLATES = {
            DATA_TEMPLATE: '/data_templates/data_template/{{ templateType }}/{{ templateId }}',
            DATA_TEMPLATE_LIBRARY: '/data_templates/library_menu/{{ templateType }}',
        };
        this.DISCLOSURES = {
            FUND_CHART_DISCLOSURE: '/disclosures/fund_chart',
            SCATTER_PLOT_DISCLOSURE: '/disclosures/scatter_plot',
            TECH_CHART_DISCLOSURE: '/disclosures/tech_chart',
        };
        this.EMAIL_REPORTS = {
            COLLECTION: '/email_reports/',
            DUPLICATE_EMAIL_REPORT: '/email_reports/{{ customEmailReportId }}/duplicate',
            EMAIL_REPORT: '/email_reports/{{ customEmailReportId }}',
            LIST_PAGE: '/email_reports/list/',
            MODULE_PREVIEW: '/email_reports/module_preview',
            MODULE_MENU: '/email_reports/module_category_menu',
            EMAIL_REPORT_EDIT: '/email_reports/builder/',
            EMAIL_REPORT_SAVED_PREVIEW: '/email_reports/{{ customEmailReportId }}/preview',
        };
        this.EMBEDS = {
            FUND_CHART: '/generate_fund_chart?mode={{ mode }}',
            SCATTER_PLOT_CHART: '/generate_scatter_plot_chart',
            TECH_CHART: '/generate_tech_chart_redesign?mode={{ mode }}',
            SAVE_AS_IMAGE_FUND_CHART: '/embeds/save_fund_chart_as_image',
            SAVE_AS_IMAGE_SCATTER_PLOT_CHART: '/embeds/save_scatter_plot_as_image',
            SAVE_AS_IMAGE_TECH_CHART_LEGACY: '/embeds/save_tech_chart_as_image_legacy?mode=image_attachment',
            SAVE_AS_IMAGE_TECH_CHART: '/embeds/save_tech_chart_as_image?mode=image_attachment',
            SCENARIO_CHART: '/generate_scenario_chart',
            SAVE_AS_SCENARIO_CHART: '/embeds/save_scenario_chart_as_image?mode=image_attachment',
        };
        this.ENTITLEMENTS = {
            CANCEL_SUBSCRIPTION: '/entitlements/cancel_subscription',
            CONTACT_US_FORM: '/entitlements/contact_us_form',
        };
        this.ESG = {
            NEWS: '/esg/news',
        };
        this.EVENTS_CALENDAR = {
            EVENTS_CALENDAR_DATA: '/events/events_data',
            EVENTS_CALENDAR_COUNTS: '/events/events_count',
        };
        this.EXCEL = {
            EXCEL_CENTER: '/excel',
            TEMPLATE_PREVIEW: '/excel/preview_excel_template/',
            TEMPLATES_INFO: '/excel/templates_info/',
        };
        this.FEATURE_NEEDED_POPUP = {
            GET_FEATURE: '/entitlements/feature_popup_json/{{ featureKey }}',
        };
        this.FOLDERS = {
            FOLDER: '/folders/{{ folderId }}',
            COLLECTION: '/folders/collection',
            FOLDER_ITEM: '/folders/{{ itemType }}/{{ itemId }}',
            FOLDERABLE_RENAME: '/folders/rename/{{ itemType }}/{{ itemId }}',
        };
        this.FUNDS_PAGE = {
            FUND_FLOW_PERFORMANCE_TABLE: '/funds/fund_flow_performance_table',
            FUND_CATEGORIES_INDEX: '/mutual_funds/category_index',
            FUND_FLOW_PERFORMANCE_TABLE_EXPORT: '/funds/fund_flow_performance_table.xlsx?fundType={{ fundType }}&countryCode={{ countryCode }}&fundDataViewType={{ fundDataViewType }}',
            PERFORMANCE_BY_BROAD_ASSET_CLASS: '/funds/performance_by_broad_asset_class',
            FUND_FLOW_PERFORMANCE_HEATMAP: '/funds/fund_flow_performance_top_bottom',
        };
        this.HYPOTHETICALS = {
            HYPOTHETICAL_DATA: '/scenarios/hypotheticals_data/',
            HYPOTHETICALS_COLLECTION: '/scenarios/hypotheticals/',
            HYPOTHETICALS_ITEM: '/scenarios/hypotheticals/{{ hypotheticalId }}',
            HYPOTHETICALS_PDF_REPORT: '/pdf_reports/scenarios',
            ANALYSIS_PLAN_DATA: '/scenarios/analysis_plan_data/',
            ANALYSIS_PLAN_COLLECTION: '/scenarios/analysis_plans/',
            ANALYSIS_PLAN_ITEM: '/scenarios/analysis_plans/{{ analysisPlanId }}',
            ANALYSIS_PLAN_PDF_REPORT: '/pdf_reports/analysis_plan',
        };
        this.INDICATORS = {
            INDICATORS_ALL_ECONOMIC_DATA: '/indicators/info/all_economic_data',
            INDICATORS_INFO_WATCHLIST: '/indicators/info/indicators_info_watchlist',
            INDICATOR_EVENT_COUNT_DATA_VIEW: '/indicators/info/indicator_event_count_calendar_data',
            INDICATOR_GEOGRAPHIES_DATA_VIEW: '/indicators/info/geographies/{{ regionType }}',
            INDICATOR_LIST_DATA_VIEW: '/indicators/info/indicator_calendar_data',
            INDICATOR_REGION_DATA_VIEW: '/indicators/info/region_data',
            REVIEW_NOTE: '/indicators/review/reports/{{ reportId }}/notes/{{ noteId }}',
            REVIEW_NOTE_COLLECTION: '/indicators/review/reports/{{ reportId }}/notes',
            REVIEW_REPORT: '/indicators/review/reports/{{ reportId }}',
            REVIEW_REPORT_COLLECTION: '/indicators/review/reports',
        };
        this.INDICES = {
            ASSET_CLASS_DATA: '/indices/asset_class/{{ asset_class }}/index_list',
            CONSTITUENTS_DATA: '/indices/{{ symbol }}/constituents_data',
            INDICES_FAMILY_PAGE_GAINERS_LOSERS: '/indices/families/gainers_losers',
            INDICES_FAMILY_DATA: '/indices/table_data',
        };
        this.INTEGRATIONS = {
            ACTIVE_INTEGRATIONS: '/integrations/collection/active',
            ADDEPAR_ENTITY_CHILDREN: '/integrations/addepar/{{ groupID }}/{{ entityID }}/entities',
            ADDEPAR_GROUP_MEMBERS: '/integrations/addepar/{{ groupID }}/entities',
            ADDEPAR_IMPORT_HOLDINGS: '/integrations/addepar/import_holdings/portfolio',
            ADDEPAR_INTEGRATION_STATUS: '/integrations/addepar/status',
            ADDEPAR_INTEGRATION_AUTH: '/integrations/oauth/addepar',
            ADDEPAR_SEARCH: '/integrations/addepar/search/{{ searchType }}',
            ADDEPAR_IMPORT_CUSTOM_SECURITY: '/integrations/addepar/import_custom_security',
            BLACK_DIAMOND_IMPORT_ACCOUNT_HOLDINGS: '/integrations/black_diamond/import_holdings/account',
            BLACK_DIAMOND_INTEGRATION_OAUTH: '/integrations/oauth/black_diamond',
            BLACK_DIAMOND_INTEGRATION_STATUS: '/integrations/black_diamond/status',
            BLACK_DIAMOND_SEARCH: '/integrations/black_diamond/search/{{ searchType }}',
            BLACK_DIAMOND_PORTFOLIO_ACCOUNTS: '/integrations/black_diamond/portfolio/{{ portfolioKey }}/account',
            BROADRIDGE_INTEGRATION_OAUTH: '/integrations/oauth/broadridge',
            BROADRIDGE_INTEGRATION_STATUS: '/integrations/broadridge/status',
            BROADRIDGE_SEARCH: '/integrations/broadridge/search/{{ searchType }}',
            BROADRIDGE_GROUP_ACCOUNTS: '/integrations/broadridge/group/{{ groupID }}/account',
            BROADRIDGE_IMPORT_ACCOUNT_HOLDINGS: '/integrations/broadridge/import_holdings/account',
            ORION_INTEGRATION_AUTH: '/integrations/oauth/orion',
            ORION_INTEGRATION_STATUS: '/integrations/orion/status',
            ORION_SEARCH: '/integrations/orion/search/{{ searchType }}',
            ORION_CLIENT_ACCOUNTS: '/integrations/orion/client/{{ clientId }}/accounts',
            ORION_IMPORT_HOLDINGS: '/integrations/orion/import_holdings/{{ searchType }}',
            PERSHING_SEARCH: '/integrations/pershing/search/{{ searchType }}',
            PERSHING_CLIENT_ACCOUNTS: '/integrations/pershing/client/{{ clientId }}/accounts',
            PERSHING_IMPORT_HOLDINGS: '/integrations/pershing/import_holdings/{{ importType }}',
            REDTAIL_INTEGRATION_AUTH: '/integrations/auth/redtail',
            REDTAIL_INTEGRATION_STATUS: '/integrations/redtail/status',
            REDTAIL_SEARCH: '/integrations/redtail/search/contacts',
            REDTAIL_CONTACT_ACCOUNTS: '/integrations/redtail/contact/{{ contactId }}/accounts',
            REDTAIL_IMPORT_HOLDINGS: '/integrations/redtail/import_holdings/{{ importType }}',
            IMPORTED_HOLDINGS: '/integrations/imported_holdings/{{ importedHoldingsId }}',
            IMPORTED_HOLDING_MAPPING: '/integrations/imported_holding_mapping/',
            IMPORTED_CUSTOM_SECURITY: '/integrations/imported_custom_security/{{ importedCustomSecurityId }}',
            IMPORTED_CUSTOM_SECURITY_REFRESH: '/integrations/imported_custom_security/{{ importedCustomSecurityId }}/refresh',
            SCHWAB_CLIENT_ACCOUNTS: '/integrations/schwab/client/{{ clientId }}/accounts',
            SCHWAB_IMPORT_HOLDINGS: '/integrations/schwab/import_holdings/{{ importType }}',
            SCHWAB_INTEGRATION_OAUTH: '/integrations/oauth/schwab',
            SCHWAB_INTEGRATION_STATUS: '/integrations/schwab/status',
            SCHWAB_PUSH_MODEL: '/integrations/schwab/push',
            SCHWAB_SEARCH: '/integrations/schwab/search/{{ searchType }}',
            SCHWAB_SSO_SAML: '/integrations/schwab_saml/',
            SSO_LINK_ACCOUNT: '/integrations/link/{{ ssoIdp }}/',
            PERSHING_INTEGRATION_STATUS: '/integrations/pershing/status',
            VRGL_INTEGRATION_STATUS: '/integrations/vrgl/status',
            VRGL_SEND_REQUEST_EMAIL: '/integrations/vrgl/send_request_email',
            VRGL_SEARCH: '/integrations/vrgl/search/{{ searchType }}',
            VRGL_HOUSEHOLD_ACCOUNTS: '/integrations/vrgl/household/{{ householdId }}/accounts',
            VRGL_HOUSEHOLD_PROPOSAL_ACCOUNTS: '/integrations/vrgl/household/{{ householdId }}/proposalAccounts',
            VRGL_IMPORT_HOLDINGS: '/integrations/vrgl/import_holdings/{{ importType }}',
            RAYMOND_JAMES_SEARCH: '/integrations/raymond_james/search',
            RAYMOND_JAMES_IMPORT_ACCOUNT_HOLDINGS: '/integrations/raymond_james/import_holdings/account',
            RAYMOND_JAMES_INTEGRATION_STATUS: '/integrations/raymond_james/status',
            RAYMOND_JAMES_ACTIVATE: '/integrations/raymond_james/activate',
            NORTHWESTERN_MUTUAL_CLIENT_ACCOUNT_SEARCH: '/integrations/northwestern_mutual/account_search',
            NORTHWESTERN_MUTUAL_CLIENT_SEARCH: '/integrations/northwestern_mutual/client_search',
            NORTHWESTERN_MUTUAL_IMPORT_ACCOUNT_HOLDINGS: '/integrations/northwestern_mutual/import_holdings/{{ importType }}',
            NORTHWESTERN_MUTUAL_STATUS: '/integrations/northwestern_mutual/status',
            AVISO_SEARCH: '/integrations/aviso/search',
            AVISO_IMPORT_ACCOUNT_HOLDINGS: '/integrations/aviso/import_holdings/account',
            AVISO_INTEGRATION_STATUS: '/integrations/aviso/status',
            AVISO_ACTIVATE: '/integrations/aviso/activate',
        };
        this.MODEL_PORTFOLIOS = {
            COLLECTION: '/model_portfolios/',
            DOWNLOAD_SOURCE_FILE: '/model_portfolios/{{ modelPortfolioId }}/latest_file_download',
            EDIT: '/model_portfolios/{{ modelPortfolioId }}/edit/',
            LIST: '/model_portfolios/list/',
            MODEL_PORTFOLIO: '/model_portfolios/{{ modelPortfolioId }}',
            DRAFT_MODEL_PORTFOLIO: '/model_portfolios/draft/{{ modelPortfolioId }}',
            QUOTE: '/model_portfolios/{{ modelPortfolioId }}/quote',
            MODEL_PORTFOLIO_ANCESTORS: '/model_portfolios/ancestors',
            MODEL_PORTFOLIO_COLLECTION_STATUS: '/model_portfolios/collection_status',
            MODEL_PORTFOLIO_DESCENDANTS: '/model_portfolios/{{ modelPortfolioId }}/descendants',
            MODEL_PORTFOLIO_STATUS: '/model_portfolios/{{ modelPortfolioId }}/status',
            MODEL_PORTFOLIO_UNSHARING_ANCESTORS: '/model_portfolios/{{ modelPortfolioId }}/sharing/impacted_ancestors',
            HOLDINGS_EXPORT: '/model_portfolios/{{ modelPortfolioId }}/holdings/csv',
            DELIST_CONVERSION_TEMPLATE_EXPORT: '/model_portfolios/{{ modelPortfolioId }}/delist_conversion_template/csv',
            TOP_PORTFOLIO_HOLDERS: '/model_portfolios/{{ securityType }}/{{ securityId }}/top_portfolio_holders/{{ page }}',
            RESET_MODEL_PORTFOLIO: '/model_portfolios/{{ modelPortfolioId }}/reset',
            MODEL_PORTFOLIO_ATTRIBUTE: '/model_portfolios/{{ modelPortfolioId }}/attribute',
            MODEL_PORTFOLIO_TRANSITIONED_HOLDINGS: '/model_portfolios/{{ baselinePortfolioId }}/transition_holdings',
            EXECUTE_TRANSITION_ANALYSIS: '/model_portfolios/transition_analysis/{{ transitionStrategy }}/{{ initialPortfolioId }}',
            EXPORT_TRANSITION_ANALYSIS: '/pdf_reports/transition_analysis',
            ARCHIVE_TRANSITION_ANALYSIS: '/pdf_reports/transition_analysis/archive',
            // These URLs don't exist yet, so they will not work until they are implemented in the backend
            HISTORICAL_PERFORMANCE_TEMPLATE: '/model_portfolios/historical_performance_template/',
            HISTORICAL_PERFORMANCE_VALIDATION: '/model_portfolios/historical_performance_validation',
            HISTORICAL_PERFORMANCE_UPLOAD: '/model_portfolios/{{ modelPortfolioId }}/historical_performance_upload',
            TRANSITION_ANALYSIS_LIST: '/model_portfolios/transition_analysis/list',
        };
        this.MODEL_PORTFOLIOS_FIXED = {
            COLLECTION: '/model_portfolios/fixed/',
            MODEL_PORTFOLIO: '/model_portfolios/fixed/{{ modelPortfolioId }}',
            HOLDINGS_TEMPLATE_MODEL_PORTFOLIO: '/model_portfolios/fixed/model_portfolio_holdings_template/model_portfolio',
            HOLDINGS_TEMPLATE_BENCHMARK: '/model_portfolios/fixed/model_portfolio_holdings_template/benchmark',
            HOLDINGS_TEMPLATE_CLIENT_PORTFOLIO: '/model_portfolios/fixed/model_portfolio_holdings_template/client',
            HOLDINGS_TEMPLATE_HOUSEHOLD_PORTFOLIO: '/model_portfolios/fixed/model_portfolio_holdings_template/household',
            HOLDINGS_UPLOAD_MODEL_PORTFOLIO: '/model_portfolios/fixed/holdings_upload/model_portfolio',
            HOLDINGS_UPLOAD_BENCHMARK: '/model_portfolios/fixed/holdings_upload/benchmark',
            HOLDINGS_UPLOAD_CLIENT_PORTFOLIO: '/model_portfolios/fixed/holdings_upload/client',
            HOLDINGS_UPLOAD_HOUSEHOLD_PORTFOLIO: '/model_portfolios/fixed/holdings_upload/household',
            NEW: '/model_portfolios/fixed/new/',
            NEW_BENCHMARK: '/model_portfolios/fixed/benchmark/new/',
            NEW_CLIENT_PORTFOLIO: '/model_portfolios/fixed/client/new/',
            DRAFT_COLLECTION: '/model_portfolios/draft/',
            DRAFT: '/model_portfolios/draft/{{ draftId }}',
            NEW_HOUSEHOLD_PORTFOLIO: '/model_portfolios/fixed/household/new/',
            UPDATE_AUTO_REFRESH_FREQUENCY: '/model_portfolios/fixed/auto_refresh_frequency/',
        };
        this.MODEL_PORTFOLIOS_DYNAMIC = {
            COLLECTION: '/model_portfolios/dynamic/',
            MODEL_PORTFOLIO: '/model_portfolios/dynamic/{{ modelPortfolioId }}',
            HOLDINGS_TEMPLATE: '/model_portfolios/dynamic/model_portfolio_holdings_template/',
            HOLDINGS_UPLOAD: '/model_portfolios/dynamic/model_portfolio_holdings_upload',
            NEW: '/model_portfolios/dynamic/new/',
        };
        this.NEWS = {
            ARTICLE_COLLECTION: '/news/articles',
            STORY: '/news/story/{{ articleId }}',
            AI_CHAT_QUERY_CHOICES: '/news/ai/chat/query_choices?securityId={{ securityId }}',
        };
        this.NOTES = {
            SECURITY_NOTE_COLLECTION: '/notes/{{ securityType }}/{{ securityId }}',
            ASSET_NOTE_COLLECTION: '/notes/{{ assetType }}/{{ assetId }}',
            NOTE_COLLECTION: '/notes/',
            NOTE_ITEM: '/notes/{{ noteId }}',
        };
        this.NOTIFICATIONS = {
            NOTIFICATION_VIEW: '/notifications/{{ notificationType }}/{{ notificationId }}',
            NOTIFICATION_COLLECTION: '/notifications/collection',
        };
        this.GLOSSARY = {
            GLOSSARY_CALC_TERM: '/glossary/glossary_terms/{{ calcName }}',
            GLOSSARY_DATA: '/glossary/data',
            GLOSSARY_HOME: '/glossary',
            GLOSSARY_TERM: '/glossary/terms/{{ term }}',
            GLOSSARY_TERM_DATA: '/glossary/term_data/{{ term }}',
        };
        this.GUIDED_WORKFLOWS = {
            COLLECTION: '/guided_workflows/page_list',
        };
        this.HISTORICAL_DATA = {
            COMPANY_EXPORT: '/companies/{{ securityId }}/{{ calcName }}.csv?startDate={{ startDate }}&endDate={{ endDate }}',
            COMPANY_TABLE: '/companies/{{ securityId }}/{{ calcName }}.json',
            CUSTOM_SECURITY_EXPORT: '/custom_securities/{{ securityId }}/{{ calcName }}.csv?startDate={{ startDate }}&endDate={{ endDate }}',
            CUSTOM_SECURITY_TABLE: '/custom_securities/{{ securityId }}/{{ calcName }}.json',
            INDEX_EXPORT: '/indices/{{ securityId }}/{{ calcName }}.csv?startDate={{ startDate }}&endDate={{ endDate }}',
            INDEX_TABLE: '/indices/{{ securityId }}/{{ calcName }}.json',
            INDICATOR_EXPORT: '/indicators/{{ securityId }}.csv?startDate={{ startDate }}&endDate={{ endDate }}',
            INDICATOR_TABLE: '/indicators/{{ securityId }}.json',
            MODEL_PORTFOLIO_EXPORT: '/model_portfolios/{{ securityId }}/{{ calcName }}.csv?startDate={{ startDate }}&endDate={{ endDate }}',
            MODEL_PORTFOLIO_TABLE: '/model_portfolios/{{ securityId }}/{{ calcName }}.json',
            MUTUAL_FUND_EXPORT: '/mutual_funds/{{ securityId }}/{{ calcName }}.csv?startDate={{ startDate }}&endDate={{ endDate }}',
            MUTUAL_FUND_TABLE: '/mutual_funds/{{ securityId }}/{{ calcName }}.json',
            SEPARATE_ACCOUNT_EXPORT: '/separate_accounts/{{ securityId }}/{{ calcName }}.csv?startDate={{ startDate }}&endDate={{ endDate }}',
            SEPARATE_ACCOUNT_TABLE: '/separate_accounts/{{ securityId }}/{{ calcName }}.json',
        };
        this.ORDWAY = {
            INTERNAL_SIGNUP_REDIRECT: '/ordway/internal_signup_redirect',
            INVOICE_INTERNAL_SIGNUP: '/ordway/invoice_internal_signup',
            ORDWAY_BILLING_CONTACT: '/ordway/contact/billing',
            ORDWAY_SHIPPING_CONTACT: '/ordway/contact/shipping',
            ORDWAY_PAYMENT_INFO: '/ordway/payment_info',
            STRIPE_CUSTOMER: '/ordway/stripe_customer',
            STRIPE_PAYMENT_METHOD: '/ordway/stripe_payment_method/{{ stripePaymentMethodId }}',
            STRIPE_SETUP_INTENT: '/ordway/stripe_setup_intent',
        };
        this.PASSKEYS = {
            COLLECTION: '/passkeys/list',
            PASSKEY: '/passkeys/{{ passkeyId }}',
            REGISTER: '/passkeys/register',
            LOGIN: '/passkeys/login',
        };
        this.PORTFOLIO_OPTIMIZER = {
            COLLECTION: '/portfolio_optimizer/saved_portfolio_optimizers/',
            COMPARISON_PORTFOLIO: '/portfolio_optimizer/comparison_portfolio_data',
            ITEM: '/portfolio_optimizer/saved_portfolio_optimizers/{{ optimizerId }}',
            CORRELATION_MATRIX_DATA: '/portfolio_optimizer/correlation_matrix_data',
            EFFICIENT_FRONTIER_DATA: '/portfolio_optimizer/efficient_frontier_data',
            GROWTH_AND_PERFORMANCE_DATA: '/portfolio_optimizer/optimized_portfolio_data/',
            BASELINE_PORTFOLIO_INFO: '/portfolio_optimizer/baseline_portfolio_info/{{ baselinePortfolioId }}',
            CORRELATION_MATRIX_PDF_REPORT: '/pdf_reports/portfolio_optimizer/correlation_matrix',
            EFFICIENT_FRONTIER_PDF_REPORT: '/pdf_reports/portfolio_optimizer/efficient_frontier',
        };
        this.QUICKFLOWS = {
            LABELS: '/quickflows/labels',
            SINGLE_SECURITY: {
                QUICKFLOWS_COLLECTION: '/quickflows/single_security',
                QUICKFLOWS_REDIRECT: '/quickflows/single_security/{{ quickflowId }}',
            },
            MULTI_SECURITY: {
                QUICKFLOWS_COLLECTION: '/quickflows/multi_security',
                QUICKFLOWS_REDIRECT: '/quickflows/multi_security/{{ quickflowId }}',
                QUICKFLOWS_LIST: '/quickflows/quickflows_list',
            },
            PREFERENCE: {
                GET_ACCESS: '/quickflows/access',
                UPDATE_ACCESS: '/quickflows/access/{{ quickflowId }}',
            },
        };
        this.RISK_PROFILES = {
            COLLECTION: '/risk_profiles/',
            EDIT: '/risk_profiles/{{ riskProfileId }}/edit/',
            LIST: '/risk_profiles/list/',
            LIST_COLLECTION: '/risk_profiles/list_collection',
            NEW: '/risk_profiles/new/',
            RISK_PROFILE: '/risk_profiles/{{ riskProfileId }}',
            DUPLICATE: '/risk_profiles/duplicate/{{ riskProfileId }}',
            BROAD_ASSET_CLASS: '/risk_profiles/broad_asset_class/{{ securityId }}',
            FINANCIAL_METRICS: '/risk_profiles/financial_metrics/{{ securityId }}',
        };
        this.SALES_SUPPORT = {
            SALES_REP: '/sales_support/sales_rep',
            SUPPORT_FORM: '/sales_support/support_form/{{ requestType }}',
        };
        this.SCATTER_PLOT = {
            SCATTER_PLOT_DATA: '/charts/scatter_plot_data.{{ format }}',
            SCATTER_PLOT_PAGE: '/charts/scatter_plot/',
        };
        this.SCREENER = {
            SCREENER_DATA: '/screener/data/{{ securityGroup }}/{{ format }}',
            SAVED_SCREEN: '/screener/saved_screens/{{ securityGroup }}/{{ screenId }}',
            SAVED_SCREEN_COLLECTION: '/screener/saved_screens/{{ securityGroup }}',
            SAVE_AS_WATCHLIST: '/screener/{{ securityGroup }}/save_as_watchlist',
        };
        this.SEARCH = {
            SEARCH_ALL_INDEX: '/search/#/',
            SUGGEST: '/search/suggest/{{ searchType }}',
            SEARCH_ALL_DATA: '/search/data/',
        };
        this.SECURITIES = {
            SAVE_HOLDINGS_AS_WATCHLIST: '/securities/holdings/{{ securityId }}/save_as_watchlist',
            HOLDINGS: '/securities/holdings/{{ securityId }}.json',
            HOLDINGS_EXPORT: '/securities/holdings/{{ securityId }}.csv',
            COMMON_HOLDINGS: '/securities/common_holdings/{{ primarySecurityId }}.json',
            COMMON_HOLDINGS_EXPORT: '/securities/common_holdings/{{ primarySecurityId }}.csv',
            HOLDING_DATES: '/securities/all_holding_dates/{{ securityId }}',
            FLATTENED_HOLDINGS: '/securities/flattened_holdings/{{ securityId }}.json',
            FLATTENED_HOLDINGS_EXPORT: '/securities/flattened_holdings/{{ securityId }}.csv',
            SAVE_FLATTENED_HOLDINGS_AS_WATCHLIST: '/securities/flattened_holdings/{{ securityId }}/save_as_watchlist',
            SECURITIES_INFO: '/securities/securities_info?securities={{ securities }}',
            SECURITIES_AUTO_PERFORMANCE_PROXY_INFO: '/securities/securities_info/auto_performance_proxy/select?securities={{ securities }}',
            SECURITIES_PERFORMANCE_PROXY_INFO: '/securities/securities_info/performance_proxy?securities={{ securities }}',
            SECURITIES_LEVEL_INFO: '/securities/securities_level_info?securities={{ securities }}',
            SECURITIES_INFO_WATCHLIST: '/securities/securities_info_watchlist',
            DEFAULT_RELATED_SECURITIES: '/securities/related/{{ securityId }}',
            USER_RELATED_SECURITY_COLLECTION: '/securities/user_related/{{ securityId }}',
            USER_RELATED_HOLDING_COLLECTION: '/securities/user_related_holding/{{ securityId }}',
            HOLDINGS_CATEGORIES: '/securities/holdings_categories/{{ securityId }}',
            TICKER_TAPE_SECURITIES: '/securities/ticker_tape_securities',
            ATTRIBUTION_CONTRIBUTORS_DETRACTORS: '/securities/attribution_contributors_detractors/{{ securityId }}',
            ATTRIBUTION_TABLE_DATA: '/securities/attribution_table_data/{{ securityId }}',
            ATTRIBUTION_TABLE_DATA_EXPORT: '/securities/attribution_table_data_export/{{ securityId }}/{{ format }}',
            RELATIVE_ATTRIBUTION: '/securities/relative_attribution/{{ securityId }}',
            RELATIVE_ATTRIBUTION_EXPORT: '/securities/relative_attribution_export/{{ securityId }}',
        };
        this.SECURITY_LISTS = {
            DYNAMIC_LABELS: '/security_lists/dynamic_labels',
            DYNAMIC_MENU: '/security_lists/dynamic_menu',
            SECURITYLIST_LENGTH: '/security_lists/length/{{ securitylistName }}/{{ securityGroup }}',
            SECURITYLIST_MEMBERS: '/security_lists/securitylist_members/{{ securityGroup }}/{{ securitylistName }}',
        };
        this.SHARING = {
            SHARED_ITEM_COLLECTION: '/sharing/{{ item_type }}/{{ item_id }}/shared_items',
            SHARED_ITEM: '/sharing/{{ item_type }}/{{ item_id }}/shared_items/{{ client_group_id }}',
            PUBLIC_SHARING_STATE: '/sharing/{{ itemType }}/{{ itemId }}/public_sharing_state',
            CLIENT_GROUP_CHANGE: '/sharing/{{ item_type }}/{{ item_id }}/shared_items/{{ client_group_id }}/client_group_change',
            URL_SHORTENER: '/sharing/url_shortener',
        };
        this.STRESS_TESTS = {
            COLLECTION: '/stress_tests/',
            STRESS_TEST: '/stress_tests/{{ stressTestId }}',
            PDF_EXPORT: '/stress_tests/{{ securityId }}/stress_test/export_pdf',
        };
        this.STORE = {
            TRANSACTION_DOWNLOAD_URL: '/store/transaction/receipt/pdf/{{ invoice_id }}',
            VALIDATE_FREE_TRIAL_EMAIL: '/accounts/validate_start_free_trial_email',
            CONTRACT_CONFIRMATION: '/store/confirm_contract',
        };
        this.SUPPORT = {
            CATEGORY: '/support/category/{{ page }}',
            CONTACT_REDIRECT: '/contact_redirect',
            HELP_POPUP: '/support/help_popups/{{ internalName }}',
            WEBINARS: '/support/webinars',
        };
        this.SYSTEMS = {
            COMMAND_CHART_DATA: '/systems/commands/{{ commandName }}/chart_data',
            COMMANDS_COLLECTION: '/systems/commands',
            MANUAL_INFILL: '/systems/review/manual_infill',
        };
        this.TABLES = {
            COMP_TABLE_PAGE: '/tables/comparison/',
            TABLE_DATA: '/tables/data/{{ tableType }}/{{ format }}',
            TABLE_LOAD_TIME_ESTIMATE: '/tables/{{ tableType }}/data_load_time_estimate',
            TIMESERIES_TABLE_PAGE: '/tables/timeseries/',
            SAVED_TABLE: '/tables/{{ tableType }}/saved_tables/{{ tableId }}',
            SAVED_TABLE_COLLECTION: '/tables/{{ tableType }}/saved_tables',
        };
        this.TALKING_POINTS = {
            COLLECTION: '/talking_points/',
            DUPLICATE: '/talking_points/duplicate/{{ talkingPointId }}',
            TALKING_POINT: '/talking_points/{{ talkingPointId }}',
            LIST: '/talking_points/list_collection',
            VALID_TEMPLATE_TYPES: '/talking_points/valid_template_types',
        };
        this.WATCHLISTS = {
            ADD_TO_WATCHLIST_MENU: '/watchlists/menu/add_to_watchlist_menu',
            NOTES: '/watchlists/{{ watchlistId }}/notes/',
            NOTES_COLLECTION: '/watchlists/notes/{{ noteId }}',
            // WatchlistView.PUT and WatchlistView.DELETE
            WATCHLIST: '/watchlists/{{ watchlistId }}',
            // WatchlistCollectionView.POST and WatchlistCollectionView.GET
            WATCHLIST_COLLECTION: '/watchlists/',
            WATCHLIST_LIST_PAGE: '/watchlists/list/',
            WATCHLIST_INFO: '/watchlists/watchlists_info?watchlists={{ watchlistIds }}',
            // WatchlistItemView GET and POST
            WATCHLIST_ITEM_COLLECTION: '/watchlists/{{ watchlistId }}/items',
            // WatchlistItemView.DELETE
            WATCHLIST_ITEM_DELETE: '/watchlists/{{ watchlistId }}/items/{{ itemType }}/{{ itemId }}',
            WATCHLIST_ITEMS_TEMPLATE: '/watchlists/menu/watchlist_items_template',
            WATCHLIST_ITEMS_UPLOAD: '/watchlists/menu/watchlist_items_upload',
            WATCHLIST_GENERATE_PDF_REPORT: '/pdf_reports/watchlist_one_page_reports/{{ securityListName }}/{{ dataSource }}/',
        };
    }
    // We have to use LooseObject here because the object values will be different types based on the URL
    getUrl(urlTemplate, urlParams, queryParams) {
        /**
         * Given a URL template and its params, return the formatted URL.
         *
         * Example: getUrl('/custom_securities/{{ customSecurityId }}',
         *                 { customSecurityId: 15 },
         *                 { calculate: true }
         *          );
         * Returns: '/custom_securities/15?calculate=true&'
         */
        let url = urlTemplate;
        if (urlParams) {
            url = this.utilsService.addUrlParams(url, urlParams);
        }
        if (queryParams) {
            url = this.utilsService.addQueryParams(url, queryParams);
        }
        return this.utilsService.sanitizeUrl(url);
    }
    getModelPortfolioCreationUrl(portfolioType) {
        if (portfolioType === 'benchmark') {
            return this.MODEL_PORTFOLIOS_FIXED.NEW_BENCHMARK;
        }
        if (portfolioType === 'client') {
            return this.MODEL_PORTFOLIOS_FIXED.NEW_CLIENT_PORTFOLIO;
        }
        if (portfolioType === 'household') {
            return this.MODEL_PORTFOLIOS_FIXED.NEW_HOUSEHOLD_PORTFOLIO;
        }
        return this.MODEL_PORTFOLIOS_FIXED.NEW;
    }
}
UrlsService.ɵfac = function UrlsService_Factory(t) { return new (t || UrlsService)(i0.ɵɵinject(i1.UtilsService)); };
UrlsService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: UrlsService, factory: UrlsService.ɵfac, providedIn: 'root' });
