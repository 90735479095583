// Module imports
import { BrowserModule } from '@angular/platform-browser';
import { ConfigModule } from 'NewAngular/config/config.module';
import { SkeletonLoadersListModule } from 'NewAngular/features/components/loaders/skeleton-loaders-list/skeleton-loaders.module';
import * as i0 from "@angular/core";
export class SalesRepModule {
}
SalesRepModule.ɵfac = function SalesRepModule_Factory(t) { return new (t || SalesRepModule)(); };
SalesRepModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: SalesRepModule });
SalesRepModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [BrowserModule, ConfigModule, SkeletonLoadersListModule] });
