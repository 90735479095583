import { Store } from '@ngrx/store';
// Utils / constants
import { CATEGORY_LABELS, CATEGORY_ORDER, SEARCH_TYPES } from 'SearchApp/ts/constants';
// NgRx actions / constants
import { setAutocompleteSelectedIndex, selectResultByIndex, setAutocompleteShouldBlur, setAutocompleteShowResults, blurAutocompleter, } from 'SearchApp/ts/features/base-autocomplete/state/autocompleter.actions';
// Services
import { UrlsService } from 'NewAngular/services/core/urls.service';
import * as i0 from "@angular/core";
import * as i1 from "NewAngular/services/core/urls.service";
import * as i2 from "@ngrx/store";
/**
 * Search Results Base Directive
 *
 * This class contains common code for components that show the auto completer results.
 *
 * Inputs:
 * autocompleterId: The UUID that was created by the parent component to manage NgRx state.

 */
export class SearchResultsBaseDirective {
    constructor(urlsService, store) {
        this.urlsService = urlsService;
        this.store = store;
        this.inputText = '';
        // ----- Constants -----
        this.CATEGORY_LABELS = CATEGORY_LABELS;
        this.CATEGORY_ORDER = CATEGORY_ORDER;
        this.SEARCH_TYPES = SEARCH_TYPES;
    }
    clickResult() {
        this.store.dispatch(selectResultByIndex({ autocompleterId: this.autocompleterId }));
    }
    setShouldBlur(shouldBlur) {
        this.store.dispatch(setAutocompleteShouldBlur({ autocompleterId: this.autocompleterId, shouldBlur }));
    }
    setSelectedIndex(selectedIndex) {
        // changes selected index on mouseover so highlighted item can be submitted via enter or submit.
        this.store.dispatch(setAutocompleteSelectedIndex({ autocompleterId: this.autocompleterId, selectedIndex }));
    }
    navigateToMoreResultsUrl(category) {
        const baseUrl = this.urlsService.SEARCH.SEARCH_ALL_INDEX;
        const queryParams = {
            searchTypes: category === undefined ? [] : [encodeURIComponent(this.SEARCH_TYPES[category])],
            queryInput: encodeURIComponent(this.inputText),
        };
        this.store.dispatch(setAutocompleteShowResults({ autocompleterId: this.autocompleterId, showResults: false }));
        this.store.dispatch(blurAutocompleter({ autocompleterId: this.autocompleterId }));
        window.location.href = this.urlsService.getUrl(baseUrl, undefined, queryParams);
    }
}
SearchResultsBaseDirective.ɵfac = function SearchResultsBaseDirective_Factory(t) { return new (t || SearchResultsBaseDirective)(i0.ɵɵdirectiveInject(i1.UrlsService), i0.ɵɵdirectiveInject(i2.Store)); };
SearchResultsBaseDirective.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: SearchResultsBaseDirective, inputs: { autocompleterId: "autocompleterId" } });
