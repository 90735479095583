// Vendor libraries
import { ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
// Utils / constants
import { BOTH_DEFAULT_AND_LEGACY_DATA, LEGACY_ONLY_DATA } from 'AccountsApp/ts/constants/user-preferences.constants';
import { LEGACY_DATA_FEATURE } from 'EntitlementsApp/ts/features/feature-needed/feature-needed.constants';
import { SEARCH_TYPES_TO_CODES } from 'SearchApp/ts/constants';
// NgRx actions / selectors
import { AUTOCOMPLETER_STATE_SLICE_KEY } from 'SearchApp/ts/features/base-autocomplete/state/autocompleter.reducer';
// Services
import { AccountsService } from 'AccountsApp/ts/services/accounts.service';
import { FeatureService } from 'EntitlementsApp/ts/features/feature-needed/feature.service';
import { UrlsService } from 'NewAngular/services/core/urls.service';
// Directives
import { SearchResultsBaseDirective } from 'SearchApp/ts/features/base-autocomplete/directives/search-results.directive';
import * as i0 from "@angular/core";
import * as i1 from "NewAngular/services/core/urls.service";
import * as i2 from "AccountsApp/ts/services/accounts.service";
import * as i3 from "EntitlementsApp/ts/features/feature-needed/feature.service";
import * as i4 from "@ngrx/store";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../../../../ycharts/static/ts/features/pipes/index-of/index-of.pipe";
const _c0 = a0 => ({ "active": a0 });
function ListResultsComponent_div_3_Template(rf, ctx) { if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵtext(1);
    i0.ɵɵelementStart(2, "a", 5);
    i0.ɵɵlistener("click", function ListResultsComponent_div_3_Template_a_click_2_listener() { i0.ɵɵrestoreView(_r1); const ctx_r1 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r1.navigateToMoreResultsUrl(ctx_r1.category)); });
    i0.ɵɵelementEnd()();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r1.CATEGORY_LABELS[ctx_r1.category], " ");
} }
function ListResultsComponent_div_5_dt_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "dt", 11);
} if (rf & 2) {
    const result_r4 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("textContent", result_r4.display_security_id);
} }
function ListResultsComponent_div_5_div_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "div", 12);
} if (rf & 2) {
    const result_r4 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("textContent", result_r4.desc);
} }
function ListResultsComponent_div_5_Template(rf, ctx) { if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵpipe(1, "indexOf");
    i0.ɵɵlistener("click", function ListResultsComponent_div_5_Template_div_click_0_listener() { i0.ɵɵrestoreView(_r3); const ctx_r1 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r1.clickResult()); })("mouseenter", function ListResultsComponent_div_5_Template_div_mouseenter_0_listener() { const result_r4 = i0.ɵɵrestoreView(_r3).$implicit; const ctx_r1 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r1.setSelectedIndexOnMouseEnter(result_r4)); });
    i0.ɵɵtemplate(2, ListResultsComponent_div_5_dt_2_Template, 1, 1, "dt", 7);
    i0.ɵɵelementStart(3, "dd", 8);
    i0.ɵɵelement(4, "div", 9);
    i0.ɵɵtemplate(5, ListResultsComponent_div_5_div_5_Template, 1, 1, "div", 10);
    i0.ɵɵelementEnd()();
} if (rf & 2) {
    const result_r4 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(8, _c0, i0.ɵɵpipeBind2(1, 5, ctx_r1.results, result_r4) === ctx_r1.selectedIndex));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", !result_r4.is_raw && result_r4.display_security_id);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("innerHTML", ctx_r1.getResultNameText(result_r4), i0.ɵɵsanitizeHtml);
    i0.ɵɵattribute("data-test-id", ctx_r1.getResultNameText(result_r4));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", result_r4.desc);
} }
/**
 *
 * List Result Component
 *
 * This component is responsible for:
 *   1. Displaying list results returned from the backend
 *   2. Handling when a result is clicked and hovered
 *   3.- Displaying Search all link and AI link
 *
 * Input Properties:
 *   showMoreResultsUrl: Whether to show the more results header + icon
 *   searchType: The autocompleter from which results are being fetched.
 *
 */
export class ListResultsComponent extends SearchResultsBaseDirective {
    constructor(changeDetector, urlsService, accountsService, featureService, store) {
        super(urlsService, store);
        this.changeDetector = changeDetector;
        this.accountsService = accountsService;
        this.featureService = featureService;
        this.store = store;
        this.results = [];
        this.selectedIndex = -1;
        this.showResults = false;
        this.dataMenuSetting = BOTH_DEFAULT_AND_LEGACY_DATA;
        this.mapStateToComponent = (state) => {
            const autocompleterState = state[this.autocompleterId];
            if (!autocompleterState) {
                return;
            }
            if (this.searchType) {
                this.category = SEARCH_TYPES_TO_CODES[this.searchType];
            }
            this.inputText = autocompleterState.inputText;
            this.results = autocompleterState.results;
            this.selectedIndex = autocompleterState.selectedIndex;
            this.showResults = autocompleterState.showResults;
            // Disable change detection when results are hidden
            if (this.showResults) {
                this.changeDetector.reattach();
                this.changeDetector.detectChanges();
            }
            else {
                this.changeDetector.detectChanges();
                this.changeDetector.detach();
            }
        };
        this.storeSubscription = this.store.select(AUTOCOMPLETER_STATE_SLICE_KEY).subscribe(this.mapStateToComponent);
        this.accountsService.getUserPreferences().subscribe((userPreferences) => {
            this.dataMenuSetting = userPreferences.data_menu_setting;
        });
    }
    ngOnDestroy() {
        this.storeSubscription.unsubscribe();
    }
    setSelectedIndexOnMouseEnter(result) {
        // changes selected index on mouseover so highlighted item can be submitted via enter or submit.
        const selectedIndex = this.results.indexOf(result);
        this.setSelectedIndex(selectedIndex);
    }
    /**
     * Removes (Morn) calc/info suffixes from users with Legacy Only data menu setting.
     *
     * This is done only if the user has the LEGACY_DATA_FEATURE in its subscription, and its data menu setting is
     * LEGACY_ONLY_DATA. Since with that combination the user will only see Morningstar calcs, we get rid of the suffix.
     *
     * @param resultName Original name.
     */
    removeMornSuffixForLegacyOnlyUsers(resultName) {
        if (!this.featureService.userHasFeatures([LEGACY_DATA_FEATURE]))
            return resultName;
        if (this.dataMenuSetting !== LEGACY_ONLY_DATA)
            return resultName;
        if (!resultName.endsWith(' (Morn)'))
            return resultName;
        return resultName.slice(0, -7);
    }
    /**
     * Returns the name to display in the results list item. If it should show the raw input, then builds an HTML
     * snippet with the raw input in it.
     *
     * @param result: Name to show for the result item.
     */
    getResultNameText(result) {
        const resultName = this.removeMornSuffixForLegacyOnlyUsers(result.name);
        if (result.is_raw) {
            return `Press <strong>Enter</strong> to select ${resultName}`;
        }
        return resultName;
    }
}
ListResultsComponent.ɵfac = function ListResultsComponent_Factory(t) { return new (t || ListResultsComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i1.UrlsService), i0.ɵɵdirectiveInject(i2.AccountsService), i0.ɵɵdirectiveInject(i3.FeatureService), i0.ɵɵdirectiveInject(i4.Store)); };
ListResultsComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ListResultsComponent, selectors: [["ycn-list-results"]], inputs: { showMoreResultsUrl: "showMoreResultsUrl", searchType: "searchType" }, features: [i0.ɵɵInheritDefinitionFeature], decls: 6, vars: 2, consts: [["tabindex", "-1", 3, "mouseenter", "mouseleave"], [1, "dropdown-section"], ["class", "dropdown-header", 4, "ngIf"], ["class", "dropdown-item", 3, "ngClass", "click", "mouseenter", 4, "ngFor", "ngForOf"], [1, "dropdown-header"], ["href", "javascript:void(0)", 1, "btn", "btn-transparent", "arrow-right", 3, "click"], [1, "dropdown-item", 3, "click", "mouseenter", "ngClass"], ["class", "dropdown-item-term", 3, "textContent", 4, "ngIf"], [1, "dropdown-item-text"], [3, "innerHTML"], ["class", "dropdown-item-descr", 3, "textContent", 4, "ngIf"], [1, "dropdown-item-term", 3, "textContent"], [1, "dropdown-item-descr", 3, "textContent"]], template: function ListResultsComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵlistener("mouseenter", function ListResultsComponent_Template_div_mouseenter_0_listener() { return ctx.setShouldBlur(false); })("mouseleave", function ListResultsComponent_Template_div_mouseleave_0_listener() { return ctx.setShouldBlur(true); });
        i0.ɵɵelementStart(1, "div")(2, "div", 1);
        i0.ɵɵtemplate(3, ListResultsComponent_div_3_Template, 3, 1, "div", 2);
        i0.ɵɵelementStart(4, "div");
        i0.ɵɵtemplate(5, ListResultsComponent_div_5_Template, 6, 10, "div", 3);
        i0.ɵɵelementEnd()()()();
    } if (rf & 2) {
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("ngIf", ctx.showMoreResultsUrl && ctx.category);
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngForOf", ctx.results);
    } }, dependencies: [i5.NgClass, i5.NgForOf, i5.NgIf, i6.IndexOfPipe], encapsulation: 2 });
