import { of } from 'rxjs';
import { catchError, map, retry, shareReplay } from 'rxjs/operators';
// Services
import { AjaxService } from 'NewAngular/services/core/ajax.service';
import * as i0 from "@angular/core";
import * as i1 from "NewAngular/services/core/ajax.service";
const STATIC_FILE_MANIFEST_URL = `${process.env.STATIC_BASE_URL}/staticfiles` +
    `${process.env.YC_STATIC_VERSION != null ? `.${process.env.YC_STATIC_VERSION}` : ''}.json`;
export class StaticFileUrlService {
    constructor(ajaxService) {
        this.ajaxService = ajaxService;
        this.staticFileManifest = this.ajaxService
            // Cache the response for 5 minutes. We also specify the following response headers in nginx_static_assets.conf:
            //  * must-revalidate: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Cache-Control#revalidation_and_reloading
            //  * s-maxage: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Cache-Control#expiration
            .getDataObserveBody(STATIC_FILE_MANIFEST_URL, {}, { 'Cache-Control': 'max-age=300' })
            .pipe(
        // shareReplay allows multiple subscribers to subscribe to this observable
        // without triggering additional HTTP requests.
        shareReplay(1), 
        // If we fail to get the static file manifest retry twice.
        retry(2));
    }
    /**
     * Returns the relative static URL via the media directory. (ie. /media/images/example.f12fr4sa.jpg)
     * If we fail to get the manifest then return the non-cached version of the file (ie. /media/images/example.jpg)
     *
     * @param staticUrl - The static path of the asset being requested. (ie. images/example.jpg)
     */
    getRelativeUrl(staticUrl) {
        const fallbackUrl = `/media/${staticUrl}`;
        return this.staticFileManifest.pipe(map((data) => {
            const urlIndex = data.paths;
            const cachedUrl = urlIndex[staticUrl];
            return cachedUrl === undefined ? fallbackUrl : `/media/${cachedUrl}`;
        }), catchError(() => {
            return of(fallbackUrl);
        }));
    }
    /**
     * Returns the absolute static URL to our CDN. (ie. static.ycharts.com/images/example.f12fr4sa.jpg)
     * If we fail to get the manifest then return the non-cached
     * version of the file (ie. static.ycharts.com/images/example.jpg)
     *
     * @param staticUrl - The static path of the asset being requested. (ie. images/example.jpg)
     */
    getAbsoluteURL(staticUrl) {
        const staticBaseUrl = `${process.env.STATIC_BASE_URL}`;
        const fallbackUrl = `${staticBaseUrl}/${staticUrl}`;
        return this.staticFileManifest.pipe(map((data) => {
            const urlIndex = data.paths;
            const cachedUrl = urlIndex[staticUrl];
            return cachedUrl === undefined ? fallbackUrl : `${staticBaseUrl}/${cachedUrl}`;
        }), catchError(() => {
            return of(fallbackUrl);
        }));
    }
}
StaticFileUrlService.ɵfac = function StaticFileUrlService_Factory(t) { return new (t || StaticFileUrlService)(i0.ɵɵinject(i1.AjaxService)); };
StaticFileUrlService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: StaticFileUrlService, factory: StaticFileUrlService.ɵfac, providedIn: 'root' });
