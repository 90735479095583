// Services
import { AjaxService } from 'NewAngular/services/core/ajax.service';
import { UrlsService } from 'NewAngular/services/core/urls.service';
import * as i0 from "@angular/core";
import * as i1 from "NewAngular/services/core/ajax.service";
import * as i2 from "NewAngular/services/core/urls.service";
export class NotificationsService {
    constructor(ajaxService, urlsService) {
        this.ajaxService = ajaxService;
        this.urlsService = urlsService;
    }
    getNotifications() {
        const url = this.urlsService.NOTIFICATIONS.NOTIFICATION_COLLECTION;
        return this.ajaxService.getDataObserveBody(url);
    }
    deleteNotifications() {
        const url = this.urlsService.NOTIFICATIONS.NOTIFICATION_COLLECTION;
        return this.ajaxService.deleteDataObserveResponse(url);
    }
    markNotificationAsRead(notification) {
        const url = this.urlsService.getUrl(this.urlsService.NOTIFICATIONS.NOTIFICATION_VIEW, {
            notificationType: notification.type,
            notificationId: notification.id,
        });
        return this.ajaxService.putDataObserveResponse(url, {});
    }
    deleteNotification(notification) {
        const url = this.urlsService.getUrl(this.urlsService.NOTIFICATIONS.NOTIFICATION_VIEW, {
            notificationType: notification.type,
            notificationId: notification.id,
        });
        return this.ajaxService.deleteDataObserveResponse(url);
    }
}
NotificationsService.ɵfac = function NotificationsService_Factory(t) { return new (t || NotificationsService)(i0.ɵɵinject(i1.AjaxService), i0.ɵɵinject(i2.UrlsService)); };
NotificationsService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: NotificationsService, factory: NotificationsService.ɵfac, providedIn: 'root' });
