import * as i0 from "@angular/core";
/**
 * Disabled Directive
 *
 * This directive handles common logic for disabling HTML elements. The host element will be greyed out and pointer events will be disabled
 * when this directive is applied.
 *
 * Input Properties:
 *  1. disabled: boolean. If disabled is true then the HTML element will be greyed out and pointer events will be set to 'none'.
 *  If no input is passed then the HTML element will be disabled by default.
 */
export class DisabledDirective {
    constructor() {
        this.disabled = false;
        this.classDisabled = false;
        this.pointerEvents = 'auto';
    }
    ngOnInit() {
        this.updateDisabledStyling();
    }
    ngOnChanges() {
        this.updateDisabledStyling();
    }
    updateDisabledStyling() {
        this.pointerEvents = this.disabled ? 'none' : 'auto';
        this.classDisabled = !!this.disabled;
    }
}
DisabledDirective.ɵfac = function DisabledDirective_Factory(t) { return new (t || DisabledDirective)(); };
DisabledDirective.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: DisabledDirective, selectors: [["", "ycnDisabled", ""]], hostVars: 4, hostBindings: function DisabledDirective_HostBindings(rf, ctx) { if (rf & 2) {
        i0.ɵɵstyleProp("pointer-events", ctx.pointerEvents);
        i0.ɵɵclassProp("disabled", ctx.classDisabled);
    } }, inputs: { disabled: "disabled" }, features: [i0.ɵɵNgOnChangesFeature] });
