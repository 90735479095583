// Vendor libraries
import { NgZone } from '@angular/core';
// Utils / Constants
import sentryOptions from './sentry-options';
import * as i0 from "@angular/core";
const DEVELOPMENT = 'development';
const environment = process.env.YC_CODE_ENV || DEVELOPMENT;
let release = '1.2.3';
if (environment !== DEVELOPMENT && process.env.YC_CODE_VER !== undefined) {
    // the git tag will come in as 'v1.23.4' so we slice off the 'v'.
    release = process.env.YC_CODE_VER.slice(1);
}
const SENTRY_SUPPORTED_BROWSERS = {
    chrome: '58',
    opera: '52',
    edge: '17',
    firefox: '55',
    safari: '12.1',
    ios: '7.0',
    android: '4.2',
};
export class SentryErrorHandler {
    constructor(ngZone) {
        this.ngZone = ngZone;
        this.USER = window.USER;
        this.sentryIsConfigured = false;
    }
    async handleError(error) {
        // NOTE: (3/31/23) We check if the browser is supported before we do anything else since
        //       we don't want to send errors to sentry for browsers we don't support.
        const browserIsSupported = await SentryErrorHandler.isSupportedBrowser(window.navigator.userAgent);
        if (!browserIsSupported) {
            return;
        }
        // NOTE: (3/30/23) We lazy load the sentry javascript package when it is needed not on
        //       initial page load since it increases load and evaluation time of our application
        //       which can hurt performance.
        const Sentry = (await import('./sentry-wrapper')).default;
        if (!this.sentryIsConfigured) {
            Sentry.init({
                normalizeDepth: 4,
                dsn: 'https://91ca4635bfbc4c839866c9e80fff6d12@o25768.ingest.sentry.io/5548106',
                environment,
                release,
                beforeSend: SentryErrorHandler.beforeSend,
                ignoreErrors: sentryOptions.ignoreErrors,
                denyUrls: sentryOptions.denyUrls,
            });
            Sentry.configureScope((scope) => scope.setUser(this.USER));
            // We set this flag to true so we don't re-initialize Sentry on every error.
            this.sentryIsConfigured = true;
        }
        // Capture handled exception and send it to Sentry.
        this.ngZone.runOutsideAngular(() => Sentry.captureException(error));
        // When in development mode, log the error to console for immediate feedback.
        if (environment === DEVELOPMENT) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    }
    /**
     * Checks if the browser is supported by Sentry.
     * @param uaString
     * @returns {Promise<boolean>}
     */
    static async isSupportedBrowser(uaString) {
        // NOTE: (3/31/23) We lazy load the ua-parser-js package when it is needed not on
        //       initial page load since it increases load and evaluation time of our application
        //       which can hurt performance.
        const UAParser = (await import('ua-parser-js')).default;
        const uaParser = new UAParser(uaString);
        const uaInfo = uaParser.getResult();
        const browserInfo = uaInfo.browser;
        if (!browserInfo.name) {
            return false;
        }
        const browserName = browserInfo.name.toLowerCase();
        const browserVersion = browserInfo.version;
        const osName = uaInfo.os?.name?.toLowerCase();
        const osVersion = uaInfo.os?.version ?? '';
        let minSupportedVersion;
        let userVersion;
        // If the error came from a mobile browser, check that it was from a supported mobile OS and version
        if (browserName.includes('mobile') && Object.keys(browserName).some((key) => browserName.includes(key))) {
            minSupportedVersion = osName ? SENTRY_SUPPORTED_BROWSERS[osName] : null;
            userVersion = osVersion;
        }
        else {
            minSupportedVersion = SENTRY_SUPPORTED_BROWSERS[browserName];
            userVersion = browserVersion;
        }
        if (minSupportedVersion && userVersion) {
            // string.localeCompare() can be used to compare version number strings: https://stackoverflow.com/a/65687141
            const versionComparisonResult = userVersion.localeCompare(minSupportedVersion, undefined, {
                numeric: true,
                sensitivity: 'base',
            });
            // If the browser version from the error is less than the supported version then ignore it
            if (versionComparisonResult < 0) {
                return false;
            }
            // If minSupportedVersion is undefined then the browser name must not be in our list of
            // supported browsers, so we can ignore the error
        }
        else {
            return false;
        }
        return true;
    }
    static beforeSend(event) {
        // Filter out http failure response errors that have a status code of 0
        if (event.breadcrumbs?.values?.length && event.message?.includes('Http failure response for')) {
            // eslint-disable-next-line camelcase
            // @ts-ignore
            const statusCode = event.breadcrumbs.values[event.breadcrumbs.values.length - 1].data?.status_code;
            if (statusCode === 0) {
                return null;
            }
        }
        let toCheck = [event.message];
        if (event.extra?.__serialized__) {
            // @ts-ignore
            const { message, status, error } = event.extra.__serialized__;
            toCheck.push(error);
            if (status === 0 && message.includes('Http failure response for')) {
                return null;
            }
        }
        // Filter out specific errors that we don't care about
        toCheck = toCheck.filter((val) => !!val && typeof val === 'string');
        const ignoreErrorPatterns = sentryOptions.ignoreErrors;
        const matchesErrorPatternToIgnore = ignoreErrorPatterns.some((pattern) => toCheck.some((val) => {
            if (typeof val !== 'string') {
                return false;
            }
            return !!val.match(pattern);
        }));
        if (matchesErrorPatternToIgnore) {
            return null;
        }
        // Any errors that have a filename in the stack trace that includes
        // a string in this array will not be sent to Sentry
        const ignoreFiles = [
            'fb.js',
            'beacon.js',
            'pagead',
            'leadflows.js',
            'collectedforms.js',
            'gpt.js',
            'pubads',
            'pendo.js',
            'gtag/js',
            'highcharts/highstock',
        ];
        let framesToSearch = [];
        // Search stacktrace frames from the exception array
        if (event.exception?.values?.length) {
            for (let i = 0; i < event.exception.values.length; i += 1) {
                const value = event.exception.values[i];
                // Ignore irrelevant error potentially caused by Bing
                if (value.value && value.value === "Can't find variable: setIOSParameters") {
                    return null;
                }
                if (value.stacktrace?.frames) {
                    framesToSearch = framesToSearch.concat(value.stacktrace.frames);
                }
                // The sentry Exception interface is missing the raw_stacktrace property, but it is
                // present in some instances of sentry events. Filtering out the common fb.js errors
                // requires checking the filenames in the raw_stacktrace frames.
                // @ts-ignore
                if (value.raw_stacktrace?.frames) {
                    // @ts-ignore
                    framesToSearch = framesToSearch.concat(value.raw_stacktrace.frames);
                }
            }
        }
        // Search stacktrace frames at the top level of the event object
        // @ts-ignore
        if (event.stacktrace?.frames) {
            // @ts-ignore
            framesToSearch = framesToSearch.concat(event.stacktrace?.frames);
        }
        // @ts-ignore
        if (event.raw_stacktrace?.frames) {
            // @ts-ignore
            framesToSearch = framesToSearch.concat(event.raw_stacktrace?.frames);
        }
        const hasDeniedFile = framesToSearch.some((frame) => {
            return frame.filename ? ignoreFiles.some((s) => frame.filename?.includes(s)) : false;
        });
        if (hasDeniedFile) {
            return null;
        }
        return event;
    }
}
SentryErrorHandler.ɵfac = function SentryErrorHandler_Factory(t) { return new (t || SentryErrorHandler)(i0.ɵɵinject(i0.NgZone)); };
SentryErrorHandler.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: SentryErrorHandler, factory: SentryErrorHandler.ɵfac, providedIn: 'root' });
