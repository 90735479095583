// Vendor libraries
import { EventEmitter, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import uuid from 'uuid-random';
// Utils / constants
import { AI_CHAT_FEATURE } from 'EntitlementsApp/ts/features/feature-needed/feature-needed.constants';
// NgRx actions / selectors
import { addAutocompleterId, resetAutocompleter, setAutocompleteDefaultLinks, } from 'SearchApp/ts/features/base-autocomplete/state/autocompleter.actions';
import { AUTOCOMPLETER_STATE_SLICE_KEY } from 'SearchApp/ts/features/base-autocomplete/state/autocompleter.reducer';
// NgRx actions
import { addMessage } from 'AIChatApp/apps/ai-chat-app/state/conversation/conversation.action';
// Services
import { FeatureService } from 'EntitlementsApp/ts/features/feature-needed/feature.service';
import { UrlsService } from 'NewAngular/services/core/urls.service';
// Components
import { FeatureNeededComponent } from 'EntitlementsApp/ts/features/feature-needed/feature-needed.component';
import { SearchBarComponent } from 'SearchApp/ts/features/base-autocomplete/components/search-bar.component';
import * as i0 from "@angular/core";
import * as i1 from "EntitlementsApp/ts/features/feature-needed/feature.service";
import * as i2 from "@ngrx/store";
import * as i3 from "NewAngular/services/core/urls.service";
import * as i4 from "../../../../../../../../ycharts/static/ts/features/directives/append-to-body/append-to-body.directive";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../../../entitlements/static/entitlements/ts/features/feature-needed/feature-needed.component";
import * as i7 from "./categories-results.component";
import * as i8 from "./list-results.component";
import * as i9 from "./search-bar.component";
const _c0 = a0 => ({ show: a0 });
const _c1 = (a0, a1) => ({ show: a0, "header-search-dropdown": a1 });
/**
 *
 * Base Autocomplete Component
 *
 * This is the base autocomplete component that should contain sufficient functionality for most use cases.
 *
 * If you are trying to add specific functionality that won't be shared across most autocompleters, please consider
 * creating a new component that uses `BaseAutocompleteComponent` rather than adding the functionality directly to
 * `BaseAutocompleteComponent`.
 *
 * Input Properties:
 *   appendToBody: Whether to append the results to the DOM body or not. This is used when the autocompleter
 *                 needs to visually appear outside the confines component's bounding box.
 *   buttonType: 'add', 'icon-browse', 'icon-search'; determines which 'icon' to show to the user.
 *   buttonText: Text used for the search bar button.
 *   dropdownMenuClass: Class used for the dropdown menu.
 *   isInvalid: Boolean that shows invalid css in search bar.
 *   hideButton: Boolean that determines if button is hidden or shows.
 *   includeSearchIcon: Whether the magnifying glass search icon is included in the autocomplete search bar.
 *   placeholder: placeholder text to show when the input box is empty.
 *   searchType: Which autocompleter results should be fetched from.
 *   searchBlockRegex: Enable raw inputs that match the regex.
 *   searchableSecurityIds: Security IDs that are searchable.
 *   showMoreResultsUrl: Whether to show the more results icon in the categories results.
 *   isDisabled: Disables input tag and stops setShouldBlur and handleClick methods from running on SearchBarComponent.
 *   defaultResults: list of AutocompleteExtraLink items to display when autocompleter is focused but empty
 *   globalSearch: Show the search all and AI link if true.
 *  defaultOnly: bool needed for Data Independence to know if we are on RB or Quote page AND that we are set the Default View
 *
 * Output Properties:
 *   searchBlockHandler: EventEmitter that emits the input when searchBlockRegex is set and matches.
 *   searchBarBlurHandler: EventEmitter that emits the input when searchBar blur event occurs.
 *   submitHandler: EventEmitter that emits the selected AutocompleteResult.
 *   backspaceHandler: EventEmitter that emits when backspace is pressed.
 *   blurHandler: EventEmitter that emits in mapStateToComponent whether the search bar input is blurred.
 *   focusHandler: EventEmitter that emits in mapStateToComponent whether the search bar input is focused.
 *   browseClickHandler: EventEmitter that emits the value passed to it by the search bar's button click handler.
 *
 */
export class BaseAutocompleteComponent {
    constructor(featureService, store, urlsService) {
        this.featureService = featureService;
        this.store = store;
        this.urlsService = urlsService;
        this.buttonText = '';
        this.defaultResults = [];
        this.globalSearch = false;
        this.includeSearchIcon = false;
        this.placeholder = 'Search';
        this.isDisabled = false;
        this.isInvalid = false;
        this.hideButton = false;
        this.searchableSecurityIds = [];
        this.defaultOnly = false;
        this.searchBlockHandler = new EventEmitter();
        this.backspaceHandler = new EventEmitter();
        this.blurHandler = new EventEmitter();
        this.focusHandler = new EventEmitter();
        this.searchBarBlurHandler = new EventEmitter();
        this.browseClickHandler = new EventEmitter();
        this.submitHandler = new EventEmitter();
        this.resultsType = 'list';
        this.showResults = false;
        this.inputText = '';
        this.hasInputFocus = false;
        this.resultsExtraLinks = [];
        this.hasFixedWidth = false;
        this.mapStateToComponent = (state) => {
            const { blurred, selectedResult, showResults, categoriesResults, inputText, extraLinks } = state[this.autocompleterId];
            this.showResults = showResults;
            this.inputText = inputText;
            // The autocomplete-in-place component needs to know whether the search is blurred or in focus
            if (blurred) {
                this.blurHandler.emit();
            }
            else {
                this.focusHandler.emit();
            }
            this.hasInputFocus = !blurred;
            const hasExtralinks = extraLinks.length > 0;
            this.hasFixedWidth = (this.defaultResults || hasExtralinks) && !this.showResults;
            // Determine whether to show list or category results based on the `categoriesResults` slice of state
            this.resultsType = Object.keys(categoriesResults).length === 0 ? 'list' : 'categories';
            if (hasExtralinks || this.globalSearch) {
                this.resultsType = 'categories';
            }
            if (!selectedResult) {
                return;
            }
            this.store.dispatch(resetAutocompleter({ autocompleterId: this.autocompleterId }));
            const { type, feature_needed: featureNeeded, url: oldUrl } = selectedResult;
            // If the result has a feature_needed and the user is missing that feature, display the feature needed modal.
            if (featureNeeded && !this.featureService.userHasFeatures([featureNeeded])) {
                this.featureNeededComponent.openModal(featureNeeded);
                return;
            }
            // For Search/AI extra links we need to send query input in the url
            let url = oldUrl;
            const queryInput = encodeURIComponent(this.inputText);
            if (type === 'ai') {
                // If we're already on the AI Chat page, add a message to the current conversation
                if (window.location.href.includes(this.urlsService.AI_CHAT.CHAT)) {
                    this.store.dispatch(addMessage({
                        message: {
                            role: 'user',
                            time: new Date(),
                            text: this.inputText,
                            topics: ['auto'],
                            feedback: null,
                        },
                    }));
                    return;
                }
                // Otherwise redirect to the AI Chat page to start a fresh conversation with query input
                url = this.urlsService.getUrl(this.urlsService.AI_CHAT.CHAT, undefined, { queryInput });
            }
            else if (type === 'searchall') {
                url = this.urlsService.getUrl(this.urlsService.SEARCH.SEARCH_ALL_INDEX, undefined, {
                    queryInput,
                    searchTypes: [],
                });
            }
            this.submitHandler.emit({ ...selectedResult, url });
        };
        this.autocompleterId = uuid();
        this.store.dispatch(addAutocompleterId({ autocompleterId: this.autocompleterId }));
        this.storeSubscription = this.store.select(AUTOCOMPLETER_STATE_SLICE_KEY).subscribe(this.mapStateToComponent);
    }
    ngOnChanges(changes) {
        if (changes?.defaultResults) {
            const showAILink = this.featureService.userHasFeatures([AI_CHAT_FEATURE]) ||
                window.USER.missingFeaturePreference === 'upsell';
            this.resultsExtraLinks = [{ type: 'searchall', url: '', feature_needed: '' }];
            const defaultLinks = changes.defaultResults.currentValue;
            if (showAILink) {
                const aiLink = {
                    type: 'ai',
                    url: '',
                    feature_needed: AI_CHAT_FEATURE,
                };
                this.resultsExtraLinks.push(aiLink);
                defaultLinks.push(aiLink);
            }
            this.store.dispatch(setAutocompleteDefaultLinks({ autocompleterId: this.autocompleterId, defaultLinks }));
        }
    }
    ngOnDestroy() {
        if (this.storeSubscription) {
            this.storeSubscription.unsubscribe();
        }
    }
    focus() {
        /**
         * Allows external components (e.g. SecurityAutocompleteInPlaceComponent) to focus the search bar.
         */
        this.searchBarComponent.textInput.nativeElement.focus();
    }
    reset() {
        /**
         * Allows external components (e.g. TopBarNavAutocompleteComponent) to reset the state.
         */
        this.store.dispatch(resetAutocompleter({ autocompleterId: this.autocompleterId }));
    }
}
BaseAutocompleteComponent.ɵfac = function BaseAutocompleteComponent_Factory(t) { return new (t || BaseAutocompleteComponent)(i0.ɵɵdirectiveInject(i1.FeatureService), i0.ɵɵdirectiveInject(i2.Store), i0.ɵɵdirectiveInject(i3.UrlsService)); };
BaseAutocompleteComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: BaseAutocompleteComponent, selectors: [["ycn-base-autocomplete"]], viewQuery: function BaseAutocompleteComponent_Query(rf, ctx) { if (rf & 1) {
        i0.ɵɵviewQuery(FeatureNeededComponent, 5);
        i0.ɵɵviewQuery(SearchBarComponent, 5);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.featureNeededComponent = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.searchBarComponent = _t.first);
    } }, inputs: { appendToBody: "appendToBody", buttonType: "buttonType", buttonText: "buttonText", dropdownMenuClass: "dropdownMenuClass", defaultResults: "defaultResults", globalSearch: "globalSearch", includeSearchIcon: "includeSearchIcon", placeholder: "placeholder", searchType: "searchType", searchBlockRegex: "searchBlockRegex", showMoreResultsUrl: "showMoreResultsUrl", isDisabled: "isDisabled", isInvalid: "isInvalid", hideButton: "hideButton", searchableSecurityIds: "searchableSecurityIds", defaultOnly: "defaultOnly" }, outputs: { searchBlockHandler: "searchBlockHandler", backspaceHandler: "backspaceHandler", blurHandler: "blurHandler", focusHandler: "focusHandler", searchBarBlurHandler: "searchBarBlurHandler", browseClickHandler: "browseClickHandler", submitHandler: "submitHandler" }, features: [i0.ɵɵNgOnChangesFeature], decls: 5, vars: 32, consts: [[1, "input-group", "field-input-group"], [1, "input-group", "field-input-group", 3, "searchBlockHandler", "backspaceHandler", "browseClickHandler", "searchBarBlurHandler", "autocompleterId", "searchType", "defaultOnly", "searchBlockRegex", "buttonText", "buttonType", "resultsType", "categoriesExtraLinks", "placeholder", "includeSearchIcon", "isDisabled", "isInvalid", "hideButton", "searchableSecurityIds"], [1, "dropdown-menu", 3, "ycnAppendToBody", "hidden", "ngClass", "autocompleterId", "showMoreResultsUrl", "searchType"], [1, "dropdown-menu", 3, "dropdownMenuClass", "ycnAppendToBody", "hidden", "globalSearch", "ngClass", "autocompleterId", "showMoreResultsUrl"]], template: function BaseAutocompleteComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0)(1, "ycn-search-bar", 1);
        i0.ɵɵlistener("searchBlockHandler", function BaseAutocompleteComponent_Template_ycn_search_bar_searchBlockHandler_1_listener($event) { return ctx.searchBlockHandler.emit($event); })("backspaceHandler", function BaseAutocompleteComponent_Template_ycn_search_bar_backspaceHandler_1_listener() { return ctx.backspaceHandler.emit(); })("browseClickHandler", function BaseAutocompleteComponent_Template_ycn_search_bar_browseClickHandler_1_listener() { return ctx.browseClickHandler.emit(); })("searchBarBlurHandler", function BaseAutocompleteComponent_Template_ycn_search_bar_searchBarBlurHandler_1_listener($event) { return ctx.searchBarBlurHandler.emit($event); });
        i0.ɵɵelementEnd();
        i0.ɵɵelement(2, "ycn-list-results", 2)(3, "ycn-categories-results", 3);
        i0.ɵɵelementEnd();
        i0.ɵɵelement(4, "ycn-feature-needed");
    } if (rf & 2) {
        i0.ɵɵadvance();
        i0.ɵɵproperty("autocompleterId", ctx.autocompleterId)("searchType", ctx.searchType)("defaultOnly", ctx.defaultOnly)("searchBlockRegex", ctx.searchBlockRegex)("buttonText", ctx.buttonText)("buttonType", ctx.buttonType)("resultsType", ctx.resultsType)("categoriesExtraLinks", ctx.resultsExtraLinks)("placeholder", ctx.placeholder)("includeSearchIcon", ctx.includeSearchIcon)("isDisabled", ctx.isDisabled)("isInvalid", ctx.isInvalid)("hideButton", ctx.hideButton)("searchableSecurityIds", ctx.searchableSecurityIds);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ycnAppendToBody", ctx.appendToBody)("hidden", ctx.resultsType !== "list")("ngClass", i0.ɵɵpureFunction1(27, _c0, ctx.showResults))("autocompleterId", ctx.autocompleterId)("showMoreResultsUrl", ctx.showMoreResultsUrl)("searchType", ctx.searchType);
        i0.ɵɵadvance();
        i0.ɵɵproperty("dropdownMenuClass", ctx.dropdownMenuClass)("ycnAppendToBody", ctx.appendToBody)("hidden", ctx.resultsType !== "categories")("globalSearch", ctx.globalSearch)("ngClass", i0.ɵɵpureFunction2(29, _c1, ctx.showResults || ctx.hasInputFocus && ctx.defaultResults.length > 0, ctx.hasFixedWidth))("autocompleterId", ctx.autocompleterId)("showMoreResultsUrl", ctx.showMoreResultsUrl);
    } }, dependencies: [i4.AppendToBodyDirective, i5.NgClass, i6.FeatureNeededComponent, i7.CategoriesResultsComponent, i8.ListResultsComponent, i9.SearchBarComponent], encapsulation: 2 });
