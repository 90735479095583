// Vendor libraries
import { ElementRef, EventEmitter, } from '@angular/core';
import { Store } from '@ngrx/store';
import { fromEvent, of } from 'rxjs';
import { debounceTime, map, switchMap, takeWhile } from 'rxjs/operators';
// Utils / Constants
import { ADD_BUTTON, ICON_BROWSE, ICON_SEARCH, NO_ICON_SEARCH, } from 'SearchApp/ts/features/base-autocomplete/base-autocomplete.constants';
// NgRx actions / selectors
import { blurAutocompleter, decrementSelectedIndex, focusAutocompleter, incrementSelectedIndex, selectResultByIndex, setAutocompleteCategoriesResults, setAutocompleteInputText, setAutocompleteListResults, setAutocompleteShouldBlur, } from 'SearchApp/ts/features/base-autocomplete/state/autocompleter.actions';
// Services
import { SearchService } from 'SearchApp/ts/services/search.service';
// Injection Tokens
import { ADDITIONAL_AUTOCOMPLETER_QUERY_PARAMS } from 'NewAngular/superclasses/autocompleter-additional-query-params';
// Constants & utils
import { FIGURE } from 'NewAngular/utils/number_formatter';
import { getReversedFigure } from 'NewAngular/utils/number_formatter_reverse';
import { AUTOCOMPLETER_STATE_SLICE_KEY } from 'SearchApp/ts/features/base-autocomplete/state/autocompleter.reducer';
import * as i0 from "@angular/core";
import * as i1 from "SearchApp/ts/services/search.service";
import * as i2 from "@ngrx/store";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../../../../ycharts/static/ts/features/directives/disabled/disabled.directive";
import * as i5 from "../../../../../../../../ycharts/static/ts/features/directives/icon/icon.directive";
const _c0 = ["autocompleteInput"];
const _c1 = (a0, a1, a2) => ({ "field-input-group field-search-browse input-group": a0, "is-invalid": a1, "btn btn-secondary style-underline": a2 });
function SearchBarComponent_div_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4)(1, "div", 5);
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(2, "svg", 6);
    i0.ɵɵelementEnd()();
} if (rf & 2) {
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("name", "icon-search");
} }
function SearchBarComponent_ng_container_3_div_1_Template(rf, ctx) { if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4)(1, "a", 8);
    i0.ɵɵlistener("click", function SearchBarComponent_ng_container_3_div_1_Template_a_click_1_listener() { i0.ɵɵrestoreView(_r2); const ctx_r2 = i0.ɵɵnextContext(2); return i0.ɵɵresetView(ctx_r2.handleClick()); })("mouseenter", function SearchBarComponent_ng_container_3_div_1_Template_a_mouseenter_1_listener() { i0.ɵɵrestoreView(_r2); const ctx_r2 = i0.ɵɵnextContext(2); return i0.ɵɵresetView(ctx_r2.setShouldBlur(false)); })("mouseleave", function SearchBarComponent_ng_container_3_div_1_Template_a_mouseleave_1_listener() { i0.ɵɵrestoreView(_r2); const ctx_r2 = i0.ɵɵnextContext(2); return i0.ɵɵresetView(ctx_r2.setShouldBlur(true)); });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(2, "svg", 6);
    i0.ɵɵelementEnd()();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("name", ctx_r2.buttonType);
} }
function SearchBarComponent_ng_container_3_span_2_Template(rf, ctx) { if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 9)(1, "div", 10);
    i0.ɵɵlistener("click", function SearchBarComponent_ng_container_3_span_2_Template_div_click_1_listener() { i0.ɵɵrestoreView(_r4); const ctx_r2 = i0.ɵɵnextContext(2); return i0.ɵɵresetView(ctx_r2.handleClick()); })("mouseenter", function SearchBarComponent_ng_container_3_span_2_Template_div_mouseenter_1_listener() { i0.ɵɵrestoreView(_r4); const ctx_r2 = i0.ɵɵnextContext(2); return i0.ɵɵresetView(ctx_r2.setShouldBlur(false)); })("mouseleave", function SearchBarComponent_ng_container_3_span_2_Template_div_mouseleave_1_listener() { i0.ɵɵrestoreView(_r4); const ctx_r2 = i0.ɵɵnextContext(2); return i0.ɵɵresetView(ctx_r2.setShouldBlur(true)); });
    i0.ɵɵtext(2, " Add ");
    i0.ɵɵelementEnd()();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("disabled", ctx_r2.isDisabled);
} }
function SearchBarComponent_ng_container_3_span_3_span_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "span", 13);
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("innerText", ctx_r2.buttonText);
} }
function SearchBarComponent_ng_container_3_span_3_Template(rf, ctx) { if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span")(1, "div", 11);
    i0.ɵɵlistener("click", function SearchBarComponent_ng_container_3_span_3_Template_div_click_1_listener() { i0.ɵɵrestoreView(_r5); const ctx_r2 = i0.ɵɵnextContext(2); return i0.ɵɵresetView(ctx_r2.handleClick()); })("mousedown", function SearchBarComponent_ng_container_3_span_3_Template_div_mousedown_1_listener() { i0.ɵɵrestoreView(_r5); const ctx_r2 = i0.ɵɵnextContext(2); return i0.ɵɵresetView(ctx_r2.setShouldBlur(false)); })("mouseup", function SearchBarComponent_ng_container_3_span_3_Template_div_mouseup_1_listener() { i0.ɵɵrestoreView(_r5); const ctx_r2 = i0.ɵɵnextContext(2); return i0.ɵɵresetView(ctx_r2.setShouldBlur(true)); });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(2, "svg", 6);
    i0.ɵɵtemplate(3, SearchBarComponent_ng_container_3_span_3_span_3_Template, 1, 1, "span", 12);
    i0.ɵɵelementEnd()();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("name", ctx_r2.buttonType);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.buttonText);
} }
function SearchBarComponent_ng_container_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, SearchBarComponent_ng_container_3_div_1_Template, 3, 1, "div", 2)(2, SearchBarComponent_ng_container_3_span_2_Template, 3, 1, "span", 7)(3, SearchBarComponent_ng_container_3_span_3_Template, 4, 2, "span", 3);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.buttonType === ctx_r2.AUTOCOMPLETE_SEARCH_BUTTON_TYPES.ICON_SEARCH);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.buttonType === ctx_r2.AUTOCOMPLETE_SEARCH_BUTTON_TYPES.ADD_BUTTON);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.buttonType === ctx_r2.AUTOCOMPLETE_SEARCH_BUTTON_TYPES.ICON_BROWSE);
} }
/**
 *
 * Search Bar Component
 *
 * This component is responsible for reading user input being typed into the search bar, and using it to make backend
 * requests. Once it receives data from the backend, it updates the NgRx store.
 *
 * It also handles special actions, and dispatches NgRx actions accordingly:
 *   1. Key presses (DOWN, UP, ENTER, ESC)
 *   2. Focusing the search bar
 *   3. Blurring the search bar
 *   4. Hovering / clicking the search icon
 *
 * Input Properties:
 *
 *   autocompleterId: The UUID that was created by the parent component to manage NgRx state.
 *   buttonType: 'add', 'icon-search', 'icon-browse'; determines which 'icon' to show to the user.
 *   categoriesExtraLinks: Extra links to append to categories result.
 *   buttonText: Text used for the search bar.
 *   includeSearchIcon: Whether the magnifying glass search icon is included in the search bar
 *   isDisabled: Boolean that disables input tag.
 *   isInvalid: Boolean that shows invalid css in search bar.
 *   placeholder: placeholder text to show when the input box is empty.
 *   searchType: Which autocompleter results should be fetched from.
 *   searchBlockRegex: Enable raw inputs that match the regex.
 *   searchableSecurityIds: Security IDs that are searchable.
 *   resultsType: returned type of list or categories dict.
 *   defaultOnly: bool needed for Data Independence to know if we are on RB or Quote page AND that we are set the Default View
 *
 * Output Properties:
 *
 *   searchBlockHandler: EventEmitter that emits the input when searchBlockRegex is set and matches.
 *   backspaceHandler: EventEmitter that emits when backspace is pressed.
 *   browseClickHandler:  EventEmitter that emits the value passed to it by the search bar's button click handler.
 *   searchBarBlurHandler: EventEmitter that emits the input when searchBar blur event occurs.
 */
export class SearchBarComponent {
    constructor(searchService, store, additionalAutoCompleterQueryParams) {
        this.searchService = searchService;
        this.store = store;
        this.additionalAutoCompleterQueryParams = additionalAutoCompleterQueryParams;
        this.categoriesExtraLinks = [];
        this.includeSearchIcon = false;
        this.buttonText = '';
        this.isDisabled = false;
        this.isInvalid = false;
        this.hideButton = false;
        this.resultsType = 'list';
        this.searchableSecurityIds = [];
        this.defaultOnly = false;
        this.searchBlockHandler = new EventEmitter();
        this.backspaceHandler = new EventEmitter();
        this.browseClickHandler = new EventEmitter();
        this.searchBarBlurHandler = new EventEmitter();
        this.AUTOCOMPLETE_SEARCH_BUTTON_TYPES = {
            ADD_BUTTON,
            ICON_BROWSE,
            ICON_SEARCH,
            NO_ICON_SEARCH,
        };
        this.cacheData = {}; // maps a cacheKey to its associated results
        this.cacheKey = '';
        this.shouldBlur = false;
        this.showResults = false;
        this.isAlive = true;
        // The results returned from the backend are stored here
        this.results = [];
        this.MULTI_ITEM_SEARCH_TYPES = ['multi_asset_watchlist_securities', 'indicator'];
        this.mapStateToComponent = (state) => {
            const autocompleterState = state[this.autocompleterId];
            if (!autocompleterState) {
                return;
            }
            this.showResults = autocompleterState.showResults || autocompleterState.extraLinks.length > 0;
            this.shouldBlur = autocompleterState.shouldBlur;
            if (autocompleterState.inputText === '' && this.textInput) {
                this.textInput.nativeElement.value = '';
            }
        };
        this.store
            .select(AUTOCOMPLETER_STATE_SLICE_KEY)
            .pipe(takeWhile(() => this.isAlive))
            .subscribe(this.mapStateToComponent);
    }
    ngOnDestroy() {
        this.isAlive = false;
    }
    ngAfterViewInit() {
        // Subscribe to changes in the input. Whenever a new value is emitted, call `getData`
        const typeAhead = fromEvent(this.textInput.nativeElement, 'input').pipe(map((e) => e.target.value), debounceTime(10), switchMap((searchTerm) => this.getData(searchTerm)));
        typeAhead
            .pipe(takeWhile(() => this.isAlive))
            .subscribe((data) => {
            this.cacheData[this.cacheKey] = data;
            if (this.resultsType === 'list' && Array.isArray(data)) {
                const filteredData = this.searchableSecurityIds.length
                    ? this.filterBySearchableSecurityIds(data)
                    : data;
                this.store.dispatch(setAutocompleteListResults({
                    autocompleterId: this.autocompleterId,
                    results: filteredData,
                }));
            }
            else {
                const filteredData = {};
                Object.entries(data).forEach(([key, listData]) => {
                    const filteredListData = this.searchableSecurityIds.length
                        ? this.filterBySearchableSecurityIds(listData)
                        : listData;
                    if (filteredListData.length > 0) {
                        filteredData[key] = filteredListData;
                    }
                });
                this.store.dispatch(setAutocompleteCategoriesResults({
                    autocompleterId: this.autocompleterId,
                    results: filteredData,
                    extraLinks: this.categoriesExtraLinks,
                }));
            }
        });
    }
    filterBySearchableSecurityIds(autocompleteResults) {
        return autocompleteResults.filter((result) => {
            return this.searchableSecurityIds.includes(result.search_id);
        });
    }
    getData(inputText) {
        /**
         * Given a text query, return the result contained in the cache, or make a request to the backend.
         */
        this.store.dispatch(setAutocompleteInputText({ autocompleterId: this.autocompleterId, inputText, isFetching: true }));
        // If searchBlockRegex is set and matches, call searchBlockHandler and if inputText is a number,
        // return one-result list
        if (this.searchBlockRegex &&
            inputText.length > 0 &&
            inputText.match(this.searchBlockRegex) !== null &&
            // @ts-ignore
            inputText.match(this.searchBlockRegex)[0] === inputText) {
            if (this.searchBlockHandler) {
                this.searchBlockHandler.emit(inputText);
            }
            // If the input has a figure format (E.g. 100k), reverse it to get the number.
            const reversedInputText = getReversedFigure(FIGURE, inputText);
            // `reversedInputText` will be false if it has a wrong number format. Don't do anything if that's the case.
            if (reversedInputText !== false) {
                return of([
                    {
                        name: reversedInputText.toString(),
                        is_raw: true,
                        search_id: reversedInputText.toString(),
                    },
                ]);
            }
            return of([]);
        }
        // Prevent suggest requests for long strings of multiple items. This can result in long request headers
        // that are blocked by our CDN
        if (this.MULTI_ITEM_SEARCH_TYPES.includes(this.searchType) && inputText.length > 200) {
            return of([]);
        }
        this.cacheKey = `${this.searchType}${inputText}${this.defaultOnly}`;
        const cachedResults = this.cacheData[this.cacheKey];
        if (cachedResults) {
            return of(cachedResults);
        }
        return this.searchService.getSuggestDataListResults(this.searchType, { q: inputText, ...(this.additionalAutoCompleterQueryParams ?? {}) }, this.defaultOnly);
    }
    handleFocus() {
        this.store.dispatch(focusAutocompleter({ autocompleterId: this.autocompleterId }));
    }
    handleBlur() {
        if (this.shouldBlur) {
            this.searchBarBlurHandler.emit(this.textInput.nativeElement.value);
            this.store.dispatch(blurAutocompleter({ autocompleterId: this.autocompleterId }));
        }
    }
    handleClick() {
        if (this.isDisabled)
            return;
        switch (this.buttonType) {
            case ADD_BUTTON:
            case ICON_SEARCH:
                this.store.dispatch(selectResultByIndex({ autocompleterId: this.autocompleterId }));
                break;
            case ICON_BROWSE:
                this.browseClickHandler.emit();
                this.setShouldBlur(true);
                this.handleBlur();
                break;
            default:
                break;
        }
    }
    setShouldBlur(shouldBlur) {
        if (this.isDisabled)
            return;
        this.store.dispatch(setAutocompleteShouldBlur({ autocompleterId: this.autocompleterId, shouldBlur }));
    }
    // ------------------------------------ Begin KeyDownHelper Callback Methods ------------------------------------
    nextItem(event) {
        // Select next item if active, otherwise pass this keypress through
        if (this.showResults) {
            this.store.dispatch(incrementSelectedIndex({ autocompleterId: this.autocompleterId }));
            event.preventDefault();
        }
    }
    prevItem(event) {
        // Select previous item if showResults, otherwise pass this keypress through
        if (this.showResults) {
            this.store.dispatch(decrementSelectedIndex({ autocompleterId: this.autocompleterId }));
            event.preventDefault();
        }
    }
    escPress() {
        this.setShouldBlur(true);
        this.textInput.nativeElement.blur();
    }
    enterPress() {
        this.store.dispatch(selectResultByIndex({ autocompleterId: this.autocompleterId }));
    }
    backspacePress() {
        if (this.backspaceHandler && this.textInput.nativeElement.value === '') {
            this.backspaceHandler.emit();
        }
    }
}
SearchBarComponent.ɵfac = function SearchBarComponent_Factory(t) { return new (t || SearchBarComponent)(i0.ɵɵdirectiveInject(i1.SearchService), i0.ɵɵdirectiveInject(i2.Store), i0.ɵɵdirectiveInject(ADDITIONAL_AUTOCOMPLETER_QUERY_PARAMS, 8)); };
SearchBarComponent.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: SearchBarComponent, selectors: [["ycn-search-bar"]], viewQuery: function SearchBarComponent_Query(rf, ctx) { if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
    } if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.textInput = _t.first);
    } }, inputs: { autocompleterId: "autocompleterId", buttonType: "buttonType", categoriesExtraLinks: "categoriesExtraLinks", includeSearchIcon: "includeSearchIcon", buttonText: "buttonText", placeholder: "placeholder", searchType: "searchType", searchBlockRegex: "searchBlockRegex", isDisabled: "isDisabled", isInvalid: "isInvalid", hideButton: "hideButton", resultsType: "resultsType", searchableSecurityIds: "searchableSecurityIds", defaultOnly: "defaultOnly" }, outputs: { searchBlockHandler: "searchBlockHandler", backspaceHandler: "backspaceHandler", browseClickHandler: "browseClickHandler", searchBarBlurHandler: "searchBarBlurHandler" }, decls: 4, vars: 9, consts: [["autocompleteInput", ""], ["autocomplete", "off", "name", "q", "type", "text", 1, "form-control", "field-input", 3, "keydown.escape", "keydown.enter", "keydown.arrowUp", "keydown.arrowDown", "keydown.backspace", "blur", "focus", "placeholder", "disabled", "ngClass"], ["class", "field-input-group-actions", 4, "ngIf"], [4, "ngIf"], [1, "field-input-group-actions"], [1, "btn", "btn-transparent"], ["ycnIcon", "", 3, "name"], ["class", "input-group-btn", 4, "ngIf"], ["tabindex", "-1", "href", "javascript:void(0)", 1, "btn", "btn-transparent", 3, "click", "mouseenter", "mouseleave"], [1, "input-group-btn"], ["tabindex", "-1", "ycnDisabled", "", 1, "btn", "btn-secondary", 3, "click", "mouseenter", "mouseleave", "disabled"], ["tabindex", "-1", 1, "btn", "btn-secondary", "btn-browse", 3, "click", "mousedown", "mouseup"], [3, "innerText", 4, "ngIf"], [3, "innerText"]], template: function SearchBarComponent_Template(rf, ctx) { if (rf & 1) {
        const _r1 = i0.ɵɵgetCurrentView();
        i0.ɵɵelementStart(0, "input", 1, 0);
        i0.ɵɵlistener("keydown.escape", function SearchBarComponent_Template_input_keydown_escape_0_listener() { i0.ɵɵrestoreView(_r1); return i0.ɵɵresetView(ctx.escPress()); })("keydown.enter", function SearchBarComponent_Template_input_keydown_enter_0_listener() { i0.ɵɵrestoreView(_r1); return i0.ɵɵresetView(ctx.enterPress()); })("keydown.arrowUp", function SearchBarComponent_Template_input_keydown_arrowUp_0_listener($event) { i0.ɵɵrestoreView(_r1); return i0.ɵɵresetView(ctx.prevItem($event)); })("keydown.arrowDown", function SearchBarComponent_Template_input_keydown_arrowDown_0_listener($event) { i0.ɵɵrestoreView(_r1); return i0.ɵɵresetView(ctx.nextItem($event)); })("keydown.backspace", function SearchBarComponent_Template_input_keydown_backspace_0_listener() { i0.ɵɵrestoreView(_r1); return i0.ɵɵresetView(ctx.backspacePress()); })("blur", function SearchBarComponent_Template_input_blur_0_listener() { i0.ɵɵrestoreView(_r1); return i0.ɵɵresetView(ctx.handleBlur()); })("focus", function SearchBarComponent_Template_input_focus_0_listener() { i0.ɵɵrestoreView(_r1); return i0.ɵɵresetView(ctx.handleFocus()); });
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(2, SearchBarComponent_div_2_Template, 3, 1, "div", 2)(3, SearchBarComponent_ng_container_3_Template, 4, 3, "ng-container", 3);
    } if (rf & 2) {
        i0.ɵɵproperty("placeholder", ctx.placeholder)("disabled", ctx.isDisabled)("ngClass", i0.ɵɵpureFunction3(5, _c1, ctx.buttonType === ctx.AUTOCOMPLETE_SEARCH_BUTTON_TYPES.ICON_BROWSE, ctx.isInvalid, ctx.buttonText !== ""));
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngIf", ctx.includeSearchIcon);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", !ctx.hideButton);
    } }, dependencies: [i3.NgClass, i3.NgIf, i4.DisabledDirective, i5.IconDirective], encapsulation: 2 });
